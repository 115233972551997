
import React from "react";
import { Line } from "react-chartjs-2";
import {
    Card, CardBody, CardHeader, Nav, NavItem,
    NavLink, TabContent, Table, TabPane
} from "reactstrap";
import {
    chartExample1
} from "variables/charts.js";







class StockDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            horizontalTabs: "home",
            verticalTabs: "info",
            pageTabs: "homePages",
            openedCollapses: ["collapseOne", "collapse1"],
        };
    }
    // with this function we create an array with the opened collapses
    // it is like a toggle function for all collapses from this page
    collapsesToggle = (collapse) => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter((item) => item !== collapse),
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses,
            });
        }
    };

    render() {
        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader>
                            <h5>Horizontal Tabs</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                    <Nav id="tabs" role="tablist" tabs>
                                        <NavItem>
                                            <NavLink
                                                aria-expanded={this.state.horizontalTabs === "home"}
                                                data-toggle="tab"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "home"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "home" })
                                                }
                                            >
                                                Summary
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-expanded={
                                                    this.state.horizontalTabs === "profile"
                                                }
                                                data-toggle="tab"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "profile"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "profile" })
                                                }
                                            >
                                                Chart
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-expanded={
                                                    this.state.horizontalTabs === "messages"
                                                }
                                                data-toggle="tab"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "messages"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "messages" })
                                                }
                                            >
                                                Historical Data
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                            <TabContent
                                className="text-center"
                                id="my-tab-content"
                                activeTab={this.state.horizontalTabs}
                            >
                                <TabPane tabId="home" role="tabpanel">
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Previous Close</th>
                                                <th>Open</th>
                                                <th>Bid</th>
                                                <th >Day's Range</th>
                                                <th >52 Week Range</th>
                                                <th >Ask</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td >109.376</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="profile" role="tabpanel">
                                    <div className="container-fluid">
                                        <Line
                                            data={chartExample1.data}
                                            options={chartExample1.options}

                                        />

                                    </div>

                                </TabPane>
                                <TabPane tabId="messages" role="tabpanel">
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Open</th>
                                                <th>High</th>
                                                <th >Low</th>
                                                <th >Close</th>
                                                <th >Adj Close</th>
                                                <th >Volume</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>20/02/2021</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td>109.376</td>
                                                <td >109.376</td>
                                                <td>-</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>


                </div>
            </>
        );
    }
}

export default StockDetails;
