import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input } from 'reactstrap';
import { CombinePayee } from './Store/managePayeesSlice';

function CombinePayees(props) {

    const dispatch = useDispatch()

    const { savedPayeeList } = useSelector(state => state.shared)

    const [payeeName, setPayeeName] = useState(null)

    useEffect(() => {
    }, [])

    return (
        <Col md="7" id="checbox-div" className="">
            <h6>
                <span id="number-of-payees">
                    {savedPayeeList.filter(obj => obj.isChecked).length}
                </span>
                {" Payees Selected"}
            </h6>

            <p className="font-weight-bold">Combine and rename selected payees?</p>
            <Input
                value={payeeName}
                onChange={(e) => setPayeeName(e.target.value)}
                type="text"
            />
            <Button
                onClick={() => dispatch(CombinePayee({ payeeName }))}
                color="primary"
                size="sm"
            >
                {"Combine"}
            </Button>
            <br />
        </Col>
    )
}

export default CombinePayees