
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row
} from "reactstrap";
import { ChangeFormData } from '../ManagePayees/Store/managePayeesSlice';
import AddPayee from "../Shared/Components/AddPayee";
import { ChangeAllSavedPayeeListCheck, ChangeSavedPayeeListCheck } from "../Shared/Store/sharedSlice";
import CombinePayees from "./CombinePayees";
import DeletePayeeModal from "./DeletePayeeModal";

function ManagePayees(props) {

    const dispatch = useDispatch()

    const { savedPayeeList } = useSelector(state => state.shared)

    const [search, setSearch] = useState('')

    const onCheckboxChange = async (isChecked, value, label, expenseId, mortgageId, goalId, envelopeId, isAutomatic, isIncluded) => {

        let envelopeMortgageGoalId
        let parentId
        if (expenseId) {
            envelopeMortgageGoalId = expenseId
            parentId = envelopeId
        }
        else if (mortgageId) {
            envelopeMortgageGoalId = mortgageId
            parentId = -1
        }
        else if (goalId) {
            envelopeMortgageGoalId = goalId
            parentId = 0
        }

        if (value) {
            dispatch(ChangeSavedPayeeListCheck({ value, isChecked }))
        } else {
            dispatch(ChangeAllSavedPayeeListCheck({ isChecked }))
        }

        let checkedPayees = savedPayeeList.filter(obj => (obj.isChecked && obj.value != value))

        if (savedPayeeList.length == 1 && !value) {

            if (isChecked) {
                const { value, label, expenseId, envelopeId, mortgageId, goalId, isAutomatic, isIncluded } = savedPayeeList[0] || {}

                let envelopeMortgageGoalId
                let parentId
                if (expenseId) {
                    envelopeMortgageGoalId = expenseId
                    parentId = envelopeId
                }
                else if (mortgageId) {
                    envelopeMortgageGoalId = mortgageId
                    parentId = -1
                }
                else if (goalId) {
                    envelopeMortgageGoalId = goalId
                    parentId = 0
                }

                dispatch(ChangeFormData({
                    id: value,
                    payeeName: label,
                    isIncluded: isIncluded,
                    isAutomatic: isAutomatic,
                    envelopeMortgageGoalId,
                    parentId,
                }))
            } else
                dispatch(ChangeFormData({
                    id: 0,
                    payeeName: "",
                    isIncluded: true,
                    isAutomatic: true,
                    envelopeMortgageGoalId: null,
                    parentId: null,
                }))

        } else
            if (isChecked)
                dispatch(ChangeFormData({
                    id: value,
                    payeeName: label,
                    isIncluded: isIncluded,
                    isAutomatic: isAutomatic,
                    envelopeMortgageGoalId,
                    parentId,
                }))
            else {
                if (checkedPayees.length == 1 && value) {
                    const { value, label, expenseId, envelopeId, mortgageId, goalId, isAutomatic, isIncluded } = checkedPayees[0] || {}

                    let envelopeMortgageGoalId
                    let parentId
                    if (expenseId) {
                        envelopeMortgageGoalId = expenseId
                        parentId = envelopeId
                    }
                    else if (mortgageId) {
                        envelopeMortgageGoalId = mortgageId
                        parentId = -1
                    }
                    else if (goalId) {
                        envelopeMortgageGoalId = goalId
                        parentId = 0
                    }

                    dispatch(ChangeFormData({
                        id: value,
                        payeeName: label,
                        isIncluded: isIncluded,
                        isAutomatic: isAutomatic,
                        envelopeMortgageGoalId,
                        parentId,
                    }))
                } else {
                    dispatch(ChangeFormData({
                        id: 0,
                        payeeName: "",
                        isIncluded: true,
                        isAutomatic: true,
                        envelopeMortgageGoalId: null,
                        parentId: null,
                    }))
                }
            }
    }

    function RenderPayees() {
        let payeeListToRender = savedPayeeList.filter(obj => (obj.label.toLowerCase().includes(search.toLowerCase())))
        return payeeListToRender.map((item) => {
            const { value, label, expenseId, envelopeId, mortgageId, goalId, isChecked, isIncluded, isAutomatic } = item
            return (
                <li
                    key={value}
                    className="list-group-item border-0 "
                >
                    <FormGroup check>
                        <Label check>
                            <Input
                                checked={isChecked}
                                type="checkbox"
                                className="payee"
                                onChange={(e) => {
                                    onCheckboxChange(e.target.checked, value, label, expenseId, mortgageId, goalId, envelopeId, isAutomatic, isIncluded)
                                    // e.preventDefault()
                                }}
                            />
                            <span className="form-check-sign" />
                            {label}
                        </Label>
                    </FormGroup>
                </li>
            )
        })
    }

    useEffect(() => {
    })

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12" className="">
                        <Card className="h-100">
                            <CardHeader className="">
                                <CardTitle tag="h5">Manage Payees</CardTitle>
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col md="5">
                                        <Form className="border-bottom">
                                            <InputGroup className="no-border">
                                                <Input
                                                    defaultValue=""
                                                    placeholder="Search Payees"
                                                    type="text"
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    value={search}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-zoom-split" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Form>

                                        <ul className="list-group" id="payees-list">
                                            <li className="list-group-item border-top-0 border-left-0 border-right-0 border-bottom">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            className="payee"
                                                            onChange={(e) => onCheckboxChange(e.target.checked, 0, "", null)}
                                                        />
                                                        <span className="form-check-sign" />
                                                        Payees
                                                    </Label>
                                                </FormGroup>
                                            </li>
                                            {RenderPayees()}
                                        </ul>
                                    </Col>

                                    {savedPayeeList.filter(obj => obj.isChecked).length < 2 &&
                                        <AddPayee />
                                    }

                                    {savedPayeeList.filter(obj => obj.isChecked).length > 1 &&
                                        <CombinePayees />
                                    }
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </div>
            <DeletePayeeModal />
        </>
    );
}

export default ManagePayees;
