
import React from "react";
import Select from "react-select";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";

class BudgetSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            singleSelect1: null,
            singleSelect2: null,

        };
    }
    render() {
        return (
            <>
                <div className="content">
                    <Row>

                        <Col md="6" className="mr-auto ml-auto">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Budget Settings</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal">
                                        <Row>
                                            <Label md="3">Budget Name</Label>
                                            <Col md="9">
                                                <FormGroup>
                                                    <Input placeholder="" type="text" />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label md="3">Currency</Label>
                                            <Col md="9">
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    value={this.state.singleSelect}
                                                    onChange={(value) =>
                                                        this.setState({ singleSelect: value })
                                                    }
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Select Your Currency",
                                                            isDisabled: true,
                                                        },
                                                        { value: "2", label: "US Dollar-USD" },
                                                        { value: "3", label: "UAE Dirham- UAE" },
                                                        { value: "4", label: "UK Pound Sterling-GBP" },
                                                        { value: "4", label: "Canadian Dollar-CAD" },
                                                    ]}
                                                    placeholder="Select..."
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label md="3">Number Format</Label>
                                            <Col md="9">
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    value={this.state.singleSelect1}
                                                    onChange={(value) =>
                                                        this.setState({ singleSelect1: value })
                                                    }
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Preferred Number Format",
                                                            isDisabled: true,
                                                        },
                                                        { value: "2", label: "123,456.789" },
                                                        { value: "3", label: "12,345.789" },
                                                        { value: "4", label: "1,234.567" },
                                                        { value: "4", label: "123,456" },
                                                    ]}
                                                    placeholder="Select..."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label md="3">Currency Placement</Label>
                                            <Col md="9">
                                                <div className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            defaultValue="option1"
                                                            id="exampleRadios11"
                                                            name="exampleRadioz"
                                                            type="radio"
                                                        />
                                                        Before Amount($123,456)
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                                <div className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            defaultChecked
                                                            defaultValue="option2"
                                                            id="exampleRadios12"
                                                            name="exampleRadioz"
                                                            type="radio"
                                                        />
                                                            After Amount(123,456$)
                                                            <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                                <div className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            defaultChecked
                                                            defaultValue="option2"
                                                            id="exampleRadios12"
                                                            name="exampleRadioz"
                                                            type="radio"
                                                        />
                                                            No Symbol(123,456)
                                                            <span className="form-check-sign" />
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label md="3">Date Format</Label>
                                            <Col md="9">
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="singleSelect"
                                                    value={this.state.singleSelect2}
                                                    onChange={(value) =>
                                                        this.setState({ singleSelect2: value })
                                                    }
                                                    options={[
                                                        {
                                                            value: "",
                                                            label: "Preferred Date Format",
                                                            isDisabled: true,
                                                        },
                                                        { value: "2", label: "12/09/2021" },
                                                        { value: "3", label: "12.09.2021" },
                                                        { value: "4", label: "09/12/2021" },
                                                        { value: "4", label: "2021.09.12" },
                                                    ]}
                                                    placeholder="Select..."
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md="4" />
                                        <Col md="8">
                                            <Button className="btn-round" color="info" type="submit">
                                                Apply Settings
                      </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </>
        );
    }
}

export default BudgetSettings;
