const onChange = (e, categoryName) => {
    console.log(categoryName)
    console.log(e.target);
    var Popover2 = document.getElementById('Popover2');
    Popover2.innerHTML = e.target.value;
}


var dateObj = new Date();
var month = dateObj.getUTCMonth() + 1; //months from 1-12
var day = dateObj.getUTCDate();
var year = dateObj.getUTCFullYear();
var month = new Array();
month[0] = "January"; month[1] = "February"; month[2] = "March"; month[3] = "April"; month[4] = "May"; month[5] = "June"; month[6] = "July"; month[7] = "August"; month[8] = "September"; month[9] = "October"; month[10] = "November"; month[11] = "December";
var d = new Date();
var n = month[d.getMonth()];
var newdate = n + "-" + year;
export default { onChange, newdate };

