import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { GetAllDataOfBudget } from '../../Shared/Store/sharedSlice';
import { toast } from 'react-toastify';

export const PostBudget = createAsyncThunk('newBudget/PostBudget', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { bugetName, currency, currencyPlacement, dateFormat, numberFormat } = params

        let result = await apiInstance.post('/api/budgets', {
            'budget_name': bugetName,
            'number_format_id': numberFormat.value,
            'date_format_id': dateFormat.value,
            'currency_id': currency.value,
            'currency_symbol_placement': currencyPlacement,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PutBudget = createAsyncThunk('newBudget/PutBudget', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { selectedBudgetId } = getState().userData
        const { bugetName, currency, currencyPlacement, dateFormat, numberFormat } = params

        let result = await apiInstance.put(`/api/budgets/${selectedBudgetId}`, {
            'budget_name': bugetName,
            'number_format_id': numberFormat.value,
            'date_format_id': dateFormat.value,
            'currency_id': currency.value,
            'currency_symbol_placement': currencyPlacement,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetBudgetbyId = createAsyncThunk('newBudget/GetBudgetbyId', async (params, { dispatch, getState }) => {
    try {
        const { budgetId } = params

        let result = await apiInstance.get(`/api/budgets/${budgetId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const newBudgetSlice = createSlice({
    name: 'newBudget',
    initialState: {
        currencies: [],
        numberFormats: [],
        dateFormats: [],

        formData: {
            numberFormat: null,
            bugetName: '',
            currency: null,
            currencyPlacement: null,
            dateFormat: null,
        }
    },
    reducers: {
        ChangeFormData: (state, action) => {
            state.formData = action.payload
        },
    },
    extraReducers: {
        ['shared/GetAllDataOfBudget/fulfilled']: (state, action) => {
            const { budgetOptions } = action.payload || {}
            const { data = null, status } = budgetOptions || {}
            if (status >= 200 && status < 300) {
                const { currencies, date_formats, number_formats } = data
                let currenciesTemp = []
                let dateFormatsTemp = []
                let numberFormatsTemp = []

                currencies.map((item, key) => {
                    const { id, name, symbol } = item
                    currenciesTemp.push({ value: id, label: name, symbol })
                })
                date_formats.map((item, key) => {
                    const { id, format } = item
                    dateFormatsTemp.push({ value: id, label: format })
                })
                number_formats.map((item, key) => {
                    const { id, format } = item
                    numberFormatsTemp.push({ value: id, label: format })
                })

                state.currencies = currenciesTemp
                state.dateFormats = dateFormatsTemp
                state.numberFormats = numberFormatsTemp
            }
        },
        ['newBudget/GetBudgetbyId/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.formData = data?.budget
            }
        },
        ['newBudget/PutBudget/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                toast(data?.message)
            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
        },
        ['auth/Logout/fulfilled']: (state, action) => {
            state.formData = {
                numberFormat: null,
                bugetName: '',
                currency: null,
                currencyPlacement: null,
                dateFormat: null,
            }
        },
    },
});

export const {
    ChangeFormData,
} = newBudgetSlice.actions;

export default newBudgetSlice.reducer;