
import classnames from "classnames";
import React, { useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {
  Button,
  Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem,
  NavLink, UncontrolledDropdown
} from "reactstrap";
import { CreateHeaderTitle } from "../../../Config/Helper";
import { Logout } from "../../Auth/Store/authSlice";
import CustomAvatar from "./CustomAvatar";

function AdminNavbar(props) {

  const dispatch = useDispatch()

  const { userData, selectedBudgetName } = useSelector(state => state.userData)
  const { customHeaerTitle } = useSelector(state => state.shared)

  const [sidebarOpen, ChangeSidebarOpen] = useState(false)
  const [collapseOpen, ChangeCollapseOpen] = useState(false)
  const [color, ChangeColor] = useState("navbar-transparent")


  //* function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      ChangeColor("bg-white")
    } else {
      ChangeColor("navbar-transparent")
    }
  };
  //* this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  //* this function opens and closes the collapse on small devices
  //* it also adds navbar-transparent class to the navbar when closed
  //* ad bg-white when opened
  const toggleCollapse = () => {
    ChangeCollapseOpen(!collapseOpen)
    if (!collapseOpen) {
      ChangeColor("bg-white")
    } else {
      ChangeColor("navbar-transparent")
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
  })

  var a = window.location.hash.split('/');
  var headerTitle = a[2];

  return (
    <>
      <Navbar
        className={classnames("navbar-absolute fixed-top ", color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <span className="d-none d-md-block">
                {customHeaerTitle ?
                  customHeaerTitle
                  :
                  headerTitle == 'budget' ?
                    selectedBudgetName
                    :
                    CreateHeaderTitle(headerTitle)
                }
              </span>
              <span className="d-block d-md-none"></span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            <Nav navbar>
              <NavItem className="align-items-center">
                <NavLink
                  className="btn-magnify mt-2"
                  to="/admin/calendar"
                >
                  <Link to="/admin/calendar" className="align-middle align-items-center mt-3">  <i class="fas fa-calendar-alt"></i></Link>
                  <p>
                    <span className="d-lg-none ml-2 d-md-block">Lock Screen</span>
                  </p>
                </NavLink>
              </NavItem>
              <NavItem className="align-items-center">
                <NavLink
                  className="btn-magnify mt-2"
                  to="/admin/user-profile"
                >
                  <Link to="/admin/user-profile" className="align-middle align-items-center mt-3">  <i className="fas fa-user" /></Link>
                  <p>
                    <span className="d-lg-none ml-2 d-md-block">Lock Screen</span>
                  </p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <CustomAvatar size="40px" />
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                >
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch(Logout(null))
                    }}
                  >
                    <Link to="#" className="nav-logout-hover" >  Logout</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
