import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { GetAllDataOfBudget, UpdateEnvelopeMortgageGoalNames } from '../../Shared/Store/sharedSlice';
import { toast } from 'react-toastify';
import moment from 'moment'
import { apiInstance, apiNoLoaderInstance } from '../../../Config/AxiosInstances';

export const GetTransactionsByExpenseId = createAsyncThunk('myBudget/GetTransactionsByExpenseId', async (params, { dispatch, getState }) => {
    try {

        const expenseId = params

        let result = await apiInstance.get(`/api/trasactions/get-expense-transactions/${expenseId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("error===>", error)
    }
})
export const GetMyBudget = createAsyncThunk('myBudget/GetMyBudget', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData
        const { selectedMonth } = getState().myBudget

        let payload = {
            date: selectedMonth,
            budgetId: selectedBudgetId,
        }

        let [myBudget, budgetOverview, averagebudgetOverview] = await Promise.all([
            apiInstance.get(`/api/envelops/get-envelop-with-children/${selectedBudgetId}/${selectedMonth}`).then(response => response).catch(error => error.response),
            apiInstance.post(`/api/expense-overview`, payload).then(response => response).catch(error => error.response),
            apiInstance.post(`/api/Average-expense-overview`, payload).then(response => response).catch(error => error.response),
        ])

        return { myBudget, budgetOverview, averagebudgetOverview }

    } catch (error) {
        console.error("ERROR===>", error)
    }
})
export const GetExpenseOverview = createAsyncThunk('myBudget/GetExpenseOverview', async (params, { dispatch, getState }) => {
    try {

        const expenseId = params || undefined
        const { selectedMonth } = getState().myBudget
        const { selectedBudgetId } = getState().userData

        let payload = {
            date: selectedMonth,
            subEnvelopId: expenseId,
            budgetId: selectedBudgetId,
        }

        let [
            expenseOverview,
            averageExpenseOverview, //todo: uncomment these after API is created
        ] = await Promise.all([
            apiNoLoaderInstance.post(`/api/expense-overview`, payload).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.post(`/api/Average-expense-overview`, payload).then(response => response).catch(error => error.response),
        ])

        return {
            expenseOverview,
            averageExpenseOverview,
        }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const CreateEnvelope = createAsyncThunk('myBudget/CreateEnvelope', async (params, { dispatch, getState }) => {
    try {

        const { envelopName, suppressToast } = params || {}
        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.post(`/api/envelops`, {
            "budget_id": selectedBudgetId,
            "name": envelopName,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            console.log("error")
            console.log(error)
            return error.response
        })

        const { data, status } = result
        return { data, status, suppressToast }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const EditEnvelope = createAsyncThunk('myBudget/EditEnvelope', async (params, { dispatch, getState }) => {
    try {

        const { envelopeId, envelopName, } = params
        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.post(`/api/edit-envelop/${envelopeId}`, {
            "budget_id": selectedBudgetId,
            "name": envelopName,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            console.log("error")
            console.log(error)
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const CreateExpense = createAsyncThunk('myBudget/CreateExpense', async (params, { dispatch, getState }) => {
    try {

        const {
            expenseNameToAdd,
            selectedEnvelopeId,
            suppressToast,
            accountId = null,
        } = params

        let result = await apiNoLoaderInstance.post(`/api/envelopSubCategories`, {
            "envelop_id": selectedEnvelopeId,
            "envelop_sub_category_name": expenseNameToAdd,
            "account_id": accountId,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            console.log("error")
            console.log(error)
            return error.response
        })

        const { data, status } = result
        return { data, status, suppressToast }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const EditExpense = createAsyncThunk('myBudget/EditExpense', async (params, { dispatch, getState }) => {
    try {

        const { envelopeId, expenseId, expenseName, } = params

        let result = await apiNoLoaderInstance.post(`/api/edit-sub-envelop/${expenseId}`, {
            "envelop_id": envelopeId,
            "envelop_sub_category_name": expenseName,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PostGoal = createAsyncThunk('myBudget/PostGoal', async (params, { dispatch, getState }) => {
    try {

        const { totalAmount, durationType, weeklyValue, monthlyValue, byDateValue, expenseId, suppressToast, selectedMonth } = params || {}

        let date = selectedMonth

        let result = await apiNoLoaderInstance.post('/api/envelopsGoals', {
            'total_amount': totalAmount,
            'envelop_sub_category_id': expenseId,
            'duration_type': durationType,
            'weekly_value': weeklyValue?.value,
            'by_date_value': byDateValue,
            'monthly_value': monthlyValue?.value,
            'date': date,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(UpdateEnvelopeMortgageGoalNames())

        const { data, status } = result
        return { data, status, suppressToast }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PutGoal = createAsyncThunk('myBudget/PutGoal', async (params, { dispatch, getState }) => {
    try {

        const { id, totalAmount, durationType, weeklyValue, monthlyValue, byDateValue, expenseId } = params

        //todo: do this while implementing by_date
        // let date
        // if (durationType === "by_date") {
        //     date = byDateValue.split('/')
        //     date = date[2] + "-" + date[1] + "-" + date[0]
        // } else
        //     date = undefined

        let result = await apiNoLoaderInstance.put(`/api/envelopsGoals/${id}`, {
            'total_amount': totalAmount,
            'envelop_sub_category_id': expenseId,
            'duration_type': durationType,
            'weekly_value': weeklyValue?.value,
            'by_date_value': byDateValue,
            'monthly_value': monthlyValue?.value,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PostNotes = createAsyncThunk('myBudget/PostNotes', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS--")
        console.log(params)

        const note = params
        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.post(`/api/create-note/${selectedBudgetId}`, {
            note,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const HideEnvlope = createAsyncThunk('myBudget/HideEnvlope', async (params, { dispatch, getState }) => {
    try {

        const { envlopeId, isHidden } = params

        let result = await apiNoLoaderInstance.post(`/api/hide-envelop/${envlopeId}`, {
            hide: isHidden ? 1 : 0,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const HideExpense = createAsyncThunk('myBudget/HideExpense', async (params, { dispatch, getState }) => {
    try {

        const { expenseId, isHidden } = params

        let result = await apiNoLoaderInstance.post(`/api/hide-sub-envelop/${expenseId}`, {
            hide: isHidden ? 1 : 0,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const ChangeExpenseOrder = createAsyncThunk('myBudget/ChangeExpenseOrder', async (params, { dispatch, getState }) => {
    try {

        const { envelopeId } = params

        let expenses = getState().myBudget.myBudget.envelops.find(obj => obj.id == envelopeId).envelop_sub_items

        let result = await apiNoLoaderInstance.post(`/api/change-order-subEnvelop`, {
            expenses: JSON.stringify(expenses),
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const DeleteEnvelope = createAsyncThunk('myBudget/DeleteEnvelope', async (params, { dispatch, getState }) => {
    try {

        const { envelopeId, replaceExpense, } = params

        let result = await apiInstance.post(`/api/delete-envelop/${envelopeId}`, {
            'replaceID': replaceExpense ? replaceExpense?.value : null,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(GetMyBudget())

        const { selectedBudgetId } = getState().userData
        dispatch(GetAllDataOfBudget({ selectedBudgetId }))

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const DeleteExpense = createAsyncThunk('myBudget/DeleteExpense', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { expenseId, replaceExpense, } = params

        let result = await apiInstance.post(`/api/delete-sub-envelop/${expenseId}`, {
            'replaceID': replaceExpense ? replaceExpense?.value : null,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(GetMyBudget())

        const { selectedBudgetId } = getState().userData
        dispatch(GetAllDataOfBudget({ selectedBudgetId }))

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const myBudgetSlice = createSlice({
    name: 'myBudget',
    initialState: {
        deleteEnvelopeModal: false,
        deleteExpenseModal: false,
        expenseTransactionsModal: false,
        expenseTransactions: [],
        myBudget: null,
        selectedEnvelopeId: 0,
        selectedExpenseId: 0,
        selectedMonth: moment(new Date()).format("YYYY-MM-DD"),
        submitButtonLoading: false,
        budgetOverview: {
            totalFundsAlloted: 0,
            totalActivity: 0,
            totalFundsAvailable: 0,
            totalCashInflow: 0,

            fundsAllotedLastMonth: 0,
            totalActivityLastMonth: 0,

            averageFundsAllotted: 0,
            averageSpent: 0,
        }
    },
    reducers: {
        ChangeSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload
        },
        ChangeExpenseTransactionsModal: (state, action) => {
            state.expenseTransactionsModal = action.payload
        },
        ChangeDeleteEnvelopeModal: (state, action) => {
            const { deleteEnvelopeModal, selectedEnvelopeId } = action.payload
            state.deleteEnvelopeModal = deleteEnvelopeModal
            state.selectedEnvelopeId = selectedEnvelopeId
        },
        ChangeDeleteExpenseModal: (state, action) => {
            const { deleteExpenseModal, selectedExpenseId } = action.payload
            state.selectedExpenseId = selectedExpenseId
            state.deleteExpenseModal = deleteExpenseModal
        },
        ChangeSubmitButtonLoading: (state, action) => {
            state.submitButtonLoading = action.payload
        },
        ChangeBudgtedAmount: (state, action) => {
            const { value, expenseId, envelopId } = action.payload
            let envelops = []
            current(state.myBudget.envelops).map((item) => {
                let expenses = []
                item.envelop_sub_items.map((subItem) => {
                    if (subItem.sub_cat_id == expenseId) {
                        console.log("MODIFY")
                        expenses.push({
                            ...subItem,
                            total_budget: value,
                        })

                        // let dividedValue = value / subItem.envelop_goal.envelop_goal_amount.length

                        // let envelop_goal_amount = []
                        // subItem.envelop_goal.envelop_goal_amount.map((goalAmount) => {
                        //     envelop_goal_amount.push({
                        //         ...goalAmount,
                        //         budgeted: dividedValue,
                        //     })
                        // })
                    } else {
                        expenses.push(subItem)
                    }
                })
                envelops.push({
                    ...item,
                    envelop_sub_items: expenses
                })
            })
            state.myBudget = {
                ...state.myBudget,
                envelops,
            }
        },
    },
    extraReducers: {
        ['myBudget/GetTransactionsByExpenseId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}

            if (status >= 200 && status < 300) {
                state.expenseTransactions = data?.data
                state.expenseTransactionsModal = true
            }

        },
        ['myBudget/GetExpenseOverview/fulfilled']: (state, action) => {
            const { expenseOverview, averageExpenseOverview } = action.payload || {}

            //* expenseOverview
            if (expenseOverview?.status >= 200 && expenseOverview?.status < 300) {
                const {
                    totalBudget,
                    spent,
                    totalCashInflow,
                    totalAvailableAmount,
                    fundAllocateLastMonth,
                    spentLastMonth,
                } = expenseOverview.data

                state.budgetOverview = {
                    ...state.budgetOverview,
                    totalFundsAlloted: totalBudget,
                    totalActivity: spent,
                    totalFundsAvailable: totalCashInflow,
                    totalCashInflow: totalAvailableAmount,
                    fundsAllotedLastMonth: fundAllocateLastMonth,
                    totalActivityLastMonth: spentLastMonth,
                }
            }

            //* averageexpenseOverview
            if (averageExpenseOverview?.status >= 200 && averageExpenseOverview?.status < 300) {
                const {
                    averageLastMonthBudgeted,
                    averageLastMonthSpent,
                } = averageExpenseOverview.data

                state.budgetOverview = {
                    ...state.budgetOverview,
                    averageFundsAllotted: averageLastMonthBudgeted,
                    averageSpent: averageLastMonthSpent,
                }
            }

        },
        ['myBudget/ChangeExpenseOrder/pending']: (state, action) => {
            const { index1, index2, envelopeId } = action.meta.arg || {}

            let envelops = []
            current(state.myBudget.envelops).map((item) => {
                if (item.id == envelopeId) {

                    let envelop_sub_items = item.envelop_sub_items
                    let expense1 = envelop_sub_items[index1]

                    let envelopSubItems = envelop_sub_items.filter((obj, index) => index != index1)
                    envelopSubItems = [
                        ...envelopSubItems.slice(0, index2),
                        expense1,
                        ...envelopSubItems.slice(index2),
                    ]

                    let envelopSubItemsTemp = []
                    let length = envelopSubItems?.length
                    envelopSubItems.map((item, key) => {
                        envelopSubItemsTemp.push({
                            ...item,
                            order: length - key,
                        })
                    })

                    envelops.push({
                        ...item,
                        envelop_sub_items: envelopSubItemsTemp,
                    })

                } else {
                    envelops.push(item)
                }
            })

            state.myBudget = {
                ...state.myBudget,
                envelops: envelops,
            }
        },
        ['myBudget/GetMyBudget/pending']: (state, action) => {
            state.myBudget = null
        },
        ['myBudget/GetMyBudget/fulfilled']: (state, action) => {
            const { myBudget, budgetOverview, averagebudgetOverview } = action.payload || {}

            //* myBudget
            if (myBudget?.status >= 200 && myBudget?.status < 300) {
                let envelops = []
                myBudget.data.envelops.map((item) => {

                    let envelop_sub_items = []
                    item.envelop_sub_items.map((subItem) => {

                        let envelop_goal_amount = subItem?.envelop_goal?.envelop_goal_amount

                        let total_budget = 0
                        let spent = 0


                        if (envelop_goal_amount)
                            envelop_goal_amount.map((obj => {
                                total_budget += obj.budgeted
                                spent += obj.spent
                            }))

                        let transactionDate = subItem?.envelop_goal?.transaction_date.split('-')
                        if (transactionDate)
                            transactionDate = new Date(transactionDate[0], transactionDate[1] - 1, 1)
                        let selectedDate = state.selectedMonth.split('-')
                        selectedDate = new Date(selectedDate[0], selectedDate[1] - 1, 1)

                        let envelop_goal

                        if (transactionDate <= selectedDate)
                            envelop_goal = subItem.envelop_goal
                        else
                            envelop_goal = null

                        envelop_sub_items.push({
                            ...subItem,
                            spent,
                            total_budget,
                            envelop_goal,
                        })
                    })
                    envelops.push({
                        ...item,
                        envelop_sub_items,
                    })
                })

                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }
            }

            //* budgetOverview
            if (budgetOverview?.status >= 200 && budgetOverview?.status < 300) {
                const {
                    totalBudget,
                    spent,
                    totalCashInflow,
                    totalAvailableAmount,
                    fundAllocateLastMonth,
                    spentLastMonth,
                } = budgetOverview.data

                state.budgetOverview = {
                    ...state.budgetOverview,
                    totalFundsAlloted: totalBudget,
                    totalActivity: spent,
                    totalFundsAvailable: totalCashInflow,
                    totalCashInflow: totalAvailableAmount,
                    fundsAllotedLastMonth: fundAllocateLastMonth,
                    totalActivityLastMonth: spentLastMonth,
                }

            }

            //* averagebudgetOverview
            if (averagebudgetOverview?.status >= 200 && averagebudgetOverview?.status < 300) {
                const {
                    averageLastMonthBudgeted,
                    averageLastMonthSpent,
                } = averagebudgetOverview.data

                state.budgetOverview = {
                    ...state.budgetOverview,
                    averageFundsAllotted: averageLastMonthBudgeted,
                    averageSpent: averageLastMonthSpent,
                }
            }

        },
        ['myBudget/DeleteExpense/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                if (data?.data) {
                    const { } = data?.data

                    state.deleteExpenseModal = false
                    toast(data?.message)
                } else {
                    toast(data?.message)
                }

            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
            // state.submitButtonLoading = false
        },
        ['myBudget/DeleteEnvelope/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, user_id, budget_id, name, hide, } = data?.data

                state.deleteEnvelopeModal = false
                toast(data?.message)

            }
            // state.submitButtonLoading = false
        },
        ['myBudget/HideEnvlope/pending']: (state, action) => {
            let { envlopeId, isHidden } = action.meta.arg

            let envelops = []

            current(state.myBudget.envelops).map((item) => {
                if (item.id == envlopeId)
                    envelops.push({
                        ...item,
                        hide: isHidden,
                    })
                else
                    envelops.push(item)
            })

            state.myBudget = {
                ...state.myBudget,
                envelops,
            }
            // state.submitButtonLoading = true
        },
        ['myBudget/HideEnvlope/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, user_id, budget_id, name, hide, } = data?.data
                toast(data?.message)
            }
            // state.submitButtonLoading = false
        },
        ['myBudget/HideEnvlope/rejected']: (state, action) => {
            // state.submitButtonLoading = false
        },
        ['myBudget/HideExpense/pending']: (state, action) => {

            const { envelopId, expenseId, isHidden } = action.meta.arg

            let envelops = []

            current(state.myBudget.envelops).map((item) => {
                console.log("ITEM")
                console.log(item)
                if (item.id == envelopId) {
                    let envelop_sub_items = []
                    item.envelop_sub_items.map((subItem) => {
                        console.log("SUBITEM")
                        console.log(subItem)
                        if (subItem.sub_cat_id == expenseId)
                            envelop_sub_items.push({
                                ...subItem,
                                hide: isHidden,
                            })
                        else
                            envelop_sub_items.push(subItem)
                    })
                    envelops.push({
                        ...item,
                        envelop_sub_items,
                    })

                } else {
                    envelops.push(item)
                }

                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }
            })

            state.myBudget = {
                ...state.myBudget,
                envelops: envelops,
            }
            // state.submitButtonLoading = true
        },
        ['myBudget/HideExpense/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    id,
                    user_id,
                    envelop_id,
                    envelop_sub_category_name,
                    total_budget,
                    spent,
                    order,
                    hide,
                } = data?.data

                toast(data?.message)
            }
            // state.submitButtonLoading = false
        },
        ['myBudget/HideExpense/rejected']: (state, action) => {
            // state.submitButtonLoading = false
        },
        ['myBudget/PostNotes/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/PostNotes/fulfilled']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/PostNotes/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/CreateEnvelope/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/CreateEnvelope/fulfilled']: (state, action) => {
            const { data, status, suppressToast } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { budget_id, id, name } = data.envelop || {}

                if (state?.myBudget?.envelops)
                    state.myBudget.envelops.push({
                        budget_id,
                        envelop_sub_items: [],
                        id,
                        name,
                        hide: 1,
                    })

                if (!suppressToast)
                    toast(data?.message)

            } else if (status >= 400 && status < 500) {

                if (!suppressToast)
                    toast(data?.message)

            }
            state.submitButtonLoading = false
        },
        ['myBudget/CreateEnvelope/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/EditEnvelope/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/EditEnvelope/fulfilled']: (state, action) => {
            console.log("ACTION.PAYLOAD")
            console.log(action.payload)
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, name } = data.envelop || {}

                let envelops = []

                current(state.myBudget.envelops).map((item) => {
                    if (item.id == id)
                        envelops.push({
                            ...item,
                            name,
                        })
                    else
                        envelops.push(item)
                })

                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }

                toast(data?.message)

            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
            state.submitButtonLoading = false
        },
        ['myBudget/EditEnvelope/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/EditExpense/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/EditExpense/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, user_id, envelop_id, envelop_sub_category_name, total_budget, spent } = data?.envelop_sub_cagetgory || {}

                let envelops = []
                current(state.myBudget.envelops).map((item) => {
                    if (item.id == envelop_id) {

                        let envelop_sub_items = []
                        item.envelop_sub_items.map((subItem) => {
                            if (subItem.sub_cat_id == id)
                                envelop_sub_items.push({
                                    ...subItem,
                                    envelop_sub_category_name,
                                })
                            else
                                envelop_sub_items.push(subItem)
                        })
                        envelops.push({
                            ...item,
                            envelop_sub_items,
                        })

                    } else {
                        envelops.push(item)
                    }

                    state.myBudget = {
                        ...state.myBudget,
                        envelops,
                    }
                })

                state.myBudget = {
                    ...state.myBudget,
                    envelops: envelops,
                }

                toast(data?.message)
            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
            state.submitButtonLoading = false
        },
        ['myBudget/EditExpense/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/CreateExpense/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/CreateExpense/fulfilled']: (state, action) => {
            const { data, status, suppressToast } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { envelop_id, envelop_sub_category_name, id, user_id } = data?.data || {}
                let envelops = []
                if (state?.myBudget?.envelops)
                    current(state.myBudget.envelops).map((item) => {
                        if (item.id == envelop_id) {
                            let modifiedItem = {
                                ...item,
                                envelop_sub_items: [{
                                    sub_cat_id: id,
                                    envelop_sub_category_name,
                                    total_budget: 0,
                                    envelop_goals: new Date(),
                                    manipulated_data_object: new Date(),
                                    envelop_goal: null,
                                    hide: 1,
                                }, ...item.envelop_sub_items],
                            }
                            envelops.push(modifiedItem)
                        } else {
                            envelops.push(item)
                        }
                    })

                state.myBudget = {
                    ...state.myBudget,
                    envelops: envelops,
                }

                if (!suppressToast)
                    toast(data?.message)
            } else if (status >= 400 && status < 500) {
                if (!suppressToast)
                    toast(data?.message)
            }
            state.submitButtonLoading = false
        },
        ['myBudget/CreateExpense/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/PostGoal/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/PostGoal/fulfilled']: (state, action) => {
            const { data, status, suppressToast } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    by_date_value,
                    duration_type,
                    envelop_sub_category_id,
                    id,
                    monthly_value,
                    total_amount,
                    user_id,
                    weekly_value,
                } = data?.envelop_goal || {}
                let envelops = []
                current(state.myBudget.envelops).map((item) => {
                    let expenses = []
                    item.envelop_sub_items.map((subItem) => {
                        if (subItem.sub_cat_id == envelop_sub_category_id && subItem.envelop_goal == null) {
                            console.log("MODIFY")
                            expenses.push({
                                ...subItem,
                                envelop_goal: data?.envelop_goal
                            })
                        } else {
                            expenses.push(subItem)
                        }
                    })
                    envelops.push({
                        ...item,
                        envelop_sub_items: expenses
                    })
                })
                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }
                if (!suppressToast)
                    toast(data?.message)
            }
            state.submitButtonLoading = false
        },
        ['myBudget/PostGoal/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['myBudget/PutGoal/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['myBudget/PutGoal/fulfilled']: (state, action) => {
            console.log("ACTION.PAYLOAD")
            console.log(action.payload)
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    by_date_value,
                    duration_type,
                    envelop_sub_category_id,
                    id,
                    monthly_value,
                    total_amount,
                    user_id,
                    weekly_value,
                } = data?.envelop_goal || {}
                let envelops = []
                current(state.myBudget.envelops).map((item) => {
                    let expenses = []
                    item.envelop_sub_items.map((subItem) => {
                        if (subItem.sub_cat_id == envelop_sub_category_id) {
                            console.log("MODIFY")
                            expenses.push({
                                ...subItem,
                                envelop_goal: data?.envelop_goal
                            })
                        } else {
                            expenses.push(subItem)
                        }
                    })
                    envelops.push({
                        ...item,
                        envelop_sub_items: expenses
                    })
                })
                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }
                toast(data?.message)
            }
            state.submitButtonLoading = false
        },
        ['myBudget/PutGoal/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['shared/AllocateFundsToExpense/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    envelop_id,
                    envelop_sub_category_name,
                    id,
                    // total_budget,
                    user_id,
                    envelop_sub_category_id,
                    envelop_goal_amount,
                } = data?.data || {}

                let total_budget = envelop_goal_amount ? envelop_goal_amount.reduce((n, obj) => n + obj.budgeted, 0) : 0

                let envelops = []
                current(state.myBudget.envelops).map((item) => {
                    let expenses = []
                    item.envelop_sub_items.map((subItem) => {
                        if (subItem.sub_cat_id == envelop_sub_category_id) {
                            console.log("MODIFY")
                            expenses.push({
                                ...subItem,
                                total_budget,
                            })
                        } else {
                            expenses.push(subItem)
                        }
                    })
                    envelops.push({
                        ...item,
                        envelop_sub_items: expenses
                    })
                })
                state.myBudget = {
                    ...state.myBudget,
                    envelops,
                }
            }
        },
        ['auth/Logout/fulfilled']: (state, action) => {
            state.myBudget = null
        },
    },
});

export const {
    ChangeExpenseTransactionsModal,
    ChangeBudgtedAmount,
    ChangeDeleteExpenseModal,
    ChangeDeleteEnvelopeModal,
    ChangeSelectedMonth,
} = myBudgetSlice.actions;

export default myBudgetSlice.reducer;