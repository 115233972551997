//! Dont use Shift + Alt + O here
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';

import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/js/main.js"

import "./App/Styles/CustomStyles.css"
// import './App/Styles/TailwindOutput.css'; //! produces design issues

import store from './App/Store/index'
import MainRoutes from "./App/Screens/Layout/MainRoutes";

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);

ReactDOM.render(
  <Provider {...{ store }}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/#">
        <MainRoutes />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById("root")
);