
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {
    Button, Col, FormGroup, Modal, Row
} from "reactstrap";
import Form from "reactstrap/lib/Form";
import { ChangeDeletePayeeModal, DeletePayee } from "./Store/managePayeesSlice";

function DeletePayeeModal(props) {

    const dispatch = useDispatch()

    const {
        savedPayeeList,
    } = useSelector(state => state.shared)
    const {
        formData,
        deletePayeeModal,
    } = useSelector(state => state.managePayees)

    const [replacePayeeId, setReplacePayeeId] = useState(null)

    return (
        <Modal
            isOpen={deletePayeeModal}
            toggle={() => dispatch(ChangeDeletePayeeModal(false))}
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeDeletePayeeModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="title title-up" style={{ marginTop: "1rem" }}>
                    Delete Payee
                </h5>
            </div>
            <div className="modal-body">
                <Form>
                    <Row>
                        <Col md="12">
                            <label htmlFor="">
                                {`Select a another payee to assign transactions of ${formData?.payeeName}.`}
                            </label>
                            <FormGroup>
                                <Select
                                    onChange={(value) => {
                                        setReplacePayeeId(value)
                                    }}
                                    value={replacePayeeId}
                                    options={savedPayeeList.filter(obj => obj.value != formData.id)}
                                    styles={{
                                    }}
                                    menuColor='red'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#34B5B8' + '55',
                                            primary: '#34B5B8',
                                            primary50: '#34B5B8' + '33',
                                        },
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link "
                        color="info"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeDeletePayeeModal(false))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        onClick={() => dispatch(DeletePayee({ payeeId: formData.id, replacePayeeId }))}
                        className="btn-link btn-link-cancel"
                        color="danger"
                        type="button"
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeletePayeeModal
