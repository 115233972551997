
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import routes from "../../Config/routes"
import AdminNavbar from "../Shared/Components/AdminNavbar"
import Footer from "../Shared/Components/Footer"
import Sidebar from "../Shared/Components/Sidebar"
import {
  GetAllDataOfBudget
} from "../Shared/Store/sharedSlice"
import './Admin.css'

function Admin(props) {

  const backgroundColor = "white"
  const activeColor = "info"

  const dispatch = useDispatch()
  const mainPannelRef = useRef(null)

  const {
    selectedBudgetId
  } = useSelector(state => state.userData)
  const {
    accounts
  } = useSelector(state => state.shared)

  const getSubRoutes = (views, name) => {
    if (name == "Accounts") {
      return getRoutes(accounts)
    } else {
      return getRoutes(views)
    }
  }
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getSubRoutes(prop.views, prop.name)
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini")
  }

  React.useEffect(() => {

    dispatch(GetAllDataOfBudget({ selectedBudgetId }))

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-off"
        document.documentElement.classList.remove("perfect-scrollbar-on")
      }
    }

  }, [])

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div
        className="main-panel custom-main-panel"
        ref={mainPannelRef}
      >
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
        {props.location.pathname.indexOf("full-screen-map") !== -1 ?
          null
          :
          <Footer fluid />
        }
      </div>
    </div>
  )
}

export default Admin
