
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, CardTitle, Col, Row
} from "reactstrap";
import { CreateLinkToken } from "./Stote/addAccountSlice";

function AddAccount(props) {

    const history = useHistory()
    const dispatch = useDispatch()

    const handleNewUnlinkedAccount = async () => {
        history.push('/admin/unlinked-account/0')
    }

    return (
        <>
            <div className="content">
                <Row style={{ justifyContent: "center" }}>
                    <Col md="6" className="ml-auto mr-auto">
                        <Card>
                            <CardHeader className="text-center">
                                <CardTitle tag="h5">Add Account</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Card className="bg-light p-4 text-center font-weight-bold account-card">
                                    <CardBody
                                        onClick={async () => {
                                            await dispatch(CreateLinkToken())
                                            history.push('/admin/add-linked-account')
                                        }}
                                        style={{ fontSize: '20px' }}
                                        className="text-dark"
                                    >
                                        <i class="fas fa-cloud-download-alt " />
                                        {"Linked"}
                                        <p style={{ fontSize: '12px' }} className="font-weight-normal text-muted">
                                            {"Automatically import cleared transactions for easy reconciliation , US and Canada only"}
                                        </p>
                                    </CardBody>
                                </Card>
                                <Card className="bg-light p-4 text-center font-weight-bold  account-card">
                                    <Link
                                        onClick={handleNewUnlinkedAccount}
                                        to="#"
                                    >
                                        <CardBody style={{ fontSize: '20px' }} className="text-dark">
                                            <i class="fas fa-edit " />
                                            {"UnLinked"}
                                            <p style={{ fontSize: '12px' }} className="font-weight-normal text-muted">
                                                {"Start with your current balance and take control by entering your own transactions"}
                                            </p>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AddAccount;
