import darkLogo from "assets/img/logo-dark.png";
import lightLogo from "assets/img/logo-light.png";
import smallLogoDark from "assets/img/small-icon-dark.png";
import smallLogoLight from "assets/img/small-icon-light.png";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Collapse, Nav } from "reactstrap";
import { GetAmount } from "../../../Config/Helper";
import { Logout } from "../../Auth/Store/authSlice";
import { ChangeUserData } from '../../Shared/Store/userDataSlice';
import CustomAvatar from "./CustomAvatar";
import './Sidebar.css';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  createSublinks = (views, name) => {
    if (name == "Accounts") {
      return <ul className="nav">{this.createAccountLinks(this.props.shared.accounts)}</ul>
    } else {
      return <ul className="nav">{this.createLinks(views)}</ul>
    }
  }

  // this function creates the links for accounts and collapses that appear in the sidebar (left menu)
  createAccountLinks = (routes) => {
    return routes.map((item, key) => {
      if (item.invisible) return null;

      if (item.redirect) {
        return null;
      }
      if (item.collapse) {
        var st = {};
        st[item["state"]] = !this.state[item.state];
        return (
          <li
            className={this.getCollapseInitialState(item.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[item.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {item.icon !== undefined ? (
                <>
                  <i className={item.icon} />
                  <p>
                    {item.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{item.mini}</span>
                  <span className="sidebar-normal">
                    {item.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[item.state]}>
              {this.createSublinks(item.views, item.name)}
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(item.layout + item.path)} key={key}>
          <NavLink
            onDoubleClick={(e) => {
              if (item.name != "All Accounts") {
                e.preventDefault()
                this.props.history.push(`/admin/unlinked-account/${item.accountId}`)
              }
            }}
            onClick={(e) => {
              e.preventDefault()
              this.props.history.push(`/admin/accounts/${item.accountId}`)
            }}
            to={'$'}
            activeClassName=""
          >
            {item.icon !== undefined ? (
              <>
                <i className={item.icon} />
                <p>{item.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{item.mini}</span>
                {/* {prop.name == "All Accounts" ? //! difficult to manage onClick here
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  :
                  <span
                    className="sidebar-mini-icon"
                  >
                    <i
                      style={{ fontSize: 15 }}
                      class="fas fa-pencil-alt"
                    ></i>
                  </span>
                } */}
                <span className="sidebar-normal">
                  {item.amount ?
                    `${item.name}(${GetAmount(item.amount)})`
                    :
                    item.name
                  }
                </span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible) return null;

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              {this.createSublinks(prop.views, prop.name)}
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { userData, selectedBudgetId, sidebarColor } = this.props.userData || {}
    const { user } = userData
    const { first_name, last_name } = user

    return (
      <div
        className="sidebar"
        data-color={sidebarColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo text-center">
          <a
            className="simple-text logo-normal"
          >
            {(sidebarColor == "black" || sidebarColor == "brown") ?
              <img src={darkLogo} alt="" width="200px" />
              :
              <img src={lightLogo} alt="" width="200px" />
            }
          </a>
          <a
            className="logo-small"
          >
            {sidebarColor == "white" ?
              <img src={smallLogoDark} alt="" width="200px" />
              :
              <img src={smallLogoLight} alt="" width="200px" />
            }
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="photo custom-photo">
              <CustomAvatar size="40px" />
            </div>
            <div className="info">
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {`${first_name} `}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  {/* <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/admin/manage-payees" activeClassName="">
                      <span className="sidebar-mini-icon"><i className="fas fa-user-cog"></i></span>
                      <span className="sidebar-normal">Manage Payees</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/my-budget/${selectedBudgetId}`}
                      activeClassName=""
                    >
                      <span className="sidebar-mini-icon"><i className="fas fa-cog"></i></span>
                      <span className="sidebar-normal">Budget Settings</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/display-settings" activeClassName="">
                      <span className="sidebar-mini-icon"><i className="fas fa-cog"></i></span>
                      <span className="sidebar-normal">Display Settings</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/add-linked-account" activeClassName="">
                      <span className="sidebar-mini-icon">
                        <i className="fas fa-university fa-2x"></i>
                      </span>
                      <span className="sidebar-normal">Add Connection</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/general-settings" activeClassName="">
                      <span className="sidebar-mini-icon"><i className="fas fa-tools"></i></span>
                      <span className="sidebar-normal">General Settings</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => this.props.Logout()} to="#" activeClassName="">
                      <span className="sidebar-mini-icon"><i className="fas fa-sign-out-alt"></i></span>
                      <span className="sidebar-normal">Log Out</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav style={{ fontWeight: '400' }}>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div >
    );
  }
}

const mapStateToProps = ({ userData, shared, displaySettings }) => ({
  userData, shared, displaySettings
})

export default connect(mapStateToProps, {
  ChangeUserData,
  Logout,
})(Sidebar)
