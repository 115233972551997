
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Table } from "reactstrap";
import { GetAmount, GetDate } from "../../Config/Helper";
import {
    ChangeExpenseTransactionsModal
} from "./Store/myBudgetSlice";

const ExpenseTransactionsModal = (props) => {

    const dispatch = useDispatch()

    const {
        expenseTransactionsModal,
        expenseTransactions,
    } = useSelector(state => state.myBudget)

    function RenderTransactions() {
        return expenseTransactions?.map((item) => {

            const {
                id,
                payee,
                in_flow_amount,
                out_flow_amount,
                memo,
                transaction_date,
                sub_envelop,
                account,
            } = item || {}

            return (
                <tr key={id}>
                    <td className="custom-transaction-td text-center" >
                        {account?.account_title}
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {GetDate(transaction_date)}
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {payee ?
                            payee?.payee_name
                            :
                            "N.A"
                        }
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {sub_envelop?.envelop_sub_category_name}
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {memo ?
                            memo
                            :
                            "N.A"
                        }
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {GetAmount(out_flow_amount)}
                    </td>
                    <td className="custom-transaction-td text-center" >
                        {GetAmount(in_flow_amount)}
                    </td>
                </tr>
            )
        })
    }

    React.useEffect(() => {
    }, [])

    return (
        <Modal
            isOpen={expenseTransactionsModal}
            toggle={() => dispatch(ChangeExpenseTransactionsModal(false))}
            className="modal-lg"
        >
            <div className="modal-header justify-content-center custom-modal-header">
                <button
                    aria-label="Close"
                    className="close custom-modal-close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeExpenseTransactionsModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-info" style={{ marginTop: "1.5rem" }}>
                    {"Expense Transactions"}
                </h5>
            </div>
            <div className="modal-body custom-modal-body">
                <Table hover className="table-bordered account-table" style={{ fontSize: '12px' }}>
                    <thead className="text-dark bg-light" >
                        <tr >
                            <th className="border text-center">Account</th>
                            <th className="border text-center px-5 ">Date</th>
                            <th className="border text-center" >Payee</th>
                            <th className="border text-center" >Expense</th>
                            <th className="border text-center" >Memo</th>
                            <th className="border text-center" >Outflow</th>
                            <th className="border text-center" >Inflow</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderTransactions()}
                    </tbody>
                </Table>
            </div>
        </Modal>
    );
}

export default ExpenseTransactionsModal