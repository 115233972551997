
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {
    Button, Form, FormGroup,
    Input, Modal
} from "reactstrap";
import { ChangeAddGoalModal, PostGoal, PutGoal } from './Store/myGoalsSlice'
import { Controller, useForm } from "react-hook-form";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Select from "react-select";
import { GetDateFormat } from "../../Config/Helper";

const AddGoalModal = (props) => {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var month = new Array();
    month[0] = "January"; month[1] = "February"; month[2] = "March"; month[3] = "April"; month[4] = "May"; month[5] = "June"; month[6] = "July"; month[7] = "August"; month[8] = "September"; month[9] = "October"; month[10] = "November"; month[11] = "December";
    var d = new Date();

    const goalTypeList = [
        { value: "0", label: "Goal" },
        { value: "1", label: "Objective" },
    ]

    const defaultValues = {
        id: 0,
        title: null,
        goalType: { value: "0", label: "Goal" },
        amount: "0",
        requiredBy: null,
    }

    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors }, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { addGoalModal, formData } = useSelector(state => state.myGoals)

    const [goalType, setGoalType] = useState("0")

    useEffect(() => {
        if (formData?.id != 0) {
            const { id, title, goalType, amount, requiredBy } = formData
            reset({
                id: id,
                title: title,
                goalType: goalTypeList.find(obj => obj.value == goalType),
                amount: amount,
                requiredBy: requiredBy,
            })
        } else {
            reset(defaultValues)
        }
    }, [formData])
    return (
        <Modal
            isOpen={addGoalModal}
            toggle={() => dispatch(ChangeAddGoalModal(!addGoalModal))}
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeAddGoalModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-info" style={{ marginTop: "1.5rem" }}>
                    {formData?.id == 0 ? "Add" : "Edit"}
                    {goalType == "0" ? " Goal" : " Objective"}
                </h5>
            </div>
            <div className="modal-body">
                <div>
                    <label htmlFor="">Select</label>
                    <FormGroup>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={value}
                                    onChange={(value) => {
                                        onChange(value)
                                        setGoalType(value.value)
                                    }}
                                    options={goalTypeList}
                                    placeholder="Select goal / objective"
                                />
                            )}
                            name="goalType"
                        />
                        {errors.goalType &&
                            <div className="text text-danger error-text">Select goal / objective</div>
                        }
                    </FormGroup>
                    <label htmlFor="">Title</label>
                    <FormGroup>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    placeholder={"Enter Title"}
                                />
                            )}
                            name="title"
                        />
                        {errors.title &&
                            <div className="text text-danger error-text">Enter goal title</div>
                        }
                    </FormGroup>
                    <label htmlFor="">Amount</label>
                    <FormGroup>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                                pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    placeholder={"Enter Amount"}
                                    type="number"
                                />
                            )}
                            name="amount"
                        />
                        {errors.amount &&
                            <div className="text text-danger error-text">Enter amount</div>
                        }
                    </FormGroup>
                    <label htmlFor="">Required By</label>
                    <FormGroup>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value } }) => {
                                let date
                                if (value) {
                                    if (value.includes('/')) {
                                        date = value.split("/")
                                        date = new Date(date[2], date[1] - 1, date[0])
                                    }
                                    else {
                                        date = value.split(" ")[0].split('-')
                                        date = new Date(date[0], date[1] - 1, date[2])
                                    }

                                } else {
                                    date = null
                                }
                                // let date = value ? new Date(value) : new Date()
                                return (
                                    <ReactDatetime
                                        onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                        value={date}
                                        inputProps={{
                                            className: "form-control text-center",
                                            placeholder: "Select date",
                                            readOnly: true,
                                        }}
                                        dateFormat={GetDateFormat()}
                                        timeFormat={false}
                                    />
                                )
                            }}
                            name="requiredBy"
                        />
                        {errors.requiredBy &&
                            <div className="text text-danger error-text">Enter required by</div>
                        }
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link btn-link-cancel"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeAddGoalModal(!addGoalModal))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    {formData?.id == 0 ?
                        <Button
                            className="btn-link "
                            color="primary"
                            type="button"
                            onClick={handleSubmit(async (data) => {
                                console.log("DATA")
                                console.log(data)
                                await dispatch(PostGoal(data))
                                reset({
                                    id: 0,
                                    title: '',
                                    goalType: { value: "0", label: "Goal" },
                                    amount: "0",
                                    requiredBy: null,
                                })
                            })}
                        >
                            Add
                        </Button>
                        :
                        <Button
                            className="btn-link "
                            color="primary"
                            type="button"
                            onClick={handleSubmit(async (data) => {
                                console.log("DATA")
                                console.log(data)
                                await dispatch(PutGoal(data))
                                reset({
                                    id: 0,
                                    title: '',
                                    goalType: { value: "0", label: "Goal" },
                                    amount: "0",
                                    requiredBy: null,
                                })
                            })}
                        >
                            Edit
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default AddGoalModal