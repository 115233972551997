
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from 'react-router-dom';
import {
    Button,
    Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row
} from "reactstrap";
import bank_logo from '../../Assets/Images/bank-logo.png';

class AddBankInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            alert: null,
        };
    }

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Account Added!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                >
                    <Link to="/admin/add-account" className="border text-dark bg-light border-primary mt-3 rounded p-1"><i className="fas fa-plus-circle text-primary"></i> Add Another Account</Link>
                </ReactBSAlert>
            ),
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };
    render() {
        return (
            <>
                <div className="content">
                    {this.state.alert}

                    <Row>
                        <Col md="6" className="ml-auto mr-auto">
                            <Card className="h-100">
                                <CardHeader className="text-center">
                                    <CardTitle tag="h6">Linked Account</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form >

                                        <div class=" mb-3  text-center rounded ">
                                            <img src={bank_logo} width="180px" alt="" className="img-fluid" />
                                        </div>

                                        <h5 className="mt-5 font-weight-bold">Enter Your Credentials</h5>
                                        <label htmlFor="">User ID</label>
                                        <FormGroup>
                                            <Input type="text" />
                                        </FormGroup>
                                        <label htmlFor="">Password</label>
                                        <FormGroup>
                                            <Input type="password" />
                                        </FormGroup>
                                    </Form>

                                    <div className="text-center">
                                        <Button color="primary" >Submit</Button>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>


                    </Row>
                </div>
            </>
        );
    }
}

export default AddBankInfo;
