//! COMPLEX CODE
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactDatetime from "react-datetime"
import { Controller, useForm } from "react-hook-form"
import { BsFillCheckSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select"
import ScaleLoader from "react-spinners/ScaleLoader"
import { toast } from 'react-toastify'
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input, PopoverBody,
    Row,
    Table,
    UncontrolledPopover
} from "reactstrap"
import { GlobalContext } from '../../../context/GlobalState'
import { GetAmount, GetDate, GetDateFormat, WeekOfMonth } from '../../Config/Helper'
import AddGoalModal from '../MyGoals/AddGoalModal'
import { ChangeAddGoalModal } from '../MyGoals/Store/myGoalsSlice'
import FundsToAllotBtn from '../Shared/Components/FundsToAllotBtn'
import { AllocateFundsToExpense } from '../Shared/Store/sharedSlice'
import DeleteEnvelopeModal from './DeleteEnvelopeModal'
import DeleteExpenseModal from './DeleteExpenseModal'
import ExpenseTransactionsModal from './ExpenseTransactionsModal'
import './MyBudget.css'
import {
    ChangeBudgtedAmount,
    ChangeDeleteEnvelopeModal,
    ChangeDeleteExpenseModal,
    ChangeExpenseOrder,
    ChangeSelectedMonth,
    CreateEnvelope,
    CreateExpense,
    EditEnvelope,
    EditExpense,
    GetExpenseOverview,
    GetMyBudget,
    GetTransactionsByExpenseId,
    HideEnvlope,
    HideExpense,
    PostGoal,
    PostNotes,
    PutGoal
} from './Store/myBudgetSlice'

const MyBudget = (props) => {

    const weeklyList = [
        { value: "Monday", label: "Monday" },
        { value: "Tuesday", label: "Tuesday" },
        { value: "Wednesday", label: "Wednesday" },
        { value: "Thursday", label: "Thursday" },
        { value: "Friday", label: "Friday" },
        { value: "Saturday", label: "Saturday" },
        { value: "Sunday", label: "Sunday" },
    ]
    const monthlyList = [

        { value: "1st", label: "1st" },
        { value: "2nd", label: "2nd" },
        { value: "3rd", label: "3rd" },
        { value: "4th", label: "4th" },
        { value: "5th", label: "5th" },
        { value: "6th", label: "6th" },
        { value: "7th", label: "7th" },
        { value: "8th", label: "8th" },
        { value: "9th", label: "9th" },
        { value: "10th", label: "10th" },
        { value: "11th", label: "11th" },
        { value: "12th", label: "12th" },
        { value: "13th", label: "13th" },
        { value: "14th", label: "14st" },
        { value: "15th", label: "15th" },
        { value: "16th", label: "16th" },
        { value: "17th", label: "17th" },
        { value: "18th", label: "18th" },
        { value: "19th", label: "19th" },
        { value: "20th", label: "20th" },
        { value: "21th", label: "21th" },
        { value: "22th", label: "22th" },
        { value: "23th", label: "23th" },
        { value: "24th", label: "24th" },
        { value: "25th", label: "25th" },
        { value: "26th", label: "26th" },
        { value: "27th", label: "27th" },
        { value: "28th", label: "28th" },
        { value: "29th", label: "29th" },
        { value: "30th", label: "30th" },
        { value: "31st", label: "31st" },
    ]

    const { myBudget, submitButtonLoading, selectedMonth, budgetOverview } = useSelector(state => state.myBudget)
    const { selectedBudgetId } = useSelector(state => state.userData)
    const { budgetNotes } = useSelector(state => state.shared)

    const [showGoal, setShowGoal] = useState(false)
    const [showHidden, setShowHidden] = useState(false)
    const [envelope, setEnvelope] = useState('')
    const [expenseName, setExpenseName] = useState('')
    const [expenseNameToAdd, setExpenseNameToAdd] = useState('')
    const [expenseId, setExpenseId] = useState('')
    const [selectedEnvelopeId, setSelectedEnvelopeId] = useState(0)
    const [selectedEnvelopeName, setSelectedEnvelopeName] = useState('')
    const [selectedExpenseId, setSelectedExpenseId] = useState(0)
    const [selectedExpenseName, setSelectedExpenseName] = useState('')
    const [durationType, setDurationType] = useState("monthly")
    const [showGoalForm, setShowGoalForm] = useState(false)
    const [budgetNotesLocal, setBudgetNotesLocal] = useState(budgetNotes)
    const [requiredMessage, setRequiredMessage] = useState('')
    const [hasAnyExpenses, setHasAnyExpenses] = useState(budgetNotes)

    const { categoryGroups } = useContext(GlobalContext)
    const [updateCategoryGroups, setUpdateCategoryGroups] = useState(categoryGroups)
    const [goalExpenseName, setGoalExpenseName] = useState('')
    const [budgetedAmount, setBudgtedAmount] = useState('')
    const [spentAmount, setSpentAmount] = useState('')
    const [unspendAmount, setUnspendAmount] = useState('')

    const [addEnvelopePopover, setAddEnvelopePopover] = useState(false)
    const [addExpensePopover, setAddExpensePopover] = useState(false)
    const [editEnvlopePopover, setEditEnvlopePopover] = useState(false)
    const [enterNotePopover, setEnterNotePopover] = useState(false)
    const [currentlyCheckedExpense, setCurrentlyCheckedExpense] = useState(null)

    const toggleAddEnvelopePopover = () => setAddEnvelopePopover(!addEnvelopePopover)
    const toggleAddExpensePopover = () => setAddExpensePopover(!addExpensePopover)
    const toggleEnterNotePopover = () => setEnterNotePopover(!enterNotePopover)

    const defaultValues = {
        id: 0,
        totalAmount: "0",
        durationType: "monthly",
        weeklyValue: null,
        monthlyValue: null,
        byDateValue: null,
        selectedMonth,
    }

    const lastDivRef = useRef(null)
    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors }, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    })
    const watchId = watch("id")
    const watchSelectedMonth = watch("selectedMonth")


    const addEnvelopeGroup = async (e) => {
        e.preventDefault()
        if (selectedBudgetId == 0) {
            toast("Please add/select a budget first")
        } else {
            let action = await dispatch(CreateEnvelope({ envelopName: envelope }))
            console.log("action===>", action)
            const { status } = action?.payload

            if (status >= 200 && status < 300) {
                lastDivRef.current.scrollIntoView({
                    block: "center",
                    inline: "nearest",
                    behavior: 'smooth',
                })
                toggleAddEnvelopePopover()
            }
        }
    }

    const setExpenseGoalInfo = (
        showGoalValue,
        goalId,
        envelopId,
        expenseId,
        expenseName,
        durationType,
        totalAmount,
        monthlyValue,
        weeklyValue,
        byDateValue,
        envelopGoalAmount
    ) => {

        let byDateValue_
        if (byDateValue) {
            byDateValue_ = byDateValue.split('/')
            byDateValue_ = new Date(byDateValue_[2], byDateValue_[1] - 1, byDateValue_[0])
        }

        let amount
        if (durationType == 'weekly') {

            let currentWeekOfMonth = WeekOfMonth(new Date)
            if (currentWeekOfMonth > 4)
                currentWeekOfMonth = 4
            if (envelopGoalAmount.length > 0) {
                amount = envelopGoalAmount[currentWeekOfMonth - 1]?.total_amount
                amount = GetAmount(amount)
                setRequiredMessage(`Required ${amount} on ${weeklyValue}`)
            } else
                setRequiredMessage(``)

        } else if (durationType == 'monthly') {
            amount = GetAmount(totalAmount)
            setRequiredMessage(`Required ${GetAmount(totalAmount)} on ${monthlyValue}`)
        } else if (durationType == 'by_date') {
            amount = GetAmount(totalAmount)
            setRequiredMessage(`Required ${amount} on ${GetDate(byDateValue_)}`)
        } else
            setRequiredMessage(``)


        setShowGoal(showGoalValue)
        setSelectedEnvelopeId(envelopId)
        setExpenseId(expenseId)
        setExpenseName(expenseName)

        reset({
            id: goalId,
            totalAmount: totalAmount,
            durationType,
            weeklyValue: weeklyValue == null ? null : weeklyList.find(obj => obj.value == weeklyValue),
            monthlyValue: monthlyValue == null ? null : monthlyList.find(obj => obj.value == monthlyValue),
            byDateValue,
            selectedMonth: watchSelectedMonth,
        })
        setDurationType(durationType)
    }

    const addExpense = async (e) => {
        e.preventDefault()
        await dispatch(CreateExpense({ expenseNameToAdd, selectedEnvelopeId }))
        toggleAddExpensePopover()
    }

    const setGoalAmountFunc = (id) => {

    }

    const handleOnDragEnd = (result) => {
        if (!result?.destination) return

        const {
            destination: {
                droppableId: envelopeId,
                index: index2,
            },
            source: {
                index: index1,
            },
        } = result

        if (index1 == index2) return

        dispatch(ChangeExpenseOrder({ index1, index2, envelopeId }))
    }

    const handleCheckboxClicked = (combinedId, sub_cat_id) => {
        setTimeout(() => {
            if (currentlyCheckedExpense == combinedId) {
                dispatch(GetExpenseOverview())
                setCurrentlyCheckedExpense(null)
            }
            else {
                dispatch(GetExpenseOverview(sub_cat_id))
                setCurrentlyCheckedExpense(combinedId)
            }
        }, 0)
    }

    const getUnspentBgColor = (target, budgeted, spent, unspent) => {

        let numTarget = target ? target : 0

        if (numTarget == 0 || (unspent == 0 && spent != 0))
            return {
                backgroundColor: '#E3E3E3',
                color: `black`
            }
        else if (unspent < 0)
            return {
                backgroundColor: '#EF8357',
                color: `white`
            }
        else if (numTarget > budgeted)
            return {
                backgroundColor: '#FBC658',
                color: `white`
            }
        else if ((numTarget <= budgeted) && budgeted > 0)
            return {
                backgroundColor: '#17aa17',
                color: `white`
            }
        else
            return {
                backgroundColor: '#E3E3E3',
                color: `black`
            }
    }

    const RenderExpenses = (expenses, envelopId) => {
        let expensesToMap

        if (!showHidden)
            expensesToMap = expenses.filter(obj => obj.hide == 1)
        else
            expensesToMap = expenses

        return expensesToMap.map((item, key) => {

            const {
                envelop_goal,
                envelop_goals,
                envelop_sub_category_name,
                manipulated_data_object,
                sub_cat_id,
                hide,
                total_budget,
                spent,
            } = item || {}
            const {
                id,
                envelop_sub_category_id,
                total_amount = 0,
                duration_type,
                weekly_value,
                monthly_value,
                by_date_value,
                transaction_date,
                status,
                envelop_goal_amount,
            } = envelop_goal || {}


            let combinedId = envelopId.toString() + sub_cat_id.toString()

            if (!hasAnyExpenses)
                setHasAnyExpenses(true)

            let spentNum = parseFloat(spent)
            spentNum = isNaN(spentNum) ? 0 : spentNum

            let unSpent = (parseFloat(total_budget) + spentNum) ? (parseFloat(total_budget) + spentNum) : 0

            let requiredBy = null
            let totalAmount

            if (duration_type == "by_date") {

                let byDateValue = moment(by_date_value, 'DD/MM/YYYY').format('MM/YYYY')
                let transactionDate = moment(transaction_date, 'YYYY-MM-DD').format('MM/YYYY')
                let selectedMonthFormatted = moment(selectedMonth, 'YYYY-MM-DD').format('MM/YYYY')
                let monthsDifference = moment(byDateValue, 'MM/YYYY').diff(moment(transactionDate, 'MM/YYYY'), 'months')
                let byDateActualDate = moment(by_date_value, 'DD/MM/YYYY')
                let selectedMonthActualDate = moment(selectedMonth, 'YYYY-MM-DD')

                if (selectedMonthFormatted == byDateValue) {

                    let perMonthAmount = (total_amount / (monthsDifference + 1)).toFixed(0)
                    totalAmount = (total_amount - perMonthAmount * monthsDifference).toFixed(0)

                    requiredBy = `${GetAmount(totalAmount)} this ${moment(by_date_value, 'DD/MM/YYYY').format('Do')}`
                }
                else if (byDateActualDate.isBefore(selectedMonthActualDate)) {
                    totalAmount = null
                    requiredBy = null
                }
                else {
                    totalAmount = (total_amount / (monthsDifference + 1)).toFixed(0)
                    requiredBy = `${GetAmount(totalAmount)} this month`
                }

            } else if (duration_type == "monthly") {
                totalAmount = total_amount
                requiredBy = GetAmount(total_amount) + " on " + monthly_value
            }
            else if (duration_type == "weekly") {
                totalAmount = total_amount
                requiredBy = GetAmount(total_amount) + " this month"
            }

            let unSpentStyle = getUnspentBgColor(totalAmount, total_budget, spentNum, unSpent)

            let progress = (total_budget / parseFloat(totalAmount)) * 100
            progress = isNaN(progress) ? (total_budget > 0 ? 100 : 0) : progress

            totalAmount = requiredBy ? (total_amount ? total_amount : null) : null

            return (
                <Draggable
                    key={combinedId}
                    draggableId={sub_cat_id}
                    index={key}
                    className="expense-row"
                >
                    {(provided) => (
                        <>
                            <div
                                className="budget-head"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => {

                                    setCurrentlyCheckedExpense(combinedId)
                                    dispatch(GetExpenseOverview(sub_cat_id))

                                    if (envelop_goal) {
                                        setExpenseGoalInfo(
                                            true,
                                            id,
                                            envelopId,
                                            sub_cat_id,
                                            envelop_sub_category_name,
                                            duration_type,
                                            total_amount,
                                            monthly_value,
                                            weekly_value,
                                            by_date_value,
                                            envelop_goal_amount,
                                        )
                                    } else {
                                        setExpenseGoalInfo(
                                            true,
                                            0,
                                            envelopId,
                                            sub_cat_id,
                                            envelop_sub_category_name,
                                            null,
                                            "0",
                                            null,
                                            null,
                                            by_date_value,
                                            envelop_goal_amount,
                                        )
                                    }
                                }}
                            >
                                <div
                                    className="budget-col1"
                                >
                                    {currentlyCheckedExpense == combinedId ?
                                        <BsFillCheckSquareFill
                                            onClick={() => handleCheckboxClicked(combinedId, sub_cat_id)}
                                            className='check-icon'
                                        />
                                        :
                                        <div
                                            onClick={() => handleCheckboxClicked(combinedId, sub_cat_id)}
                                            className='checkbox-wrapper'
                                        />
                                    }
                                </div>
                                <div
                                    className="text-left ml-3 borderless expense-div budget-col2"
                                    onClick={() => {
                                        setSelectedExpenseId(sub_cat_id)
                                        setSelectedExpenseName(envelop_sub_category_name)
                                    }}
                                    id={`edit-expense-name-popover${sub_cat_id}`}
                                >
                                    <span className="expense-target">
                                        <span className="expense-target-text">
                                            {envelop_sub_category_name}
                                        </span>
                                        {totalAmount &&
                                            <span className="expense-target-chip bg-success hide-in-phone" style={{ fontSize: '10px', float: "right", color: "black", padding: 2, borderRadius: 5, marginBottom: '5px' }}>
                                                {requiredBy}
                                            </span>
                                        }
                                    </span>
                                    <div className="expense-progress hide-in-phone">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="budget-col3"
                                >
                                    <Input
                                        type="number"
                                        name="budgeted-amount" className="form-control-sm p-0 buget-input"
                                        disabled={!envelop_goal || !totalAmount}
                                        style={{ width: '70px', paddingLeft: '10px !important' }}
                                        placeholder={GetAmount(0.00)}
                                        value={total_budget}
                                        onBlur={(e) => {
                                            dispatch(AllocateFundsToExpense({
                                                amountToAllocate: e.target.value,
                                                expenseValue: {
                                                    value: sub_cat_id,
                                                }
                                            }))
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                e.target.blur()
                                            }
                                        }}
                                        onChange={(e) => {
                                            // setBudgtedAmount(e.target.value)
                                            dispatch(ChangeBudgtedAmount({ value: e.target.value, expenseId: sub_cat_id, envelopId }))
                                        }}
                                    />
                                </div>
                                <div className="budget-col4" >
                                    <span
                                        onClick={() => {
                                            if (spentNum != 0)
                                                dispatch(GetTransactionsByExpenseId(sub_cat_id))
                                        }}
                                        className={spentNum != 0 ? 'spent-text' : ''}
                                    >
                                        {spentNum}
                                    </span>
                                </div>
                                <div className="budget-col5">
                                    <span
                                        className='unspent-capsule'
                                        style={{ ...unSpentStyle }}
                                    >
                                        {unSpent}
                                    </span>
                                </div>
                            </div >
                            {selectedExpenseId == sub_cat_id &&
                                <UncontrolledPopover
                                    placement="bottom"
                                    popperClassName="big-width"
                                    isOpen={selectedExpenseId == sub_cat_id}
                                    target={`edit-expense-name-popover${sub_cat_id}`}
                                    trigger="legacy" toggle={() => setSelectedExpenseId(0)}
                                >
                                    <PopoverBody PopoverBody className="w-100" >
                                        <FormGroup>
                                            <Input
                                                value={selectedExpenseName}
                                                placeholder="Edit Name"
                                                onChange={(e) => setSelectedExpenseName(e.target.value)}
                                            />
                                        </FormGroup>

                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex p-4 left-btns">
                                                <button
                                                    disabled={submitButtonLoading}
                                                    className="btn-btn-sm btn-danger btn-round custom-btn"
                                                    onClick={() => {
                                                        dispatch(ChangeDeleteExpenseModal({ deleteExpenseModal: true, selectedExpenseId: sub_cat_id }))
                                                        setSelectedExpenseId(0)
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    disabled={submitButtonLoading}
                                                    className="btn-btn-sm btn-info btn-round custom-btn"
                                                    onClick={async () => {
                                                        if (!showHidden)
                                                            setSelectedExpenseId(0)
                                                        await dispatch(HideExpense({ envelopId, expenseId: sub_cat_id, isHidden: !hide }))
                                                    }}
                                                >
                                                    <>
                                                        {hide == 1 ?
                                                            "Hide"
                                                            :
                                                            "Show"
                                                        }
                                                    </>
                                                </button>
                                            </div>
                                            <div className="d-flex p-4">
                                                <a
                                                    className="btn-btn-sm btn-danger btn-round custom-btn"
                                                    onClick={() => {
                                                        setSelectedExpenseId(0)
                                                    }}
                                                >
                                                    {"Cancel"}
                                                </a>
                                                <button
                                                    disabled={submitButtonLoading}
                                                    className="btn-btn-sm btn-success btn-round custom-btn"
                                                    onClick={async () => {
                                                        setGoalExpenseName(selectedExpenseName)
                                                        await dispatch(EditExpense({
                                                            envelopeId: envelopId,
                                                            expenseId: selectedExpenseId,
                                                            expenseName: selectedExpenseName,
                                                        }))
                                                        setSelectedExpenseId(0)
                                                    }}
                                                >
                                                    {submitButtonLoading ?
                                                        <ScaleLoader
                                                            color={"#fff"}
                                                            loading={true}
                                                            size={50}
                                                            height={10}
                                                            width={2}
                                                        />
                                                        :
                                                        "Ok"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </PopoverBody >
                                </UncontrolledPopover >
                            }
                        </>
                    )}
                </Draggable >
            )
        })
    }
    const RenderEnvelops = () => {
        const { envelops } = myBudget || {}

        let envelopesToMap
        if (!showHidden)
            envelopesToMap = envelops.filter(obj => obj.hide == 1)
        else
            envelopesToMap = envelops

        return envelopesToMap.map((item, key) => {
            const { budget_id, envelop_sub_items, id, name, hide } = item
            let stringId = id.toString()
            return (
                <div key={id}>
                    <div className="accordion-item">
                        <div className="d-flex accordion-header align-items-center " id={`panelsStayOpen-heading${key}`}>
                            <button className="accordion-button position-relative pl-5" type="button" data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-collapse${key}`} aria-expanded="true"
                                aria-controls={`panelsStayOpen-collapse${key}`}

                            >
                                {name}
                            </button>
                            <i
                                id={`add-expense-popover-${id}`}
                                className="fas fa-plus-circle text-primary position-absolute pl-2"
                                onClick={() => {
                                    setSelectedEnvelopeId(id)
                                    toggleAddExpensePopover()
                                }}
                            />
                            <i
                                id={`edit-envlope-popover${id}`}
                                className="fa fa-pencil-square text-primary position-absolute ml-4"
                                onClick={() => {
                                    setSelectedEnvelopeId(id)
                                    setSelectedEnvelopeName(name)
                                    setEditEnvlopePopover(true)
                                }}
                            />
                        </div >
                        <div id={`panelsStayOpen-collapse${key}`} className="accordion-collapse collapse show" aria-labelledby={`panelsStayOpen-heading${key}`} >
                            <div className="accordion-body" onClick={() => setGoalAmountFunc(key)}>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId={stringId}>
                                        {(provided) => (
                                            <div
                                                id="my-scroll-bar"
                                                className="table table-hover budget-table "
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {RenderExpenses(envelop_sub_items, id)}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div >
                    {/* //todo: Can be used to solve toggle in List Problem in react */}
                    <UncontrolledPopover
                        placement="bottom"
                        popperClassName="big-width"
                        isOpen={editEnvlopePopover && selectedEnvelopeId == id}
                        target={`edit-envlope-popover${id}`}
                        trigger="legacy"
                        toggle={() => {
                            setSelectedEnvelopeId(0)
                            setEditEnvlopePopover(false)
                        }}
                    >
                        <PopoverBody PopoverBody className="w-100" >
                            <FormGroup>
                                <Input
                                    value={selectedEnvelopeName}
                                    placeholder="Edit Name"
                                    onChange={(e) => setSelectedEnvelopeName(e.target.value)}
                                />
                            </FormGroup>

                            <div className="d-flex justify-content-between">
                                <div className="d-flex p-4 left-btns">
                                    <button
                                        disabled={submitButtonLoading}
                                        className="btn-btn-sm btn-danger btn-round custom-btn"
                                        onClick={async () => {
                                            dispatch(ChangeDeleteEnvelopeModal({ deleteEnvelopeModal: true, selectedEnvelopeId }))
                                            setSelectedExpenseId(0)
                                            setEditEnvlopePopover(false)
                                        }}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        disabled={submitButtonLoading}
                                        className="btn-btn-sm btn-info btn-round custom-btn"
                                        onClick={async () => {
                                            if (!showHidden)
                                                setEditEnvlopePopover(false)
                                            await dispatch(HideEnvlope({ envlopeId: id, isHidden: !hide, }))
                                            setSelectedEnvelopeId(0)
                                        }}
                                    >
                                        <>
                                            {hide == 1 ?
                                                "Hide"
                                                :
                                                "Show"
                                            }
                                        </>
                                    </button>
                                </div>
                                <div className="d-flex p-4">
                                    <a
                                        className="btn-btn-sm btn-danger btn-round custom-btn"
                                        onClick={() => {
                                            setSelectedEnvelopeId(0)
                                            setEditEnvlopePopover(false)
                                        }}
                                    >
                                        {"Cancel"}
                                    </a>
                                    <button
                                        disabled={submitButtonLoading}
                                        className="btn-btn-sm btn-success btn-round custom-btn"
                                        onClick={async () => {
                                            await dispatch(EditEnvelope({
                                                envelopeId: selectedEnvelopeId,
                                                envelopName: selectedEnvelopeName
                                            }))
                                            setSelectedEnvelopeId(0)
                                            setEditEnvlopePopover(false)
                                        }}
                                    >
                                        {submitButtonLoading ?
                                            <ScaleLoader
                                                color={"#fff"}
                                                loading={true}
                                                size={50}
                                                height={10}
                                                width={2}
                                            />
                                            :
                                            "Ok"
                                        }
                                    </button>
                                </div>
                            </div>
                        </PopoverBody >
                    </UncontrolledPopover >
                    <UncontrolledPopover placement="bottom" popperClassName="big-width" isOpen={addExpensePopover && selectedEnvelopeId == id} target={`add-expense-popover-${id}`} trigger="legacy" toggle={toggleAddExpensePopover}>
                        <PopoverBody className="w-100">
                            <Form onSubmit={addExpense} className="mt-4">
                                <FormGroup>
                                    <Input required placeholder="New Expense" onChange={e => setExpenseNameToAdd(e.target.value)} />
                                </FormGroup>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex p-4">
                                        <a className="btn-btn-sm btn-danger btn-round custom-btn" onClick={toggleAddExpensePopover}>Cancel</a>
                                        <button
                                            disabled={submitButtonLoading}
                                            className="btn-btn-sm btn-success btn-round custom-btn"
                                        >
                                            {submitButtonLoading ?
                                                <ScaleLoader
                                                    color={"#fff"}
                                                    loading={true}
                                                    size={50}
                                                    height={10}
                                                    width={2}
                                                />
                                                :
                                                "Ok"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </PopoverBody>
                    </UncontrolledPopover >
                </div>
            )

        })
    }

    React.useEffect(() => {

        setGoalExpenseName(expenseName)
        setUpdateCategoryGroups(categoryGroups)
        setUnspendAmount(budgetedAmount - spentAmount)

    }, [expenseName, categoryGroups, budgetedAmount, spentAmount])

    React.useEffect(() => {

        setSelectedEnvelopeId(selectedEnvelopeId)
        setExpenseId(expenseId)
        setExpenseName(expenseName)
        setSpentAmount(spentAmount)
        setBudgtedAmount(budgetedAmount)
        setUnspendAmount(unspendAmount)

    }, [budgetedAmount])

    React.useEffect(() => {
        setBudgetNotesLocal(budgetNotes)
    }, [budgetNotes])

    React.useEffect(() => {
        dispatch(GetMyBudget())
    }, [])

    return (
        <>
            <div
                className="row bg-light text-dark align-middle align-items-center my-4 sticky-head"
                style={{ marginLeft: 2, marginRight: 2, }}
            >
                <div className="col-lg-3 col-md-3 align-middle">
                    <div className="text-center">
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => {
                                    let date
                                    if (value) {
                                        date = value.split("-")
                                        date = new Date(date[0], date[1] - 1, 1)
                                    } else {
                                        date = null
                                    }
                                    return (
                                        <ReactDatetime
                                            onChange={async (value) => {
                                                let date = moment(value.toDate()).format("YYYY-MM-DD")
                                                onChange(date)
                                                setCurrentlyCheckedExpense(null)
                                                await dispatch(ChangeSelectedMonth(date)) //* required to fetch budget by month from other places
                                                await dispatch(GetMyBudget())
                                            }}
                                            value={date}
                                            inputProps={{
                                                className: "form-control text-center shadow-input",
                                                placeholder: "Select month",
                                                readOnly: true,
                                            }}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                            timeFormat={false}
                                            closeOnScroll={true}
                                        />
                                    )
                                }}
                                name="selectedMonth"
                            />
                        </FormGroup>
                        <span className="text-center" style={{ fontSize: '12px' }} id="enter-note-popover">
                            {budgetNotes ?
                                budgetNotes
                                :
                                "Enter a note.."
                            }
                        </span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 text-center">
                    <FundsToAllotBtn />
                </div >
            </div >
            <div className="content custom-content">

                <Row>
                    <Col md="8">
                        <div className="App">
                            <Button color="primary" id="add-envelope-popover" className="btn-sm">
                                <i className="fas fa-plus-circle" />
                                Envelope Group
                            </Button>
                            <Button color="primary" className="btn-sm">
                                <i className="fas fa-undo" />
                                Undo
                            </Button>
                            <Button color="primary" className="btn-sm">
                                <i className="fas fa-redo" />
                                Redo
                            </Button>
                            <Button
                                color="primary"
                                className="btn-sm"
                                onClick={() => setShowHidden(!showHidden)}
                            >
                                {showHidden ?
                                    <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                    />
                                    :
                                    <i
                                        class="fa fa-eye-slash"
                                        aria-hidden="true"
                                    />
                                }
                                Hidden
                            </Button>
                            {myBudget && myBudget.envelops.length != 0 ?
                                <header className="App-header">

                                    <div className="accordion budget-container" id="accordionPanelsStayOpenExample">
                                        <div className='budget-head top-head'>
                                            <div className='budget-col budget-col1'></div>
                                            <div className='budget-col budget-col2 budget-env-col'>Envelope</div>
                                            <div className='budget-col budget-col3'>Budgeted</div>
                                            <div className='budget-col budget-col4'>Spent</div>
                                            <div className='budget-col budget-col5'>Unspent</div>
                                        </div>
                                        {RenderEnvelops()}
                                    </div>
                                </header>
                                :
                                <div className='no-data-wrapper'>
                                    <span className='no-data'>Envelopes you add will show up here!</span>
                                </div>
                            }
                            <div ref={lastDivRef} />
                        </div>
                    </Col>
                    <Col md="4">
                        <Card >
                            <CardBody >
                                <p
                                    onClick={() => dispatch(ChangeAddGoalModal(true))}
                                    className="text-center bg-primary rounded  p-1 add-goal-btn"
                                >
                                    <i className="fa fa-plus" />
                                    {" Add Goal/Objective"}
                                </p>
                                <div className="divider" />
                                {showGoal &&
                                    <div className="">
                                        <div id="goal-section" className="">
                                            <h3 className="category-name">{goalExpenseName}</h3>
                                            <h5 className="border-bottom">Obligation</h5>
                                            {
                                                <h6>{requiredMessage}</h6>
                                            }
                                            <p className="text-primary"
                                                onClick={() => setShowGoalForm(!showGoalForm)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className="fas fa-plus-circle text-primary" />
                                                {watchId == 0 ?
                                                    " Create "
                                                    :
                                                    " Edit "
                                                }
                                                {expenseName}
                                                {" Target"}
                                            </p>
                                            {showGoalForm &&
                                                <>
                                                    <label htmlFor="">Amount</label>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                            pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                                                        }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <FormGroup>
                                                                <Input
                                                                    type="number"
                                                                    value={value}
                                                                    onChange={(e) => onChange(e.target.value)}
                                                                />
                                                                {errors.totalAmount &&
                                                                    <div className="text text-danger error-text">Enter Amount</div>
                                                                }
                                                            </FormGroup>
                                                        )}
                                                        name="totalAmount"
                                                    />
                                                    {watchId == 0 &&
                                                        <>
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: watchId == 0,
                                                                }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <ul className="nav nav-pills w-100" style={{ fontSize: '10px' }} id="pills-tab" role="tablist">
                                                                        <li
                                                                            onClick={() => {
                                                                                setDurationType("monthly")
                                                                                onChange("monthly")
                                                                            }}
                                                                            className="nav-item"
                                                                        >
                                                                            <a
                                                                                className={`nav-link ${durationType == "monthly" ? "active" : ""}`}
                                                                                data-toggle="pill"
                                                                                href="#pills-home"
                                                                                role="tab"
                                                                                aria-controls="pills-home"
                                                                                aria-selected="true"
                                                                            >
                                                                                {"Monthly"}
                                                                            </a>
                                                                        </li>
                                                                        <li
                                                                            onClick={() => {
                                                                                setDurationType("weekly")
                                                                                onChange("weekly")
                                                                            }}
                                                                            className="nav-item"
                                                                        >
                                                                            <a
                                                                                className={`nav-link ${durationType == "weekly" ? "active" : ""}`}
                                                                                data-toggle="pill"
                                                                                href="#pills-profile"
                                                                                role="tab"
                                                                                aria-controls="pills-profile"
                                                                                aria-selected="false"
                                                                            >
                                                                                {"Weekly"}
                                                                            </a>
                                                                        </li>
                                                                        <li
                                                                            onClick={() => {
                                                                                setDurationType("by_date")
                                                                                onChange("by_date")
                                                                            }}
                                                                            className="nav-item"
                                                                        >
                                                                            <a
                                                                                className={`nav-link ${durationType == "by_date" ? "active" : ""}`}
                                                                                data-toggle="pill"
                                                                                href="#pills-contact"
                                                                                role="tab"
                                                                                aria-controls="pills-contact"
                                                                                aria-selected="false"
                                                                            >
                                                                                {"By"} Date
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                                name="durationType"
                                                            />
                                                            <>
                                                                {errors.durationType &&
                                                                    <div className="text text-danger error-text">Select duration type</div>
                                                                }
                                                            </>
                                                        </>
                                                    }
                                                    {durationType == "monthly" &&
                                                        <div className="" >
                                                            <label htmlFor="">Every</label>
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: durationType == "monthly",
                                                                }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <Select
                                                                        className="react-select primary"
                                                                        classNamePrefix="react-select"
                                                                        name="singleSelect"
                                                                        value={value}
                                                                        onChange={(value) => onChange(value)}
                                                                        options={monthlyList}
                                                                        placeholder="Select day of month"
                                                                    />
                                                                )}
                                                                name="monthlyValue"
                                                            />
                                                            {errors.monthlyValue &&
                                                                <div className="text text-danger error-text">Select day of month</div>
                                                            }
                                                        </div>
                                                    }
                                                    {durationType == "weekly" &&
                                                        <div className="" >
                                                            <label htmlFor="">Every</label>
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: durationType == "weekly",
                                                                }}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <Select
                                                                        className="react-select primary"
                                                                        classNamePrefix="react-select"
                                                                        name="singleSelect"
                                                                        value={value}
                                                                        onChange={(value) => onChange(value)}
                                                                        options={weeklyList}
                                                                        placeholder="Select Week"
                                                                    />
                                                                )}
                                                                name="weeklyValue"
                                                            />
                                                            {errors.weeklyValue &&
                                                                <div className="text text-danger error-text">Select week</div>
                                                            }
                                                        </div>
                                                    }
                                                    {durationType == "by_date" &&
                                                        <div className="" >
                                                            <div style={{ height: 10 }} />
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: durationType == "by_date",
                                                                }}
                                                                render={({ field: { onChange, value } }) => {
                                                                    let date
                                                                    if (value) {
                                                                        date = value.split("/")
                                                                        date = new Date(date[2], date[1] - 1, date[0])
                                                                    } else {
                                                                        date = null
                                                                    }
                                                                    return (
                                                                        <ReactDatetime
                                                                            onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                                                            value={date}
                                                                            inputProps={{
                                                                                className: "form-control text-center",
                                                                                placeholder: "Select date",
                                                                                readOnly: true,
                                                                            }}
                                                                            dateFormat={GetDateFormat()}
                                                                            timeFormat={false}
                                                                        />
                                                                    )
                                                                }}
                                                                name="byDateValue"
                                                            />
                                                            {errors.byDateValue &&
                                                                <div className="text text-danger error-text">Select date</div>
                                                            }
                                                        </div>
                                                    }
                                                    <div className="text-right">
                                                        {watchId == 0 ?
                                                            <button
                                                                disabled={submitButtonLoading}
                                                                onClick={handleSubmit(async (data) => {

                                                                    if (durationType == 'by_date')
                                                                        if (moment(data.byDateValue, 'DD/MM/YYYY').isBefore(moment(data.selectedMonth, 'YYYY-MM-DD'))) {
                                                                            toast(`Selected date should be greater than ${GetDate(moment(data.selectedMonth, 'YYYY-MM-DD'))}`)
                                                                            return
                                                                        }

                                                                    await dispatch(PostGoal({ ...data, expenseId }))
                                                                    setExpenseGoalInfo(
                                                                        false,
                                                                        0,
                                                                        null,
                                                                        null,
                                                                        null,
                                                                        "monthly",
                                                                        "0",
                                                                        null,
                                                                        null,
                                                                        null,
                                                                    )
                                                                })}
                                                                class="btn btn-primary btn-sm"
                                                                style={{
                                                                    minWidth: 120,
                                                                    minHeight: 30,
                                                                }}
                                                            >
                                                                {submitButtonLoading ?
                                                                    <ScaleLoader
                                                                        color={"#fff"}
                                                                        loading={true}
                                                                        size={50}
                                                                        height={10}
                                                                        width={2}
                                                                    />
                                                                    :
                                                                    "Save Target"
                                                                }
                                                            </button>
                                                            :
                                                            <button
                                                                disabled={submitButtonLoading}
                                                                onClick={handleSubmit(async (data) => {
                                                                    console.info("data--->", data)

                                                                    if (moment(data.byDateValue, 'DD/MM/YYYY').isBefore(moment(data.selectedMonth, 'YYYY-MM-DD'))) {
                                                                        toast(`Selected date should be greater than ${GetDate(moment(data.selectedMonth, 'YYYY-MM-DD'))}`)
                                                                        return
                                                                    }

                                                                    dispatch(PutGoal({ ...data, expenseId }))
                                                                })}
                                                                class="btn btn-primary btn-sm"
                                                                style={{
                                                                    minWidth: 120,
                                                                    minHeight: 30,
                                                                }}
                                                            >
                                                                {submitButtonLoading ?
                                                                    <ScaleLoader
                                                                        color={"#fff"}
                                                                        loading={true}
                                                                        size={50}
                                                                        height={10}
                                                                        width={2}
                                                                    />
                                                                    :
                                                                    "Edit Goal"
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            {/* //todo: implement it later */}
                                            {/* <Table className="border-bottom">
                                            <tbody>
                                                <tr>
                                                    <td className="border-0">Cash Left Over from March</td>
                                                    <td className="border-0">{GetAmount(0.00)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Budgeted This Month</td>
                                                    <td className="border-0">{GetAmount(0.00)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Cash Spending</td>
                                                    <td className="border-0">{GetAmount(0.00)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Credit Spending</td>
                                                    <td className="border-0">{GetAmount(0.00)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0">Available</td>
                                                    <td className="border-0"><Badge color="danger">{GetAmount(0.00)}</Badge></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <div className="border-bottom">
                                            <Line
                                                data={chartExample1.data}
                                                options={chartExample1.options}
                                            />
                                        </div> */}
                                        </div >
                                    </div >
                                }
                                <Table className="mt-3"  >
                                    <p className="text-center bg-primary rounded p-1 budget-overview-title"> <i className="fa fa-binoculars"></i> Budget Overview</p>
                                    <tbody >
                                        <tr style={{ lineHeight: '0' }}><td className=" border-0 text-center text-muted line-height-0">Total Funds Allotted</td></tr>
                                        <tr><td className="border-0  text-center font-weight-bolder">{GetAmount(budgetOverview?.totalFundsAlloted)}</td></tr>

                                        <tr style={{ lineHeight: '0' }} ><td className="border-0 text-center text-muted line-height-0">Total Activity</td></tr>
                                        <tr className="mb-5"><td className="border-0  text-center font-weight-bolder">{GetAmount(budgetOverview?.totalActivity)}</td></tr>

                                        <tr style={{ lineHeight: '0' }}><td className="border-0 text-center text-muted line-height-0">Total Funds Available</td></tr>
                                        <tr><td className="border-0  text-center font-weight-bolder">{GetAmount(budgetOverview?.totalFundsAvailable)}</td></tr>

                                        <tr style={{ lineHeight: '0' }}><td className="border-0 text-center text-muted line-height-0">Total Cash Inflow</td></tr>
                                        <tr><td className="border-0  text-center font-weight-bolder">{GetAmount(budgetOverview?.totalCashInflow)}</td></tr>

                                    </tbody>
                                </Table>

                                {/* <Card className="bg-light rounded  p-0"> //todo: uncomment when getting from API
                                <CardBody className="p-1">
                                    <div className="d-flex justify-content-between">
                                        <p>Under Funded Envelopes</p>
                                        <p className="font-weight-bold">GetAmount(0.00)</p>
                                    </div>
                                </CardBody>
                            </Card> */}
                                <Card className="bg-light rounded  p-0">
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Funds Allotted Last Month</p>
                                            <p className="font-weight-bold">{GetAmount(budgetOverview?.fundsAllotedLastMonth)}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="bg-light rounded  p-0">
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Spent Last Month</p>
                                            <p className="font-weight-bold">{GetAmount(budgetOverview?.totalActivityLastMonth)}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="bg-light rounded  p-0">
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Average Funds Allotted</p>
                                            <p className="font-weight-bold">{GetAmount(budgetOverview?.averageFundsAllotted)}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="bg-light rounded  p-0">
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Average Spent</p>
                                            <p className="font-weight-bold">{GetAmount(budgetOverview?.averageSpent)}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <hr />
                                <Card className="bg-secondary rounded text-white p-0"> //todo: uncomment when undo/redo done
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Reset Available Amounts</p>
                                            <p className="font-weight-bold">{GetAmount(0.00)}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="bg-secondary rounded text-white p-0">
                                    <CardBody className="p-1" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Reset Budgted Amounts</p>
                                            <p className="font-weight-bold">{GetAmount(0.00)}</p>
                                        </div>
                                    </CardBody>
                                </Card> */}
                            </CardBody >
                        </Card >
                    </Col >
                </Row >
                < UncontrolledPopover placement="bottom" popperClassName="big-width" isOpen={addEnvelopePopover} target="add-envelope-popover" trigger="legacy" toggle={toggleAddEnvelopePopover} >
                    <PopoverBody className="w-100" >
                        <Form onSubmit={addEnvelopeGroup} className="mt-4">
                            <FormGroup>
                                <Input placeholder="New Envelope Group" required onChange={e => setEnvelope(e.target.value)} />
                            </FormGroup>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex p-4">
                                    <a className="btn-btn-sm btn-danger btn-round custom-btn" onClick={toggleAddEnvelopePopover}>Cancel</a>
                                    <button
                                        disabled={submitButtonLoading}
                                        className="btn-btn-sm btn-success btn-round custom-btn"
                                    >
                                        {submitButtonLoading ?
                                            <ScaleLoader
                                                color={"#fff"}
                                                loading={true}
                                                size={50}
                                                height={10}
                                                width={2}
                                            />
                                            :
                                            "Ok"
                                        }
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </PopoverBody>
                </UncontrolledPopover >
                <UncontrolledPopover placement="bottom" popperClassName="big-width" isOpen={enterNotePopover} target="enter-note-popover" trigger="legacy" toggle={toggleEnterNotePopover}>
                    <PopoverBody className="w-100">
                        <Form className="mt-4">
                            <FormGroup>
                                <Input
                                    onChange={(e) => setBudgetNotesLocal(e.target.value)}
                                    value={budgetNotesLocal}
                                    placeholder="Enter a note..."
                                    required
                                />
                            </FormGroup>
                            <div className="d-flex justify-content-between">

                                <div className="d-flex p-4">
                                    <a className="btn-btn-sm btn-danger btn-round custom-btn" onClick={toggleEnterNotePopover}>Cancel</a>
                                    <button
                                        disabled={submitButtonLoading}
                                        className="btn-btn-sm btn-success btn-round custom-btn"
                                        onClick={async () => {
                                            await dispatch(PostNotes(budgetNotesLocal))
                                            setEnterNotePopover(false)
                                        }}
                                    >
                                        {submitButtonLoading ?
                                            <ScaleLoader
                                                color={"#fff"}
                                                loading={true}
                                                size={50}
                                                height={10}
                                                width={2}
                                            />
                                            :
                                            "Ok"
                                        }
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </PopoverBody>
                </UncontrolledPopover >
                <DeleteEnvelopeModal />
                <DeleteExpenseModal />
                <AddGoalModal />
                <ExpenseTransactionsModal />
            </div >
        </>
    )
}

export default MyBudget