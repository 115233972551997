
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from 'react-router-dom';
import Select from "react-select";
import {
    Button,
    Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Row
} from "reactstrap";


class AddBank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleSelect: null,
            alert: null,
        };
    }

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Account Added!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    btnSize=""
                >
                    <Link to="/admin/add-account" className="border text-dark bg-light border-primary mt-3 rounded p-1"><i className="fas fa-plus-circle text-primary"></i> Add Another Account</Link>
                </ReactBSAlert>
            ),
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };
    render() {
        return (
            <>
                <div className="content">
                    {this.state.alert}

                    <Row>
                        <Col md="6" className="ml-auto mr-auto">
                            <Card className="h-100">
                                <CardHeader className="text-center">
                                    <CardTitle tag="h6">Linked Account</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form >
                                        <label>Select Your Bank</label>

                                        <div class="input-group mb-3 border rounded border-seondary">
                                            <span class="input-group-text bg-transparent border-0" id="basic-addon1"><i className="fas fa-search"></i></span>
                                            <input type="text" class="form-control border-0" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={this.state.singleSelect}
                                                onChange={(value) =>
                                                    this.setState({ singleSelect: value })
                                                }
                                                options={[
                                                    {
                                                        value: "",
                                                        label: "Bank Name",
                                                        isDisabled: true,
                                                    },
                                                    { value: "2", label: "American Express" },
                                                    { value: "3", label: "Chase Bank" },
                                                    { value: "3", label: "Bank of America" },
                                                    { value: "3", label: "PNC" },
                                                ]}
                                                placeholder="Select Your Bank"
                                            />
                                        </FormGroup>
                                    </Form>

                                    <div className="text-center">
                                        <Link to="/admin/add-bank-info">      <Button color="primary" >Next</Button></Link>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>


                    </Row>
                </div>
            </>
        );
    }
}

export default AddBank;
