
import AllAccounts from '../Screens/Accounts/AllAccounts';
import AddAccount from '../Screens/AddAccount/AddAccount';
import AddBank from '../Screens/AddAccount/AddBank';
import AddBankInfo from '../Screens/AddAccount/AddBankInfo';
import AddLinkedAccount from '../Screens/AddAccount/AddLinkedAccount';
import AddUnLinkedAccount from '../Screens/AddAccount/AddUnLinkedAccount';
import AllBudgets from '../Screens/AllBudgets/AllBudgets';
import Login from "../Screens/Auth/Login";
import SignUp from "../Screens/Auth/SignUp";
import BudgetSettings from '../Screens/BudgetSettings/BudgetSettings';
import CustomCalendar from "../Screens/Calendar/CustomCalender";
import Dashboard from "../Screens/Dashboard/Dashboard";
import DisplaySettings from '../Screens/DisplaySettings/DisplaySettings';
import GeneralSettings from '../Screens/GeneralSettings/GeneralSettings';
import ManagePayees from '../Screens/ManagePayees/ManagePayees';
import Mortgage from "../Screens/Mortgage/Mortgage";
import MyBudget from '../Screens/MyBudget/MyBudget';
import MyGoals from "../Screens/MyGoals/MyGoals";
import NewBudget from '../Screens/NewBudget/NewBudget';
import UserProfile from "../Screens/UserProfile/UserProfile";
import Reports from '../Screens/Reports/Reports';
import OTP from '../Screens/Auth/OTP';

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-chart-pie",
    component: Reports,
    layout: "/admin",
  },

  {
    path: "/calendar",
    name: "Calendar",
    icon: "fas fa-calendar-alt",
    component: CustomCalendar,
    layout: "/admin",
    invisible: false,
  },

  {
    path: "/unlinked-account/:accountId",
    name: "Add Unlinked Account",
    icon: "nc-icon nc-bank",
    component: AddUnLinkedAccount,
    layout: "/admin",
    invisible: true,
  },
  {
    path: `/accounts/:accountId`,
    name: `account`,
    component: AllAccounts,
    layout: `/admin`,
    invisible: true,
  },
  {
    path: "/add-linked-account",
    name: "Add Linked Account",
    icon: "nc-icon nc-bank",
    component: AddLinkedAccount,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/add-bank",
    name: "Add Bank",
    icon: "nc-icon nc-bank",
    component: AddBank,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/add-bank-info",
    name: "Add Bank",
    icon: "nc-icon nc-bank",
    component: AddBankInfo,
    layout: "/admin",
    invisible: true,
  },

  {
    path: "/manage-payees",
    name: "Manage Payees",
    icon: "nc-icon nc-bank",
    component: ManagePayees,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Budget",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/my-goals",
        name: "My Goals",
        mini: "MG",
        component: MyGoals,
        layout: "/admin",
      },

      {
        path: "/budget",
        name: "My Budget",
        mini: "B",
        component: MyBudget,
        layout: "/admin",
      },

      {
        path: "/my-budget/:budgetId",
        name: "New Budget",
        mini: "NB",
        component: NewBudget,
        layout: "/admin",
        invisible: true,
      },
      {
        path: "/all-budgets",
        name: "All Budgets",
        mini: "AB",
        component: AllBudgets,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/user-profile",
    name: "UserProfile",
    mini: "UP",
    component: UserProfile,
    layout: "/admin",
    invisible: true,
  },

  {
    collapse: true,
    name: "Accounts",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [],
  },

  {
    collapse: true,
    name: "Accounts Settings",
    icon: "fas fa-user-circle",
    state: "tablesCollapse",
    views: [
      {
        path: "/accounts/0",
        name: "All Transactions",
        mini: "AL",
        component: AllAccounts,
        layout: "/admin",
      },
      {
        path: "/add-account",
        name: "Add Account",
        mini: "AA",
        component: AddAccount,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/budget-settings",
    name: "Budget Settings",
    mini: "RF",
    component: BudgetSettings,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/general-settings",
    name: "General Settings",
    mini: "EF",
    component: GeneralSettings,
    layout: "/admin",
    invisible: true,

  },
  {
    path: "/display-settings",
    name: "Display Settings",
    mini: "EF",
    component: DisplaySettings,
    layout: "/admin",
    invisible: true,

  },

  {
    path: "/otp",
    name: "OTP",
    mini: "VF",
    component: OTP,
    layout: "/auth",
    invisible: true,

  },

  {
    path: "/login",
    name: "Log Out",
    mini: "VF",
    component: Login,
    layout: "/auth",
    invisible: true,

  },

  {
    path: "/sign-up",
    name: "Log Out",
    mini: "VF",
    component: SignUp,
    layout: "/auth",
    invisible: true,

  },


  {
    path: "/Mortgage",
    name: "Mortgage",
    icon: "fas fa-chart-bar",
    component: Mortgage,
    layout: "/admin",
    // invisible: true,
  },

  // {
  //   collapse: true,
  //   name: "Credit Repair",
  //   icon: "far fa-credit-card",
  //   state: "creditRepairCollapse",
  //   views: [
  //     {
  //       path: "/credit-profile-dashboard",
  //       name: "Credit Profile Dashboard",
  //       mini: "CPD",
  //       component: CreditProfileDashboard,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/my-disputes",
  //       name: "My Disputes",
  //       mini: "MD",
  //       component: MyDisputes,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/letters-library",
  //       name: "Letters Library",
  //       mini: "LL",
  //       component: LettersLibrary,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/stocks",
  //   name: "Stocks",
  //   icon: "fas fa-chart-line",
  //   component: Stocks1,
  //   layout: "/admin",
  // },
  // {
  //   path: "/stocks1",
  //   name: "Stocks1",
  //   icon: "fas fa-chart-line",
  //   component: Stocks1,
  //   layout: "/admin",
  // },
  // {
  //   path: "/stock-details",
  //   name: "Stock Details",
  //   icon: "fas fa-chart-line",
  //   component: StockDetails,
  //   layout: "/admin",
  //   invisible: true,
  // },

  // {
  //   path: "/budget",
  //   name: "Add Account",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Budget,
  //   layout: "/admin",
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "nc-icon nc-single-copy-04",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       mini: "RT",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //   ],
  // },

  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "nc-icon nc-box",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];

export default routes;
