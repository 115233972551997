
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import {
    Button, Col, FormGroup,
    Input, Modal, Row
} from "reactstrap";
import Form from "reactstrap/lib/Form";
import { ChangeAddTransactionModal, EditTransaction } from "../../Accounts/Store/accountsSlice";
import { GetMortgageMonths } from "../../Shared/Store/sharedSlice";
import moment from "moment";
import { AddTransaction } from "../../Accounts/Store/accountsSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { GetDateFormat, GetAmount } from "../../../Config/Helper";
import { GetDate } from "../../../Config/Helper";

function AddTransactionModal(props) {

    const defaultValues = {
        id: 0,
        account: null,
        transactionDate: moment(new Date()).format("DD/MM/YYYY"),
        payee: null,
        envelopeMortgageGoal: null,
        memo: null,
        outflow: "0",
        inflow: "0",
        extraAmount: "0",
        selectedMonth: null,
    }

    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors }, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    let watchId = watch('id')
    let watchEnvelopeMortgageGoal = watch('envelopeMortgageGoal')
    let watchPayee = watch('payee')
    let watchInflow = watch('inflow')
    let watchOutflow = watch('outflow')

    const {
        selectEnvelopeMortgageGoalList,
        accountsList,
        mortgageMonths,
        selectedMonthId,
        savedPayeeList,
    } = useSelector(state => state.shared)
    const {
        formData,
        addTransactionModal,
    } = useSelector(state => state.accounts)

    const [accountPayeeList, setAccountPayeeList] = useState([])
    const [payeeList, setPayeeList] = useState([])

    useEffect(() => {
        if (selectedMonthId) {
            let mortgageMonth = mortgageMonths.find(obj => obj.value == selectedMonthId)
            const { amount, loanAmount } = mortgageMonth || {}

            reset({
                ...getValues(),
                selectedMonth: mortgageMonth,
                outflow: amount,
                remainingLoan: loanAmount,
            })
        }
    }, [selectedMonthId])

    useEffect(() => {
        let payeeListTemp = []
        payeeListTemp.push({
            id: -2,
            label: "Other Accounts",
            options: accountPayeeList,
        })
        payeeListTemp.push({
            id: -3,
            label: "Saved Payees",
            options: savedPayeeList,
        })
        setPayeeList(payeeListTemp)
    }, [savedPayeeList, accountPayeeList])

    useEffect(() => {
        reset(formData)
    }, [formData])

    return (
        <Modal
            isOpen={addTransactionModal}
            toggle={() => dispatch(ChangeAddTransactionModal(!addTransactionModal))}
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeAddTransactionModal(!addTransactionModal))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="title title-up" style={{ marginTop: "1rem" }}>
                    {watchId > 0 ? "Edit" : "Add New"} Transaction
                </h5>
            </div>
            <div className="modal-body">
                <Form>
                    <Row>
                        <Col md="6">
                            <label htmlFor="">Account</label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            onChange={(value) => {
                                                onChange(value)
                                                let filteredaccountsList = accountsList.filter(obj => obj.value != value.value)
                                                setAccountPayeeList(filteredaccountsList)
                                                reset({
                                                    ...getValues(),
                                                    payee: null,
                                                })
                                            }}
                                            value={value}
                                            options={accountsList}
                                            styles={{
                                            }}
                                            menuColor='red'
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#34B5B8' + '55',
                                                    primary: '#34B5B8',
                                                    primary50: '#34B5B8' + '33',
                                                },
                                            })}
                                        />
                                    )}
                                    name="account"
                                />
                                {errors.account &&
                                    <div className="text text-danger error-text">Select account</div>
                                }
                            </FormGroup>

                        </Col>
                        <Col md="6">
                            <label htmlFor="">Transaction Date</label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => {
                                        let date
                                        if (value) {
                                            date = value.split("/")
                                            date = new Date(date[2], date[1] - 1, date[0])
                                        } else {
                                            date = null
                                        }
                                        return (
                                            <ReactDatetime
                                                onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                                value={date}
                                                inputProps={{
                                                    className: "form-control text-center",
                                                    placeholder: "Select date",
                                                    readOnly: true,
                                                }}
                                                dateFormat={GetDateFormat()}
                                                timeFormat={false}
                                            />
                                        )
                                    }}
                                    name="transactionDate"
                                />
                                {errors.transactionDate &&
                                    <div className="text text-danger error-text">Enter transaction date</div>
                                }
                            </FormGroup>

                        </Col>
                        <Col md="6">
                            <label htmlFor="">Payee</label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: !watchEnvelopeMortgageGoal,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            onChange={(value) => {
                                                onChange(value)
                                                if (value.parentId == -2)
                                                    reset({
                                                        ...getValues(),
                                                        envelopeMortgageGoal: null,
                                                    })
                                            }}
                                            value={value}
                                            noOptionsMessage={() => 'Select an Account first!'}
                                            options={payeeList}
                                            placeholder="Select.."
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#34B5B8' + '55',
                                                    primary: '#34B5B8',
                                                    primary50: '#34B5B8' + '33',
                                                },
                                            })}
                                        />
                                    )}
                                    name="payee"
                                />
                                {errors.payee &&
                                    <div className="text text-danger error-text">Select payee</div>
                                }
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <label htmlFor="">Envelope/Mortgate/Goal</label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: watchPayee?.parentId == -3,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            onChange={(value) => {
                                                onChange(value)

                                                if (value.parentId == -1)
                                                    dispatch(GetMortgageMonths(value.value))

                                                if (watchPayee?.parentId == -2)
                                                    reset({
                                                        ...getValues(),
                                                        payee: null,
                                                    })
                                            }}
                                            value={value}
                                            options={selectEnvelopeMortgageGoalList}
                                            menuColor='red'
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#34B5B8' + '55',
                                                    primary: '#34B5B8',
                                                    primary50: '#34B5B8' + '33',
                                                },
                                            })}
                                        />
                                    )}
                                    name="envelopeMortgageGoal"
                                />
                                {errors.envelopeMortgageGoal &&
                                    <div className="text text-danger error-text">Select envelope/mortgate/goal</div>
                                }
                            </FormGroup>
                        </Col>
                        {watchEnvelopeMortgageGoal?.parentId == -1 &&
                            <Col md="6">
                                <label htmlFor="">Selected Month</label>
                                <FormGroup>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: watchEnvelopeMortgageGoal?.parentId == -1,
                                        }}
                                        render={({ field: { onChange, value } }) => {
                                            let date = value?.label
                                            if (date) {
                                                date = date.split("-")
                                                date = new Date(date[0], date[1] - 1, date[2])
                                            } else
                                                date = null
                                            return (
                                                <Input
                                                    readOnly={true}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={date ? GetDate(date) : null}
                                                    type="text"
                                                />
                                            )
                                        }}
                                        name="selectedMonth"
                                    />
                                    {errors.selectedMonth &&
                                        <div className="text text-danger error-text">Select Mortgage Month</div>
                                    }
                                </FormGroup>
                            </Col>
                        }
                        <Col md="6">
                            <label htmlFor="">Memo</label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        // required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            onChange={(e) => onChange(e.target.value)}
                                            value={value}
                                            type="text"
                                        />
                                    )}
                                    name="memo"
                                />
                                {errors.memo &&
                                    <div className="text text-danger error-text">Enter memo</div>
                                }
                            </FormGroup>

                        </Col>
                        {watchEnvelopeMortgageGoal?.parentId == -1 &&
                            <Col md="6">
                                <label htmlFor="">
                                    Remaining Loan
                                </label>
                                <FormGroup>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: false,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                readOnly={true}
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    reset({
                                                        ...getValues(),
                                                        inflow: "0",
                                                    })
                                                }}
                                                value={GetAmount(value)}
                                            />
                                        )}
                                        name="remainingLoan"
                                    />
                                    {errors.remainingLoan &&
                                        <div className="text text-danger error-text">Enter extra amount</div>
                                    }
                                </FormGroup>
                            </Col>
                        }
                        <Col md="6">
                            <label htmlFor="">
                                {watchEnvelopeMortgageGoal?.parentId == -1 ? "Amount" : "Outflow"}
                            </label>
                            <FormGroup>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: (watchInflow == "0"),
                                        pattern: (watchInflow == "0") ? /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/ : null,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            readOnly={watchEnvelopeMortgageGoal?.parentId == -1}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                reset({
                                                    ...getValues(),
                                                    inflow: "0",
                                                })
                                            }}
                                            value={watchEnvelopeMortgageGoal?.parentId == -1 ? GetAmount(value) : value}
                                            type={watchEnvelopeMortgageGoal?.parentId == -1 ? "text" : "number"}
                                        />
                                    )}
                                    name="outflow"
                                />
                                {errors.outflow &&
                                    <div className="text text-danger error-text">Enter outflow amount</div>
                                }
                            </FormGroup>
                        </Col>
                        {watchEnvelopeMortgageGoal?.parentId == -1 &&
                            <Col md="6">
                                <label htmlFor="">
                                    Extra Amount
                                </label>
                                <FormGroup>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: false,
                                            pattern: (watchInflow == "0") ? /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/ : null,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    reset({
                                                        ...getValues(),
                                                        inflow: "0",
                                                    })
                                                }}
                                                value={value}
                                                type={"number"}
                                            />
                                        )}
                                        name="extraAmount"
                                    />
                                    {errors.extraAmount &&
                                        <div className="text text-danger error-text">Enter extra amount</div>
                                    }
                                </FormGroup>
                            </Col>
                        }
                        {!(watchEnvelopeMortgageGoal?.parentId == -1) &&
                            <Col md="6">
                                <label htmlFor="">Inflow</label>
                                <FormGroup>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: (watchOutflow == "0"),
                                            pattern: (watchOutflow == "0") ? /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/ : null,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    reset({
                                                        ...getValues(),
                                                        outflow: "0",
                                                    })
                                                }}
                                                value={value}
                                                type="number"
                                            />
                                        )}
                                        name="inflow"
                                    />
                                    {errors.inflow &&
                                        <div className="text text-danger error-text">Enter inflow amount</div>
                                    }
                                </FormGroup>
                            </Col>
                        }
                    </Row>

                </Form>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link btn-link-cancel"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeAddTransactionModal(!addTransactionModal))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    {watchId > 0 ?
                        <Button
                            onClick={handleSubmit(async (data) => {
                                if (data?.envelopeMortgageGoal?.parentId == -1) {
                                    toast("You cannot edit mortgage transactions!")
                                } else {
                                    await dispatch(EditTransaction(data))
                                }
                            })}
                            className="btn-link "
                            color="primary"
                            type="button"
                        >
                            Edit
                        </Button>
                        :
                        <Button
                            onClick={handleSubmit(async (data) => {
                                await dispatch(AddTransaction(data))
                                reset(defaultValues)
                            })}
                            className="btn-link "
                            color="primary"
                            type="button"
                        >
                            Add
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default AddTransactionModal
