
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Shared/Components/Loader";
import AdminLayout from './Admin';
import AuthLayout from './Auth';

function MainRoutes(props) {

    const location = useLocation();

    const { loading } = useSelector(state => state.shared)
    const { userData } = useSelector(state => state.userData)

    React.useEffect(() => {
    }, [])

    return (
        <>
            <Switch>
                {userData ?
                    <>
                        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                        {(location.pathname == "/" || location.pathname.includes('auth')) &&
                            <Redirect from="*" to="/admin/dashboard" />
                        }
                    </>
                    :
                    <>
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        {(location.pathname == "/" || location.pathname.includes('admin')) &&
                            <Redirect from="*" to="/auth/login" />
                        }
                    </>
                }
            </Switch>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme='light' //todo: might need to change it with theme later
                progressStyle={{
                    background: '#34B5B8'
                }}
                style={{
                }}
            />
            {loading &&
                <Loader />
            }
        </>
    )
}

export default MainRoutes;
