import { createSlice, createAsyncThunk, createEntityAdapter, current } from '@reduxjs/toolkit';
import { CompareYears } from '../../../Config/Helper';
import { toast } from 'react-toastify';
import { apiInstance } from '../../../Config/AxiosInstances';

export const GetAllMortgages = createAsyncThunk('mortgage/GetAllMortgages', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.get(`/api/get-mortgage/${selectedBudgetId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PostMortgage = createAsyncThunk('mortgage/PostMortgage', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { name, amount, startDate, endDate, interestRate } = params
        let startDateSplited = startDate.split('/')
        let endDateSplited = endDate.split('/')

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.post('/api/mortgages', {
            'budget_id': selectedBudgetId,
            'mortgage_name': name,
            'amount': amount,
            'rate': interestRate,
            'start_date': `${startDateSplited[2]}-${startDateSplited[1]}-${startDateSplited[0]}`,
            'end_date': `${endDateSplited[2]}-${endDateSplited[1]}-${endDateSplited[0]}`,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const mortgageSliceSlice = createSlice({
    name: 'mortgage',
    initialState: {
        addMortgageModal: false,
        mortgageDetailModal: false,
        currentMortgageDetail: null,

        mortgages: [],

        years: [],
        selectedYear: null,
    },
    reducers: {
        ChangeCurrentMortgageDetail: (state, action) => {
            state.currentMortgageDetail = action.payload
        },
        ChangeMortgageDetailModal: (state, action) => {
            state.mortgageDetailModal = action.payload
        },
        ChangeAddMortgageModal: (state, action) => {
            state.addMortgageModal = action.payload
        },
        ChangeSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
    },
    extraReducers: {
        ['shared/GetMortgageMonths/fulfilled']: (state, action) => {
            const { data, status, mortgageId } = action.payload || {}
            if (status >= 200 && status < 300) {
                let mortgages = [...state.mortgages]
                let index = mortgages.findIndex(obj => obj.id === mortgageId)

                mortgages[index].mortgage_transaction = data?.data || []

                state.mortgages = mortgages
            }
        },
        ['accounts/AddTransaction/fulfilled']: (state, action) => {
            const { data, status, selectedMonth } = action.payload || {}
            if (status >= 200 && status < 300) {
                if (data?.transaction?.mortgage_id) {
                    const {
                        account_id,
                        envelop_id,
                        envelop_sub_cat_id,
                        goal_id,
                        id,
                        in_flow_amount,
                        is_envelop_or_mortgage,
                        memo,
                        mortgage_id,
                        mortgage_name,
                        out_flow_amount,
                        transaction_date,
                        user_id,
                    } = data?.transaction

                    let mortgages = []
                    current(state.mortgages).map((item) => {
                        if (item.id == mortgage_id) {
                            let mortgageTransaction = []
                            item.mortgage_transaction.map((subItem) => {
                                if (subItem.id == selectedMonth.value) {
                                    mortgageTransaction.push({
                                        ...subItem,
                                        mortgage_status_id: 3,
                                        mortgage_status: {
                                            id: 3,
                                            status_name: "paid",
                                            status: 1,
                                            created_at: null,
                                            updated_at: null,
                                        }
                                    })
                                } else {
                                    mortgageTransaction.push(subItem)
                                }
                            })
                            mortgages.push({
                                ...item,
                                mortgage_transaction: mortgageTransaction,
                            })
                        } else {
                            mortgages.push(item)
                        }
                    })
                    state.mortgages = mortgages
                }
            }
        },
        ['mortgage/GetAllMortgages/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let mortgages = data.data.filter(obj => obj.mortgage_transaction.length != 0)
                let years = []
                if (current(state.years).length != 0)
                    years.concat(current(state.years))

                mortgages.map((item) => {
                    item.mortgage_transaction.map((subItem) => {
                        let year = subItem.months.split('-')
                        if (years.findIndex(obj => obj.value == year[0]) == -1)
                            years.push({ label: year[0], value: year[0] })
                    })
                })
                years.sort(CompareYears)

                state.years = years
                state.selectedYear = years[0]
                state.mortgages = mortgages
            }
        },
        ['mortgage/PostMortgage/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { mortgage, mortgageTransaction } = data?.data
                const { amount, budget_id, end_date, id, mortgage_name, start_date, user_id, } = mortgage

                let mortgageTransactionsTemp = []
                let years = []
                current(state.years).map((item) => {
                    years.push(item)
                })


                mortgageTransaction.map((item) => {
                    mortgageTransactionsTemp.push({
                        ...item,
                        mortgage_status: {
                            id: item.mortgage_status_id,
                            //! not getting whole object from DB
                        }
                    })

                    let year = item.months.split('-')
                    if (years.findIndex(obj => obj.value == year[0]) == -1)
                        years.push({ label: year[0], value: year[0] })
                })
                years.sort(CompareYears)

                state.years = years
                state.selectedYear = years[0]
                state.mortgages.push({
                    id,
                    mortgage_name,
                    amount,
                    mortgage_transaction: mortgageTransactionsTemp
                })
                state.addMortgageModal = false
                toast(data?.message)
            }
        },
    },
});

export const {
    ChangeAddMortgageModal,
    ChangeSelectedYear,
    ChangeMortgageDetailModal,
    ChangeCurrentMortgageDetail,
} = mortgageSliceSlice.actions;

export default mortgageSliceSlice.reducer;