import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        selectedBudgetName: null,
        selectedBudgetId: 0, //todo: if zero force user to add budget first
        selectedBudgetCurrency: null,
        selectedBudgetCurrencyPlacement: null,
        selectedBudgetNumberFormat: null,
        selectedBudgetDateFormat: null,

        sidebarColor: 'white',
        userData: null,
    },
    reducers: {
        ChangeSidebarColor: (state, action) => {
            state.sidebarColor = action.payload
        },
        ChangeSelectedBudgetId: (state, action) => {
            const {
                budget_name,
                currency_id,
                currency_symbol_placement,
                date_format_id,
                id,
                note,
                number_format_id,
                updated_at,
                user_id,
            } = action.payload

            state.selectedBudgetName = budget_name
            state.selectedBudgetId = id
            state.selectedBudgetCurrency = currency_id
            state.selectedBudgetCurrencyPlacement = currency_symbol_placement
            state.selectedBudgetNumberFormat = number_format_id
            state.selectedBudgetDateFormat = date_format_id
        },
        ChangeUserData: (state, action) => {
            state.userData = action.payload
        },
    },
    extraReducers: {
        ['userProfile/EditImage/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { image } = data?.data || {}
                let userData = {
                    ...state.userData,
                    user: {
                        ...state.userData.user,
                        image,
                    }
                }
                state.userData = userData
                toast(data?.message)
            }
        },
        ['displaySettings/ChangeMode/pending']: (state, action) => {
            const { mode } = action.meta.arg || {}
            if (mode == '0')
                state.sidebarColor = 'white'
            else if (mode == '1')
                state.sidebarColor = 'brown'
            else if (mode == '2')
                state.sidebarColor = 'black'
        },
        ['displaySettings/ChangeMode/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                toast(data?.message, {
                    toastId: "theme-changed"
                })
            }
        },
        ['userProfile/EditUser/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { first_name, last_name } = data?.data || {}
                let userData = {
                    ...state.userData,
                    user: {
                        ...state.userData.user,
                        first_name,
                        last_name,
                    }
                }
                toast(data?.message)
                state.userData = userData
            }
        },
        ['auth/SignIn/fulfilled']: (state, action) => {
            const { data, status, budget } = action.payload || {}
            const { data: budgetData, status: budgetStatus } = budget || {}

            //* userData
            if (status >= 200 && status < 300 && data?.data?.user?.is_email_verified) {
                const { data: { user: { mode }, token } } = data || {}

                if (mode == '0')
                    state.sidebarColor = 'white'
                else if (mode == '1')
                    state.sidebarColor = 'brown'
                else if (mode == '2')
                    state.sidebarColor = 'black'

                let userData = {
                    user: data?.data?.user,
                    token: data?.data?.token,
                }
                state.userData = userData
            }
            else if (status >= 400 && status < 500) {
                toast("Invalid email or password!")
            }

            //* budgetData
            if (budgetStatus >= 200 && budgetStatus < 300 && data?.data?.user?.is_email_verified) {
                const {
                    budget_name,
                    currency_id,
                    currency_symbol_placement,
                    date_format_id,
                    id,
                    note,
                    number_format_id,
                    updated_at,
                    user_id,
                } = budgetData?.budget || {}

                state.selectedBudgetName = budget_name
                state.selectedBudgetId = id
                state.selectedBudgetCurrency = currency_id
                state.selectedBudgetCurrencyPlacement = currency_symbol_placement
                state.selectedBudgetNumberFormat = number_format_id
                state.selectedBudgetDateFormat = date_format_id
            }
        },
        ['auth/VerifyCode/fulfilled']: (state, action) => {
            const { data, status, budget } = action.payload || {}
            const { data: budgetData, status: budgetStatus } = budget || {}

            //* userData
            if (status >= 200 && status < 300 && data?.data?.token) {
                const { data: { user: { mode }, token } } = data || {}

                if (mode == '0')
                    state.sidebarColor = 'white'
                else if (mode == '1')
                    state.sidebarColor = 'brown'
                else if (mode == '2')
                    state.sidebarColor = 'black'

                let userData = {
                    user: data?.data?.user,
                    token: data?.data?.token,
                }
                state.userData = userData

                toast(data?.message)
            }
            else if (status >= 400 && status < 500) {
                toast(data?.message)
            }

            //* budgetData
            if (budgetStatus >= 200 && budgetStatus < 300 && data?.data?.user?.is_email_verified) {
                const {
                    budget_name,
                    currency_id,
                    currency_symbol_placement,
                    date_format_id,
                    id,
                    note,
                    number_format_id,
                    updated_at,
                    user_id,
                } = budgetData?.budget || {}

                state.selectedBudgetName = budget_name
                state.selectedBudgetId = id
                state.selectedBudgetCurrency = currency_id
                state.selectedBudgetCurrencyPlacement = currency_symbol_placement
                state.selectedBudgetNumberFormat = number_format_id
                state.selectedBudgetDateFormat = date_format_id
            }
        },
        ['auth/Register/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300 && data?.data?.user?.is_email_verified) {
                state.sidebarColor = 'white'
                let userData = {
                    user: data?.data?.user,
                    token: data?.data?.token,
                }
                state.userData = userData
            }
            else if (status >= 400 && status < 500) {
                toast("This email is already taken, please enter another email")
            }
        },
        ['newBudget/PutBudget/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    budget_name,
                    currency_id,
                    currency_symbol_placement,
                    date_format_id,
                    id,
                    note,
                    number_format_id,
                    updated_at,
                    user_id,
                } = data?.budget

                state.selectedBudgetName = budget_name
                state.selectedBudgetId = id
                state.selectedBudgetCurrency = currency_id
                state.selectedBudgetCurrencyPlacement = currency_symbol_placement
                state.selectedBudgetNumberFormat = number_format_id
                state.selectedBudgetDateFormat = date_format_id
            }
        },
        ['newBudget/PostBudget/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    budget_name,
                    currency_id,
                    currency_symbol_placement,
                    date_format_id,
                    id,
                    note,
                    number_format_id,
                    updated_at,
                    user_id,
                } = data?.budget

                state.selectedBudgetName = budget_name
                state.selectedBudgetId = id
                state.selectedBudgetCurrency = currency_id
                state.selectedBudgetCurrencyPlacement = currency_symbol_placement
                state.selectedBudgetNumberFormat = number_format_id
                state.selectedBudgetDateFormat = date_format_id

                toast("Budget is added and changed successfylly!")
            }
        },
        ['auth/Logout/fulfilled']: (state, action) => {
            state.selectedBudgetName = null
            state.selectedBudgetId = 0
            state.selectedBudgetCurrency = null
            state.selectedBudgetCurrencyPlacement = null
            state.selectedBudgetNumberFormat = null
            state.selectedBudgetDateFormat = null

            state.sidebarColor = 'white'
            state.userData = null
        },
    },
});

export const {
    ChangeSidebarColor,
    ChangeUserData,
    Logout,
    ChangeSelectedBudgetId,
} = userDataSlice.actions;

export default userDataSlice.reducer;