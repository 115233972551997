export const styles = {
    toolbarDiv: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 15,
    },
    calendarArrows: {
        cursor: "pointer",
        backgroundColor: "#5CCBCE55",
        padding: 5,
        borderRadius: 5,
    },
    toolbarLeftDiv: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
    },
    toolbarRightDiv: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        fontSize: 14,
    },
    goalTypeDiv: {
        padding: 5,
        marginLeft: 5,
        borderRadius: 5,
    },
    smartHr: {
        borderWidth: 0
    },
    helocTimesSheepHeader: {
        display: "flex",
        flexDirection: "row",
        height: 50,
    },
    helocTimesSheepHeaderInner: {
        display: "flex",
        flex: 1,
    },

}