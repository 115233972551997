
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Button } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { ChangeDeletePayeeModal, PostPayee, PutPayee, } from "../../ManagePayees/Store/managePayeesSlice";

function AddPayee(props) {

    const dispatch = useDispatch()

    const { selectEnvelopeMortgageGoalList } = useSelector(state => state.shared)
    const { formData } = useSelector(state => state.managePayees)

    const expenseList = selectEnvelopeMortgageGoalList?.[0]?.options //todo: change this when API is fixed later 

    const defaultValues = {
        id: 0,
        payeeName: "",
        isIncluded: true,
        isAutomatic: true,
        envelopeMortgageGoal: null
    }

    const { control, handleSubmit, reset, formState: { errors }, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    const watchId = watch("id")

    useEffect(() => {
        const { id, payeeName, isIncluded, isAutomatic, envelopeMortgageGoalId, parentId } = formData || {}

        let envelop = null

        envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == parentId)?.options
        console.log("ENVELOP--", envelop)
        console.log("ENVELOPEMORTGAGEGOALID--", envelopeMortgageGoalId)
        if (envelop)
            envelop = envelop.find(obj => obj.value == envelopeMortgageGoalId)
        console.log("ENVELOP--", envelop)

        reset({
            id,
            payeeName,
            isIncluded,
            isAutomatic,
            envelopeMortgageGoal: envelop
        })
    }, [formData])

    return (
        <>
            <Col md="7" className="" id="single-checbox-div">
                <Controller
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            placeholder="Payee Name"
                        />
                    )}
                    name="payeeName"
                />
                {errors.payeeName &&
                    <div className="text text-danger error-text">Enter Payee Name</div>
                }
                <p className="font-weight-bold border-bottom p-2">Autofill options </p>
                <FormGroup check>
                    <Label check>
                        <Controller
                            control={control}
                            rules={{
                                // required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    checked={value}
                                    onChange={(e) => {
                                        onChange(e.target.checked)
                                    }}
                                    type="checkbox"
                                />
                            )}
                            name="isIncluded"
                        />
                        {errors.isIncluded &&
                            <div className="text text-danger error-text">Select Option</div>
                        }
                        <span className="form-check-sign" />
                        Include in Payees List
                        <p>If enabled, this payee will be suggested as you type and will be shown in the list of payees when adding transactions.</p>
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Controller
                            control={control}
                            rules={{
                                // required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    checked={value}
                                    onChange={(e) => {
                                        onChange(e.target.checked)
                                    }}
                                    type="checkbox"
                                />
                            )}
                            name="isAutomatic"
                        />
                        {errors.isAutomatic &&
                            <div className="text text-danger error-text">Select Option</div>
                        }
                        <span className="form-check-sign" />
                        Automatically categorize payee
                        <p>If enabled, the selected category will automatically be assigned to a transaction with this payee</p>
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                value={value}
                                onChange={(value) => onChange(value)}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                options={selectEnvelopeMortgageGoalList}
                                placeholder="Select expense/goal/mortgage"
                            />
                        )}
                        name="envelopeMortgageGoal"
                    />
                    {errors.envelopeMortgageGoal &&
                        <div className="text text-danger error-text">Select expense/goal/mortgage</div>
                    }
                </FormGroup>
            </Col>
            <div className="text-right" >
                {watchId != 0 &&
                    <Button
                        onClick={() => dispatch(ChangeDeletePayeeModal(true))}
                        color="danger"
                        className="mt-4"
                        style={{ marginRight: "5px" }}
                    >
                        Delete
                    </Button>
                }
                {watchId == 0 ?
                    <Button
                        onClick={handleSubmit(async (data) => {
                            console.log("DATA")
                            console.log(data)
                            await dispatch(PostPayee(data))
                            reset(defaultValues)
                        })}
                        color="primary"
                        className="mt-4"
                    >
                        Save
                    </Button>
                    :
                    <Button
                        onClick={handleSubmit(async (data) => {
                            console.log("DATA")
                            console.log(data)
                            await dispatch(PutPayee(data))
                        })}
                        color="primary"
                        className="mt-4"
                    >
                        Edit
                    </Button>
                }
            </div>
        </>
    );
}

export default AddPayee;
