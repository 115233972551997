
import { GetAmount } from "App/Config/Helper";
import React, { useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import {
    Card, CardBody, CardHeader, CardTitle, Col, Form, Row
} from "reactstrap";
import './AddLinkedAccount.css';
import PlaidLinkButton from "./PlaidLinkButton";
import { CreateLinkToken } from "./Stote/addAccountSlice";


function AddLinkedAccount(props) {


    const dispatch = useDispatch()

    const { linkToken } = useSelector(state => state.addAccount)
    const { accounts } = useSelector(state => state.shared)

    const [linkedAccounts, setLinkedAccounts] = useState([])

    function RenderLinkedAccounts() {
        return linkedAccounts.map((item) => {
            const {
                accountId,
                path,
                singleName,
                name,
                mini,
                layout,
                amount,
                isPlaidAccount,
                plaidAccountId,
            } = item

            return (
                <div className="linked-account-row">
                    <Avatar
                        name={name[0]}
                        size="40px"
                    />
                    <span className="linked-account-name">{name}</span>
                    <span className="linked-account-amount">{GetAmount(amount)}</span>
                </div>
            )
        })
    }

    React.useEffect(() => {
        if (accounts) {
            let linkedAccounts = accounts.filter(item => item.plaidAccountId)
            setLinkedAccounts(linkedAccounts)
        }
    }, [accounts])
    React.useEffect(() => {
        if (!linkToken)
            dispatch(CreateLinkToken())
    }, [])

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="6" className="ml-auto mr-auto">
                        <Card className="h-100">
                            <CardHeader className="text-center">
                                <CardTitle tag="h5">Linked Accounts</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {linkedAccounts.length > 0 ?
                                    <>
                                        <div className="linked-account-row">
                                            <span className="linked-account-header">{"Icon"}</span>
                                            <span className="linked-account-header">{"Name"}</span>
                                            <span className="linked-account-header">{"Amount"}</span>
                                        </div>
                                        {RenderLinkedAccounts()}
                                    </>
                                    :
                                    <Form >
                                        <div className="text-center">
                                            <i className="fas fa-university fa-2x"></i>
                                            <p className="text-muted">No Accounts Connected</p>
                                            <p>All linked accounts connected to your Budget will appear here</p>
                                        </div>
                                        <div className="text-center">
                                            {linkToken &&
                                                <PlaidLinkButton />
                                            }
                                        </div>
                                    </Form>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AddLinkedAccount;
