import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiInstance, apiNoLoaderInstance } from 'App/Config/AxiosInstances';
import moment from "moment";
import { toast } from 'react-toastify';
import { GetMortgageMonths, UpdateAccounts, UpdateEnvelopeMortgageGoalNames, UpdateFundsToAllocate } from '../../Shared/Store/sharedSlice';

export const AddTransaction = createAsyncThunk('accounts/AddTransaction', async (params, { dispatch, getState }) => {
    try {

        const { account, transactionDate, payee, envelopeMortgageGoal, memo, outflow, inflow, selectedMonth, extraAmount } = params || {}

        let date = transactionDate.split('/')
        let partialBody

        if (payee?.parentId == -2)
            partialBody = {
                'payee_account_id': payee?.value
            }
        else
            if (envelopeMortgageGoal.parentId == -1)
                partialBody = {
                    'mortgage_id': envelopeMortgageGoal.value,
                    'mortageMonth_id': selectedMonth.value,
                    'payee_id': payee?.value,
                    "extra_amount": extraAmount ? extraAmount : 0,
                }
            else if (envelopeMortgageGoal.parentId > 1)
                partialBody = {
                    'envelop_id': envelopeMortgageGoal.parentId,
                    'envelop_sub_cat_id': envelopeMortgageGoal.value,
                    'payee_id': payee?.value,
                }
            else
                partialBody = {
                    'goal_id': envelopeMortgageGoal.value,
                    'payee_id': payee?.value,
                }

        let result = await apiInstance.post('/api/transactions', {
            'account_id': account.value,
            'out_flow_amount': outflow,
            'in_flow_amount': inflow,
            'transaction_date': `${date[2]}-${date[1]}-${date[0]}`,
            'memo': memo,
            ...partialBody
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(UpdateFundsToAllocate())

        if (envelopeMortgageGoal?.parentId == -1) {
            dispatch(UpdateEnvelopeMortgageGoalNames())
            dispatch(GetMortgageMonths(envelopeMortgageGoal?.value))
        }

        const { data, status } = result
        return { data, status, selectedMonth, envelopeMortgageGoal }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const EditTransaction = createAsyncThunk('accounts/EditTransaction', async (params, { dispatch, getState }) => {
    try {
        const { id,
            account,
            transactionDate,
            payee,
            envelopeMortgageGoal,
            memo,
            outflow,
            inflow,
            selectedMonth,
            supressLoader,
        } = params

        let instance
        if (supressLoader)
            instance = apiNoLoaderInstance
        else
            instance = apiInstance

        let date = transactionDate.split('/')
        let partialBody

        if (payee?.parentId == -2)
            partialBody = {
                'payee_account_id': payee?.value
            }
        else
            if (envelopeMortgageGoal.parentId == -1)
                partialBody = {
                    'mortgage_id': envelopeMortgageGoal.value,
                    'mortageMonth_id': selectedMonth.value,
                    'payee_id': payee?.value,
                }
            else if (envelopeMortgageGoal.parentId > 1)
                partialBody = {
                    'envelop_id': envelopeMortgageGoal.parentId,
                    'envelop_sub_cat_id': envelopeMortgageGoal.value,
                    'payee_id': payee?.value,
                }
            else
                partialBody = {
                    'goal_id': envelopeMortgageGoal.value,
                    'payee_id': payee?.value,
                }

        let result = await instance.put(`/api/transactions/${id}`, {
            'account_id': account.value,
            'out_flow_amount': outflow,
            'in_flow_amount': inflow,
            'transaction_date': `${date[2]}-${date[1]}-${date[0]}`,
            'memo': memo,
            ...partialBody
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(UpdateFundsToAllocate())
        dispatch(UpdateAccounts())

        const { data, status } = result
        return { data, status, selectedMonth }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const BookmarkTransaction = createAsyncThunk('accounts/BookmarkTransaction', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { id, bookmark } = params

        let result = await apiNoLoaderInstance.post(`/api/bookmark/${id}`, {
            bookmark,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const ClearTransaction = createAsyncThunk('accounts/ClearTransaction', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { id, isCleared } = params

        let result = await apiNoLoaderInstance.post(`/api/cleared-transaction/${id}`, {
            isCleared,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetTransactionsByAccountId = createAsyncThunk('accounts/GetTransactionsByAccountId', async (params, { dispatch, getState }) => {
    try {

        const accontId = params

        let result = await apiInstance.get(`/api/transactions/get-account-transaction/${accontId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetTransactionsByBudgetId = createAsyncThunk('accounts/GetTransactionsByBudgetId', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.get(`/api/transactions/get-account-transaction-by-budget-id/${selectedBudgetId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        submitButtonLoading: false,

        addTransactionModal: false,

        transactions: [],
        clearedBalance: 0,
        unClearedBalance: 0,

        formData: {
            id: 0,
            account: null,
            transactionDate: moment(new Date()).format("DD/MM/YYYY"),
            payee: null,
            envelope: null,
            memo: null,
            outflow: null,
            inflow: null,
        },
    },
    reducers: {
        ChangeAddTransactionModal: (state, action) => {
            if (action.payload) {
                let transactions = []
                current(state.transactions).map((item) => {
                    transactions.push({
                        ...item,
                        isChecked: false,
                    })
                })
                state.transactions = transactions

                state.formData = {
                    id: 0,
                    account: null,
                    transactionDate: moment(new Date()).format("DD/MM/YYYY"),
                    payee: null,
                    envelopeMortgageGoal: null,
                    memo: null,
                    outflow: "0",
                    inflow: "0",
                    selectedMonth: null,
                }
            }
            state.addTransactionModal = action.payload
        },
        ChangeEditTransactionModal: (state, action) => {
            state.addTransactionModal = action.payload
        },
        ChangeFormData: (state, action) => {
            state.formData = action.payload
        },
    },
    extraReducers: {
        ['accounts/ClearTransaction/pending']: (state, action) => {
            const { id, isCleared } = action.meta.arg || {}
            let transactions = []

            current(state.transactions).map((item) => {
                if (item.id == id) {

                    let difference = parseFloat(item?.in_flow_amount) - parseFloat(item?.out_flow_amount)

                    if (isCleared) {
                        state.clearedBalance = state.clearedBalance + difference
                        state.unClearedBalance = state.unClearedBalance - difference
                    } else {
                        state.clearedBalance = state.clearedBalance - difference
                        state.unClearedBalance = state.unClearedBalance + difference
                    }

                    transactions.push({
                        ...item,
                        isCleared,
                    })
                }
                else
                    transactions.push(item)
            })
            state.transactions = transactions
        },
        ['accounts/BookmarkTransaction/pending']: (state, action) => {
            const { bookmark, id } = action.meta.arg || {}
            let transactions = []
            current(state.transactions).map((item) => {
                if (item.id == id)
                    transactions.push({
                        ...item,
                        bookmark,
                    })
                else
                    transactions.push(item)
            })
            state.transactions = transactions
        },
        ['accounts/BookmarkTransaction/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                // toast(data?.message)
            }
        },
        ['accounts/EditTransaction/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                const { transaction, message } = data || {}

                let transactions = []

                current(state.transactions).map((item) => {
                    if (item?.id == transaction?.id) {

                        let previousDifference = parseFloat(item?.in_flow_amount) - parseFloat(item?.out_flow_amount)
                        let newDifference = parseFloat(transaction?.in_flow_amount) - parseFloat(transaction?.out_flow_amount)

                        if (item?.isCleared) {
                            state.clearedBalance = state.clearedBalance - previousDifference
                            state.clearedBalance = state.clearedBalance + newDifference
                        } else {
                            state.unClearedBalance = state.unClearedBalance - previousDifference
                            state.unClearedBalance = state.unClearedBalance + newDifference
                        }

                        transactions.push({
                            ...item,
                            ...transaction,
                            isChecked: true,
                        })
                    }
                    else
                        transactions.push(item)
                })

                state.transactions = transactions
                toast(message)
            }
        },
        ['accounts/AddTransaction/fulfilled']: (state, action) => {
            const { data, status, envelopeMortgageGoal } = action.payload || {}
            if (status >= 200 && status < 300) {

                let difference = parseFloat(data?.transaction?.in_flow_amount) - parseFloat(data?.transaction?.out_flow_amount)

                state.unClearedBalance = state.unClearedBalance + difference

                state.transactions.push({
                    ...data?.transaction,
                    isChecked: false,
                    accountName: data?.transaction?.account?.account_title,
                    payeeAccountName: data?.transaction?.payee_account?.account_title,
                    payeeName: data?.transaction?.payee?.payee_name,
                    expenseName: data?.transaction?.sub_envelop?.envelop_sub_category_name,
                    goalName: data?.transaction?.my_goal?.title,
                    mortgageName: data?.transaction?.mortgage?.mortgage_name,
                })
                toast(data?.message)
                state.addTransactionModal = false
            } else if (status >= 400 && status < 500) {
                if (envelopeMortgageGoal.parentId > 1)
                    toast("Please add a goal first against selected expense!")
                else
                    toast(data?.message)
            }
        },
        ['accounts/GetTransactionsByAccountId/pending']: (state, action) => {
            state.transactions = []
            state.clearedBalance = 0
            state.unClearedBalance = 0
        },
        ['accounts/GetTransactionsByAccountId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let transactions = []
                let clearedBalance = 0
                let unClearedBalance = 0

                if (data?.data?.transactions)
                    data.data.transactions.map((item) => {

                        let difference = parseFloat(item?.in_flow_amount) - parseFloat(item?.out_flow_amount)

                        if (item?.isCleared)
                            clearedBalance = clearedBalance + difference
                        else
                            unClearedBalance = unClearedBalance + difference

                        transactions.push({
                            ...item,
                            isChecked: false,
                            accountName: data?.data?.account_title,
                            payeeAccountName: item?.payee_account?.account_title,
                            payeeName: item?.payee?.payee_name,
                            expenseName: item?.sub_envelop?.envelop_sub_category_name,
                            goalName: item?.my_goal?.title,
                            mortgageName: item?.mortgage?.mortgage_name,
                        })
                    })
                state.transactions = transactions
                state.clearedBalance = clearedBalance
                state.unClearedBalance = unClearedBalance
            }
        },
        ['accounts/GetTransactionsByBudgetId/pending']: (state, action) => {
            state.transactions = []
            state.clearedBalance = 0
            state.unClearedBalance = 0
        },
        ['accounts/GetTransactionsByBudgetId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let transactionsTemp = []
                let clearedBalance = 0
                let unClearedBalance = 0

                data.data.map((item) => {
                    const {
                        transactions,
                    } = item
                    transactions.map((subItem) => {

                        let difference = parseFloat(subItem?.in_flow_amount) - parseFloat(subItem?.out_flow_amount)

                        if (subItem?.isCleared)
                            clearedBalance = clearedBalance + difference
                        else
                            unClearedBalance = unClearedBalance + difference

                        transactionsTemp.push({
                            ...subItem,
                            isChecked: false,
                            accountName: item?.account_title,
                            payeeAccountName: subItem?.payee_account?.account_title,
                            payeeName: subItem?.payee?.payee_name,
                            expenseName: subItem?.sub_envelop?.envelop_sub_category_name,
                            goalName: subItem?.my_goal?.title,
                            mortgageName: subItem?.mortgage?.mortgage_name,
                        })
                    })
                })
                state.transactions = transactionsTemp
                state.clearedBalance = clearedBalance
                state.unClearedBalance = unClearedBalance
            }
        },
    },
});

export const {
    ChangeFormData,
    ChangeEditTransactionModal,
    ChangeAddTransactionModal,
} = accountsSlice.actions;

export default accountsSlice.reducer;