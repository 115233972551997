import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const PostPayee = createAsyncThunk('managePayees/PostPayee', async (params, { dispatch, getState }) => {
    try {

        const { payeeName, isIncluded, isAutomatic, envelopeMortgageGoal } = params
        const { selectedBudgetId } = getState().userData

        let partialBody
        if (envelopeMortgageGoal.parentId == -1)
            partialBody = {
                'mortgage_id': envelopeMortgageGoal.value,
            }
        else if (envelopeMortgageGoal.parentId >= 1) //! > 1 will give error on DB clean (use >=1 everywhere)
            partialBody = {
                'envelop_sub_id': envelopeMortgageGoal.value,
            }
        else
            partialBody = {
                'goal_id': envelopeMortgageGoal.value,
            }

        let result = await apiInstance.post('/api/create-payees', {
            'budget_id': selectedBudgetId,
            'payee_name': payeeName,
            'include_in_payee': isIncluded ? 1 : 0,
            'automatically_categorize_payee': isAutomatic ? 1 : 0,
            ...partialBody,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PutPayee = createAsyncThunk('managePayees/PutPayee', async (params, { dispatch, getState }) => {
    try {

        const { id, payeeName, isIncluded, isAutomatic, envelopeMortgageGoal } = params
        const { selectedBudgetId } = getState().userData

        let partialBody
        if (envelopeMortgageGoal.parentId == -1)
            partialBody = {
                'mortgage_id': envelopeMortgageGoal.value,
            }
        else if (envelopeMortgageGoal.parentId >= 1) //! > 1 will give error on DB clean (use >=1 everywhere)
            partialBody = {
                'envelop_sub_id': envelopeMortgageGoal.value,
            }
        else
            partialBody = {
                'goal_id': envelopeMortgageGoal.value,
            }

        let result = await apiInstance.post(`/api/update-payees/${id}`, {
            'budget_id': selectedBudgetId,
            'payee_name': payeeName,
            'include_in_payee': isIncluded ? 1 : 0,
            'automatically_categorize_payee': isAutomatic ? 1 : 0,
            ...partialBody,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const DeletePayee = createAsyncThunk('managePayees/DeletePayee', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { payeeId, replacePayeeId } = params

        let result = await apiInstance.post(`/api/delete-payees/${payeeId}`, {
            'replace_payee_id': replacePayeeId?.value,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const CombinePayee = createAsyncThunk('managePayees/CombinePayee', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { payeeName } = params

        let payeeIds = []
        getState().shared.savedPayeeList.map(obj => {
            if (obj.isChecked && obj.value)
                payeeIds.push(obj.value)
        })

        let payeeId = payeeIds.shift()

        let result = await apiInstance.post(`/api/combine-payees`, {
            'payee_id': payeeId,
            'payee_ids': payeeIds.toString(),
            'payee_name': payeeName,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status, payeeIds }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const managePayeesSlice = createSlice({
    name: 'managePayees',
    initialState: {
        addPayeeModal: false,
        deletePayeeModal: false,

        formData: {
            id: 0,
            payeeName: null,
            isIncluded: true,
            isAutomatic: true,
            envelopeMortgageGoalId: null,
            parentId: null,
        }
    },
    reducers: {
        ChangeDeletePayeeModal: (state, action) => {
            state.deletePayeeModal = action.payload
        },
        ChangeFormData: (state, action) => {
            state.formData = action.payload
        },
        ChangeAddPayeeModal: (state, action) => {
            state.addPayeeModal = action.payload
        },
    },
    extraReducers: {
        ['managePayees/DeletePayee/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.deletePayeeModal = false
                toast(data?.message)
            }
        },
    },
});

export const {
    ChangeAddPayeeModal,
    ChangeFormData,
    ChangeDeletePayeeModal,
} = managePayeesSlice.actions;

export default managePayeesSlice.reducer;