import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const creditRepairSlice = createSlice({
    name: 'creditRepair',
    initialState: {
        disputeStatus: [
            {
                name: "Positive",
                nameClass: "text-success",
                transunion: 16,
                experian: 17,
                timeZone: 16,
            },
            {
                name: "Charge off",
                nameClass: "text-danger",
                transunion: 34,
                experian: 54,
                timeZone: 56,
            },
            {
                name: "Late Payments",
                nameClass: "text-danger",
                transunion: 78,
                experian: 34,
                timeZone: 98,
            },
            {
                name: "In Dispute",
                nameClass: "text-info",
                transunion: 23,
                experian: 345,
                timeZone: 56,
            },
            {
                name: "Dispute(ChargeOff)",
                nameClass: "text-info",
                transunion: 689,
                experian: 45,
                timeZone: 346,
            },
            {
                name: "Dispute(ChargeOff)",
                nameClass: "text-info",
                transunion: 16,
                experian: 17,
                timeZone: 16,
            },
        ]
    },
    reducers: {
    },
    extraReducers: {
    },
});

export const {
} = creditRepairSlice.actions;

export default creditRepairSlice.reducer;