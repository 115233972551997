
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { MdArrowBack } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from "react-select";
import { toast } from "react-toastify";
import {
    Button,
    Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row
} from "reactstrap";
import CreditCardAccountModal from "./CreditCardAccountModal";
import {
    ChangeCreditCardAccountModal,
    ChangeFormData,
    DeleteUnLinkedAccount,
    GetAccountbyId,
    PostUnLinkedAccount,
    PutUnLinkedAccount
} from "./Stote/addAccountSlice";


function AddUnLinkedAccount(props) {
    const defaultValues = {
        accountType: null,
        accountTitle: '',
        accountBalance: '',
    }

    const history = useHistory()
    const { accountId } = useParams()
    console.log(accountId)
    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const { formData, accountTypes, } = useSelector(state => state.addAccount)
    const { selectedBudgetId } = useSelector(state => state.userData)

    const handleDelete = async () => {
        let res = await dispatch(DeleteUnLinkedAccount(formData.id))
        if (res.payload.status >= 200 && res.payload.status < 300)
            history.push('/admin/accounts/0')
    }

    React.useEffect(() => {
        if (accountId != 0) {
            console.log("FETCH HERE")
            dispatch(GetAccountbyId({ accountId }))
        } else {
            dispatch(ChangeFormData({
                accountType: null,
                accountTitle: '',
                accountBalance: '',
            }))
        }
    }, [])
    React.useEffect(() => {
        const {
            id,
            user_id,
            account_type_id,
            budget_id,
            account_title,
            current_amount,
            is_account_unlinked,
            status,
            json_detail
        } = formData || {}
        reset({
            accountType: accountTypes.find(obj => obj.value == account_type_id),
            accountTitle: account_title,
            accountBalance: current_amount,
        })
    }, [formData])

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="6" className="ml-auto mr-auto">
                        <Card>
                            <Link to="/admin/add-account">
                                <div style={{ padding: "0.5rem", fontSize: "1rem" }} >
                                    <MdArrowBack />
                                </div>
                            </Link>
                            <CardHeader className="text-center">
                                <CardTitle tag="h5">
                                    {accountId == 0 ?
                                        "Add Unlinked Account"
                                        :
                                        "Edit Unlinked Account"
                                    }
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form >
                                    {accountId == 0 &&
                                        <>
                                            <label>Type of account</label>
                                            <FormGroup>
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: accountId == 0,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="singleSelect"
                                                            value={value}
                                                            onChange={(value) => onChange(value)}
                                                            options={accountTypes}
                                                            placeholder="Select Account Type"
                                                        />
                                                    )}
                                                    name="accountType"
                                                />
                                                {errors.accountType &&
                                                    <div className="text text-danger error-text">Enter account type</div>
                                                }
                                            </FormGroup>
                                        </>
                                    }
                                    <label>Account Title</label>
                                    <FormGroup>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                                maxLength: 20,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    value={value}
                                                    onChange={(event) => onChange(event.target.value)}
                                                    placeholder="Enter Account Title"
                                                    type="text"
                                                />
                                            )}
                                            name="accountTitle"
                                        />
                                        {errors.accountTitle &&
                                            <div className="text text-danger error-text">Enter account title with max 20 charavters</div>
                                        }
                                    </FormGroup>
                                    <label>Current account Balance</label>
                                    <FormGroup>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    value={value}
                                                    onChange={(event) => onChange(event.target.value)}
                                                    placeholder="Put a rough estimate , you can change it later"
                                                    type="number"
                                                />
                                            )}
                                            name="accountBalance"
                                        />
                                        {errors.accountBalance &&
                                            <div className="text text-danger error-text">Enter account balance</div>
                                        }
                                    </FormGroup>
                                    <div className="text-center">
                                        {accountId == 0 ?
                                            <Button
                                                color="primary"
                                                onClick={handleSubmit(async (data) => {

                                                    if (selectedBudgetId == 0)
                                                        toast("Please add/select a budget first")
                                                    else {

                                                        if (data?.accountType?.label == 'Credit Card')
                                                            dispatch(ChangeCreditCardAccountModal(true))
                                                        else {
                                                            await dispatch(PostUnLinkedAccount(data))
                                                            reset({
                                                                accountType: null,
                                                                accountTitle: '',
                                                                accountBalance: '',
                                                            })
                                                        }

                                                    }
                                                })}
                                                outline
                                            >
                                                {"Add Account"}
                                            </Button>
                                            :
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <Button
                                                    color="primary"
                                                    onClick={handleSubmit(async (data) => {
                                                        dispatch(PutUnLinkedAccount({ ...data, accountId: formData.id }))
                                                    })}
                                                    outline
                                                >
                                                    {"Edit Account"}
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    onClick={handleDelete}
                                                    outline
                                                >
                                                    {"Delete Account"}
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CreditCardAccountModal
                handleSubmit={handleSubmit}
                reset={reset}
            />
        </>
    );
}

export default AddUnLinkedAccount;
