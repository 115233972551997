import { emailRegex } from "../../Config/Constants";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import {
    Button,
    Card, CardBody, CardHeader, Col, Container, Form,
    Input, InputGroup, InputGroupAddon,
    InputGroupText, Row
} from "reactstrap";
import { Register } from "./Store/authSlice";

function SignUp(props) {
    const defaultValues = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
    }

    const dispatch = useDispatch()
    const history = useHistory()

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const handleRegister = handleSubmit(async (data) => {
        let action = await dispatch(Register(data))

        const { data: data1, status } = action.payload || {}

        if (status >= 200 && status < 300)
            if (!data1?.data?.user?.is_email_verified)
                history.push("/auth/otp")
    })

    return (
        <div className="login-page">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <Form action="" className="form" method="">
                            <Card className="card-login">
                                <CardHeader>
                                    <CardHeader className="text-center">
                                        <h3 className="header text-center">Sign Up</h3>
                                        <Link to="/auth/login">Already a Member ?  </Link>
                                    </CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <InputGroup className="custom-input-group">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    onChange={(event) => onChange(event.target.value)}
                                                    value={value}
                                                    placeholder="First Name"
                                                    type="text"
                                                />
                                            )}
                                            name="firstname"
                                        />
                                    </InputGroup>
                                    {errors.firstname &&
                                        <div className="text text-danger error-text">Enter Firstname</div>
                                    }
                                    <div style={{ height: 10 }} />
                                    <InputGroup className="custom-input-group">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    onChange={(event) => onChange(event.target.value)}
                                                    value={value}
                                                    placeholder="Last Name"
                                                    type="text"
                                                />
                                            )}
                                            name="lastname"
                                        />
                                    </InputGroup>
                                    {errors.lastname &&
                                        <div className="text text-danger error-text">Enter Lastname</div>
                                    }
                                    <div style={{ height: 10 }} />
                                    <InputGroup className="custom-input-group">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: emailRegex,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    onChange={(event) => onChange(event.target.value)}
                                                    value={value}
                                                    placeholder="Email / Username"
                                                    type="email"
                                                />
                                            )}
                                            name="email"
                                        />
                                    </InputGroup>
                                    {errors.email &&
                                        <div className="text text-danger error-text">Enter valid email address</div>
                                    }
                                    <div style={{ height: 10 }} />
                                    <InputGroup className="custom-input-group">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-key-25" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                                minLength: 8,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    onChange={(event) => onChange(event.target.value)}
                                                    value={value}
                                                    placeholder="Password"
                                                    type="password"
                                                    autoComplete="off"
                                                />
                                            )}
                                            name="password"
                                        />
                                    </InputGroup>
                                    {errors.password &&
                                        <div className="text text-danger error-text">Enter password with min 8 chars</div>
                                    }
                                    <div style={{ height: 10 }} />
                                    <br />
                                </CardBody>
                                <div className="text-center">
                                    <Button
                                        onClick={handleRegister}
                                        color="primary"
                                    >
                                        {"Sign Up"}
                                    </Button>
                                </div>

                                {/* <div className="social text-center ">
                                    <p className="card-description">Or Sign Up with</p>

                                    <Button className="btn-icon btn-round text-center" color="apple">
                                        <i className="fab fa-apple" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="google">
                                        <i className="fab fa-google" />
                                    </Button>

                                </div> */}
                            </Card>


                        </Form>


                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
                }}
            />
        </div >
    );
}

export default SignUp;
