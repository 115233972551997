
import React from "react";
import Avatar from "react-avatar";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Button,
  Card, CardBody, CardHeader, Col, Form, FormGroup, Input,
  Row
} from "reactstrap";
import CustomAvatar from "../Shared/Components/CustomAvatar";
import { EditUser } from "./Store/userProfileSlice";
import './UserProfile.css'

function UserProfile(props) {

  const dispatch = useDispatch()

  const { userData } = useSelector(state => state.userData)
  const {
    email,
    first_name,
    last_name,
  } = userData?.user || {}


  const defaultValues = {
    username: first_name + last_name,
    email: email,
    firstname: first_name,
    lastname: last_name,
  }
  const { control, handleSubmit, reset, formState: { errors }, getValues } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <img
                  alt="..."
                  src={require("assets/img/bg/damir-bosnjak.jpg")}
                />
              </div>
              <CardBody className="custom-card-body">
                <div className="author">
                  <CustomAvatar upload={true} />
                  <h5 className="title">{`${first_name} ${last_name}`}</h5>
                  <p className="description">{email}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>

                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Username</label>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              disabled
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              placeholder="Username"
                              type="text"
                            />
                          )}
                          name="username"
                        />
                        {errors.username &&
                          <div className="text text-danger error-text">Enter Username</div>
                        }
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              disabled
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              placeholder="Email"
                              type="email"
                            />
                          )}
                          name="email"
                        />
                        {errors.email &&
                          <div className="text text-danger error-text">Enter Email</div>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              placeholder="Company"
                              type="text"
                            />
                          )}
                          name="firstname"
                        />
                        {errors.firstname &&
                          <div className="text text-danger error-text">Enter First Name</div>
                        }
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              placeholder="Last Name"
                              type="text"
                            />
                          )}
                          name="lastname"
                        />
                        {errors.lastname &&
                          <div className="text text-danger error-text">Enter Last Name</div>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <div className="text-center">
                <Button
                  onClick={handleSubmit(async (data) => {
                    await dispatch(EditUser(data))
                    console.log("FIRST_NAME--", first_name)
                    reset({
                      ...getValues(),
                      username: getValues().firstname + getValues().lastname
                    })
                  })}
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
