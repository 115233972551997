
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    Row
} from "reactstrap";
import swal from "sweetalert";
import { DeleteUser, Logout } from "../Auth/Store/authSlice";
import CustomAvatar from "../Shared/Components/CustomAvatar";

function GeneralSettings() {

    const dispatch = useDispatch()

    const { userData } = useSelector(state => state.userData)
    const {
        budget_id,
        email,
        first_name,
        id,
        is_email_verified,
        is_heloc_accunt_is_on,
        is_sms_verified,
        last_name,
        two_step_varification,
        verification_code,
    } = userData?.user || {}

    const [modalMini, setModalMini] = useState(false)

    async function deleteAccount() {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {

                let action = await dispatch(DeleteUser())

                const { data, status } = action.payload || {}

                if (status >= 200 && status < 300) {

                    swal({
                        title: "Success!",
                        text: "Your account has been deleted.",
                        icon: "success",
                    }).then(() => {
                        
                        dispatch(Logout())
                    })
                }
            }
        });
    }

    const toggleModalMini = () => {
        setModalMini(!modalMini)
    };

    React.useEffect(() => {
    }, [])

    return (
        <>
            <div className="content">
                <Row>

                    <Col md="8" className="mr-auto ml-auto">
                        <Card className="w-100 px-5">
                            <CardHeader className="border-bottom">
                                <CardTitle tag="h6">General Settings</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="p-2 border-bottom">
                                    <div className="author text-center">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <CustomAvatar size="80px" />
                                            <h5 className="title">{first_name} {last_name}</h5>
                                        </a>
                                        <p className="description">{email}</p>
                                    </div>
                                    <Form>

                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <FormGroup>
                                                    <label>First Name</label>
                                                    <Input
                                                        defaultValue="Chet"
                                                        placeholder="Company"
                                                        type="text"
                                                        value={first_name}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-1" md="6">
                                                <FormGroup>
                                                    <label>Last Name</label>
                                                    <Input
                                                        defaultValue="Faker"
                                                        placeholder="Last Name"
                                                        type="text"
                                                        value={last_name}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Form>
                                </div>
                                <div className="border-bottom p-2 pb-5">
                                    <h4>Trial</h4>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p>You have <span className="font-weight-bold">30 days</span> left on your trial. </p>
                                        <Button color="primary" className="">Subscribe Now</Button>
                                    </div>

                                </div>

                                <div className="border-bottom p-2 pb-5">
                                    <h4>Options</h4>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <FormGroup check>
                                            <Label check>
                                                <Input defaultChecked type="checkbox" />
                                                <span className="form-check-sign" />
                                            </Label>
                                        </FormGroup>
                                        <p className="mt-5">Open Last Used Budget by default</p>

                                    </div>

                                </div>

                                <div className="border-bottom p-2 pb-5">
                                    <h4>Delete Account</h4>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p>Deleting your account  will remove all of your  <br /> account and budget data permanently.</p>
                                        <Button
                                            onClick={deleteAccount}
                                            color="danger"
                                            className=""
                                        >
                                            {"Delete Account"}
                                        </Button>
                                    </div>

                                </div>


                            </CardBody>

                        </Card>
                    </Col>

                </Row>


                <Modal
                    className="modal-sm"
                    modalClassName="modal-primary"
                    isOpen={modalMini}
                    toggle={toggleModalMini}
                >
                    <div className="modal-body">
                        <Form>
                            <label htmlFor="">New Email</label>
                            <FormGroup>
                                <Input type="email" required />
                            </FormGroup>

                            <label htmlFor="">New Password</label>
                            <FormGroup>
                                <Input type="password" required />
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="">
                            <Button color="primary" onClick={toggleModalMini} type="button">
                                Save
                            </Button>
                        </div>
                        <div className="">
                            <Button
                                color="primary"
                                data-dismiss="modal"
                                type="button"
                                onClick={toggleModalMini}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default GeneralSettings;
