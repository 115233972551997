
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import './Loader.css'

function Loader(props) {
    return (
        <div className="loader-container">
            <ScaleLoader
                color={"#51CBCE"}
                loading={true}
                size={150}
            // css={override}
            />
        </div>
    );
}

export default Loader;
