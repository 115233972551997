import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { toast } from "react-toastify";
import {
    Button, Col, Row
} from "reactstrap";
import { styles } from '../../../custom';
import { CheckIfLate } from '../../Config/Helper';
import { ChangeAddTransactionModal } from '../Accounts/Store/accountsSlice';
import AddTransactionModal from '../Shared/Components/AddTransactionModal';
import MortgageDetialModal from "../Shared/Components/MortgageDetialModal";
import AddMortgageModal from './AddMortgageModal';
import { ChangeAddMortgageModal, ChangeCurrentMortgageDetail, ChangeMortgageDetailModal, ChangeSelectedYear, GetAllMortgages } from './Store/mortgageSlice';

var ps;

function Mortgage(props) {

    const dispatch = useDispatch()


    const { mortgages, years, selectedYear } = useSelector(state => state.mortgage)
    const { selectedBudgetId } = useSelector(state => state.userData)

    function RenderMortgageTransaction(mortgageTransaction) {
        return [...Array(12)].map((item, key) => {

            let currentMonth = key + 1
            currentMonth = ('0' + currentMonth).slice(-2)

            let currentMortgage = mortgageTransaction.find(obj => obj.months.includes(`${selectedYear?.value}-${currentMonth}`))

            const {
                amount, budget_id, created_at, id, months, mortgage_id, mortgage_status, mortgage_status_id, updated_at
            } = currentMortgage || {}

            let isLate = false
            if (mortgage_status_id) {
                isLate = CheckIfLate(months)
            }

            return (
                <td
                    onClick={() => {
                        if (mortgage_status_id) {
                            dispatch(ChangeMortgageDetailModal(true))
                            dispatch(ChangeCurrentMortgageDetail(currentMortgage))
                        }
                    }}
                    style={{ cursor: `${mortgage_status_id ? "pointer" : ""}` }}
                    class="border text-center"
                >
                    {!mortgage_status_id ?
                        <i class="text-success fas fa-times ml-1" />
                        :
                        <>
                            {mortgage_status_id == 3 ?
                                <i class="text-success fas fa-check-circle" />
                                :
                                <>
                                    {(mortgage_status_id == 1 || isLate) ?
                                        <i class="ml-1 fas fa-exclamation-triangle text-danger" />
                                        :
                                        <i class="fas text-warning fa-clock ml-1" />
                                    }
                                </>
                            }
                        </>

                    }
                </td>
            )
        })
    }

    function RenderMortgages() {
        return mortgages.map((item) => {
            const { amount, id, mortgage_name, mortgage_transaction } = item || {}
            return (
                <tr key={id}>
                    <td class="border text-center ">{mortgage_name}</td>
                    {RenderMortgageTransaction(mortgage_transaction)}
                </tr>
            )
        })
    }

    useEffect(() => {
        dispatch(GetAllMortgages())
    }, [])

    useEffect(() => {
        if (mortgages?.length != 0)
            ps = new PerfectScrollbar("#my-scroll-bar", {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
    }, [mortgages])

    return (
        // apply the table props
        <div class="content">
            <div className="row bg-light text-dark align-middle align-items-center my-4" style={{ marginLeft: 2, marginRight: 2, paddingTop: 20, paddingBlock: 20 }}>
                <div className="col-lg-3 col-md-3 align-middle">
                    <Button onClick={() => dispatch(ChangeAddTransactionModal(true))} color="primary">
                        <i className="fas fa-plus"></i>Add Transaction
                    </Button>
                </div>
            </div>
            <div class="h-100 card">
                <div class="card-header">
                    <Row>
                        <Col sm='6'>
                            <h5 style={{ display: "inline" }}>Mortgage sheet</h5>
                        </Col>
                        <Col sm='6'>
                            <div className='sheet-left-items' style={{ justifyContent: "flex-end", alignItems: "center", ...styles.helocTimesSheepHeaderInner }}>
                                <Button
                                    color="primary"
                                    style={{ display: "inline", margin: 0 }}
                                    onClick={() => {
                                        if (selectedBudgetId != 0)
                                            dispatch(ChangeAddMortgageModal(true))
                                        else
                                            toast("Please select/create a budget first")
                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                    Add Mortgage
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div class="card-body">
                    <Row style={{}}>
                        <Col sm='6'>
                            <div style={{ width: 200 }}>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={selectedYear}
                                    onChange={(value) => dispatch(ChangeSelectedYear(value))}
                                    options={years}
                                    placeholder="Select Year"
                                />
                            </div>
                        </Col>
                        <Col sm='6' >
                            <div className='sheet-left-items' style={{ justifyContent: "flex-end", height: 40, alignItems: "center", ...styles.helocTimesSheepHeaderInner }}>
                                <span style={{ marginRight: 20 }}>Late <i class="ml-1 fas fa-exclamation-triangle text-danger"></i></span>
                                <span style={{ marginRight: 20 }}>Unpaid <i class="fas text-warning fa-clock ml-1"></i></span>
                                <span style={{ marginRight: 20 }}>Paid <i class="text-success fas fa-check-circle"></i></span>
                                <span style={{ marginRight: 10 }}>N/A <i class="text-success fas fa-times ml-1" ></i></span>
                            </div>
                        </Col>
                    </Row>
                    {mortgages?.length != 0 ?
                        <table
                            class="table  table-responsive "
                            id="my-scroll-bar"
                            style={{ position: "relative", width: '100%', marginTop: 20 }}>
                            <thead>
                                <tr>
                                    <th class="border text-center" style={{ width: 150, }}>Mortgage for</th>
                                    <th class="border text-center" style={{ width: 100 }}>Jan </th>
                                    <th class="border text-center" style={{ width: 100 }}>Feb </th>
                                    <th class="border text-center" style={{ width: 100 }}>Mar </th>
                                    <th class="border text-center" style={{ width: 100 }}>Apr </th>
                                    <th class="border text-center" style={{ width: 100 }}>May</th>
                                    <th class="border text-center" style={{ width: 100 }}>Jun </th>
                                    <th class="border text-center" style={{ width: 100 }}>Jul </th>
                                    <th class="border text-center" style={{ width: 100 }}>Aug </th>
                                    <th class="border text-center" style={{ width: 100 }}>Sep </th>
                                    <th class="border text-center" style={{ width: 100 }}>Oct </th>
                                    <th class="border text-center" style={{ width: 100 }}>Nov </th>
                                    <th class="border text-center" style={{ width: 100 }}>Dec </th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderMortgages()}
                            </tbody >
                        </table >
                        :
                        <div className='no-data-wrapper'>
                            <span className='no-data'>Mortgages you add will show up here!</span>
                        </div>
                    }
                </div >
            </div >
            <AddMortgageModal />
            <MortgageDetialModal />
            <AddTransactionModal />
        </div >
    )
}

export default Mortgage