import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';

export const GetAllBudgets = createAsyncThunk('allBudget/GetAllBudgets', async (params, { dispatch, getState }) => {
    try {
        let result = await apiInstance.get('/api/budgets')
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const allBudgetSlice = createSlice({
    name: 'allBudget',
    initialState: {
        budgets: []
    },
    reducers: {
    },
    extraReducers: {
        ['allBudget/GetAllBudgets/fulfilled']: (state, action) => {
            console.log("ACTION.PAYLOAD")
            console.log(action.payload)
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.budgets = data?.data
            }
        },
        ['auth/Logout/fulfilled']: (state, action) => {
            state.budgets = []
        },
    },
});

export const {
    ChangeUserData,
} = allBudgetSlice.actions;

export default allBudgetSlice.reducer;