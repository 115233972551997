
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import {
    Button, FormGroup, Modal
} from "reactstrap";
import { ChangeDeleteExpenseModal, DeleteExpense } from "./Store/myBudgetSlice";

const DeleteExpenseModal = (props) => {

    const dispatch = useDispatch()

    const { deleteExpenseModal, selectedExpenseId } = useSelector(state => state.myBudget)
    const { selectEnvelopeMortgageGoalList } = useSelector(state => state.shared)

    const [replaceExpense, setReplaceExpense] = useState(null)

    let envelopesWithExpenses = []
    selectEnvelopeMortgageGoalList.map(item => {
        if (item.id > 0) {
            let options = []
            item.options.map(subItem => {
                if (subItem.value != selectedExpenseId) {
                    options.push(subItem)
                }
            })
            envelopesWithExpenses.push({
                ...item,
                options,
            })
        }
    })

    useEffect(() => {
    }, [])
    return (
        <Modal
            isOpen={deleteExpenseModal}
            toggle={() => dispatch(ChangeDeleteExpenseModal(!deleteExpenseModal))}
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeDeleteExpenseModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-info" style={{ marginTop: "1.5rem" }}>
                    {"Delete Expense"}
                </h5>
            </div>
            <div className="modal-body">
                <div>
                    <label htmlFor="">Select replacement expense</label>
                    <FormGroup>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={replaceExpense}
                            onChange={(value) => {
                                setReplaceExpense(value)
                            }}
                            options={envelopesWithExpenses}
                            placeholder="Select expense"
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeDeleteExpenseModal(!deleteExpenseModal))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link btn-link-cancel"
                        color="danger"
                        type="button"
                        onClick={async () => {
                            await dispatch(DeleteExpense({ expenseId: selectedExpenseId, replaceExpense }))
                            setReplaceExpense(null)
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteExpenseModal