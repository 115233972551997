import axios from 'axios';
import { toast } from 'react-toastify';
import { Logout } from '../Screens/Auth/Store/authSlice';
import { ChangeLoading } from '../Screens/Shared/Store/sharedSlice';
import store from '../Store/index';
import {
    baseURL,
    baseUrlLocal,
    plaidSandboxBaseUrl,
} from './baseURL';

let url = baseURL   

// * apiInstance
export const apiInstance = axios.create({
    baseURL: url,
    // timeout: 10000,
    headers: {
        'Accept': 'Application/json',
        'Access-Control-Max-Age': 0
    }
})

apiInstance.interceptors.request.use(function (config) {
    store.dispatch(ChangeLoading(true))
    // console.log("config--->", config)
    config.headers.Authorization = `Bearer ${store.getState().userData?.userData?.token}`
    return config;
}, function (error) {
    return Promise.reject(error);
});

apiInstance.interceptors.response.use(function (response) {
    store.dispatch(ChangeLoading(false))
    return response;
}, function (error) {
    store.dispatch(ChangeLoading(false))
    const { data, status } = error.response
    console.info(data)
    console.info(status)
    if (status == 401) {
        if (data.message == "Unauthenticated.") {
            toast("Your token was expired, login again to use app", {
                toastId: "token-expired"
            })
            store.dispatch(Logout())
        }
    } else if (status == 429)
        if (data.message.includes("Too Many Attempts"))
            toast("You are being rate limited due to too many attempts", {
                toastId: "too-many-attempts"
            })

    return Promise.reject(error);
});

// * apiInstancePlaid
export const apiInstancePlaid = axios.create({
    baseURL: plaidSandboxBaseUrl,
    // timeout: 10000,
    headers: {
        'Accept': 'Application/json',
        'Access-Control-Max-Age': 0
    }
})

apiInstancePlaid.interceptors.request.use(function (config) {
    // store.dispatch(ChangeLoading(true))
    // console.log("config--->", config)
    return config;
}, function (error) {
    return Promise.reject(error);
});

apiInstancePlaid.interceptors.response.use(function (response) {
    // store.dispatch(ChangeLoading(false))
    return response;
}, function (error) {
    // store.dispatch(ChangeLoading(false))
    const { data, status } = error.response
    console.info(data)
    console.info(status)
    if (status == 401) {
        if (data.message == "Unauthenticated.") {
            toast("Your token was expired, login again to use app", {
                toastId: "token-expired"
            })
            store.dispatch(Logout())
        }
    } else if (status == 429)
        if (data.message.includes("Too Many Attempts"))
            toast("You are being rate limited due to too many attempts", {
                toastId: "too-many-attempts"
            })

    return Promise.reject(error);
});


// * apiNoLoaderInstance
export const apiNoLoaderInstance = axios.create({
    baseURL: url,
    // timeout: 10000,
    headers: {
        'Accept': 'Application/json',
    }
})

apiNoLoaderInstance.interceptors.request.use(function (config) {
    // console.log("config--->", config)
    if (store.getState().userData?.userData?.token)
        config.headers.Authorization = `Bearer ${store.getState().userData?.userData?.token}`
    return config;
}, function (error) {
    return Promise.reject(error);
});

apiNoLoaderInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const { data, status } = error.response
    console.info(status)
    console.info(data)
    if (status == 401) {
        if (data.message.includes("Unauthenticated.")) {
            toast("Your token was expired, login again to use app", {
                toastId: "token-expired"
            })
            store.dispatch(Logout())
        }
    } else if (status == 429)
        if (data.message.includes("Too Many Attempts"))
            toast("You are being rate limited due to too many attempts", {
                toastId: "too-many-attempts"
            })

    return Promise.reject(error);
});