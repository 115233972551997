import React from "react"
import { Line } from "react-chartjs-2"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from "reactstrap"
import {
  chartExample1
} from "../../../variables/charts"
import { GetAmount } from "../../Config/Helper"
import './Dashboard.css'
import { GetDashboad } from "./Store/dashboardSlice"
import {
  FcGlobe,
  FcPaid,
  FcHome,
  FcBadDecision,
  FcBearish,
  FcPrint,
} from "react-icons/fc"

function Dashboard() {

  let chartOptions = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  }

  const dispatch = useDispatch()
  const history = useHistory()

  const {
    totalBudget,
    usedBudget,
    myGoals,
    myObligations,
    upcommingExpenses,
    weekDays,
    byWeekIncome,
    byWeekExpense,
  } = useSelector(state => state.dashboard)

  React.useEffect(() => {
    dispatch(GetDashboad())
  }, [])
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('budget')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcGlobe className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Budget</p>
                      <CardTitle tag="p">{`${GetAmount(totalBudget)}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('budget')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcPaid className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Used Budget</p>
                      <CardTitle tag="p">{`${GetAmount(usedBudget)}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('my-goals')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcHome className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category"> My Goals</p>
                      <CardTitle tag="p">{`${GetAmount(myGoals)}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('my-goals')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcBadDecision className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">My Obligations</p>
                      <CardTitle tag="p">{`${GetAmount(myObligations)}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('calendar')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcBearish className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Upcoming Expenses</p>
                      <CardTitle tag="p">{`${GetAmount(upcommingExpenses)}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats clickable-card"
              onClick={() => history.push('reports')}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <FcPrint className="custom-icon" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">My Reports</p>
                      {/* <CardTitle tag="p">Netflix Bill </CardTitle> */}
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* <Col lg="4" md="6" sm="6">
            <Card 
            className="card-stats clickable-card"
            onClick={()=>history.push()}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i class="fas fa-envelope text-info"></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Letters</p>
                      <CardTitle tag="p">4</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card 
            className="card-stats clickable-card"
            onClick={()=>history.push()}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i class="fas fa-file-archive text-danger"></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Training Disputes</p>
                      <CardTitle tag="p">2</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card 
            className="card-stats clickable-card"
            onClick={()=>history.push()}
            >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i class="fas fa-calendar-alt text-success"></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">My Remainders</p>
                      <CardTitle tag="p">0</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col lg="4" sm="6">
            <Card>
              <CardBody>
                <h6 className="big-title font-weight-bold">Total Expenses</h6>
                <Line

                  data={chartExample1.data}
                  // options={chartExample1.options}
                  height={480}
                  width={826}
                />
              </CardBody>
              {/* <CardFooter>
                  <hr />
                  <h6 className="big-title font-weight-bold">
                    Categories Expenditure
                  </h6>
                </CardFooter> */}
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card>
              <CardBody>
                <h6 className="big-title font-weight-bold">Savings</h6>
                <Line

                  data={chartExample1.data}
                  // options={chartExample3.options}
                  height={480}
                  width={826}
                />
              </CardBody>
              {/* <CardFooter>
                  <hr />
                  <h6 className="big-title font-weight-bold">
                    Savings
                  </h6>
                </CardFooter> */}
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card>
              <CardBody>
                <h6 className="big-title font-weight-bold">Savings</h6>
                <Line

                  data={chartExample1.data}
                  // options={chartExample3.options}
                  height={480}
                  width={826}
                />
              </CardBody>
              {/* <CardFooter>
                  <hr />
                  <h6 className="big-title font-weight-bold">
                    Savings
                  </h6>
                </CardFooter> */}
            </Card>
          </Col>

          {(byWeekExpense && byWeekIncome) &&
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle>
                    <Row>
                      <Col md="6">Income Vs Expense</Col>
                      <Col md="6" class="align-items-end">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 10,
                            backgroundColor: "#EF8357",
                            display: "inline-block",
                          }}
                        ></div>
                        <span style={{ marginLeft: 5 }}>Expense</span>
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: 10,
                            backgroundColor: "#239B37",
                            display: "inline-block",
                            marginLeft: 10,
                          }}
                        ></div>
                        <span style={{ marginLeft: 5 }}>Income</span>
                      </Col>
                    </Row>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Line
                    style={{ marginTop: "40px", display: "flex" }}
                    data={{
                      labels: weekDays,
                      datasets: [
                        {
                          borderColor: "#239B37",
                          backgroundColor: "#239B37",
                          pointRadius: 0,
                          pointHoverRadius: 0,
                          borderWidth: 3,
                          barPercentage: 1.6,
                          data: byWeekIncome,
                        },
                        {
                          borderColor: "#EF8357",
                          backgroundColor: "#EF8357",
                          pointRadius: 0,
                          pointHoverRadius: 0,
                          borderWidth: 3,
                          barPercentage: 1.6,
                          data: byWeekExpense,
                        },
                      ],
                    }}
                    options={chartOptions}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter style={{ padding: "0.8rem" }}>

                  <div className="stats">
                    <i className="fa fa-history" />
                    Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          }

        </Row>

      </div>
    </>
  )
}

export default Dashboard
