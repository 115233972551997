
import moment from "moment";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    Button, FormGroup, Input, Label, Modal
} from "reactstrap";
import { CreateEnvelope, CreateExpense, PostGoal } from "../MyBudget/Store/myBudgetSlice";
import { ChangeLoading } from "../Shared/Store/sharedSlice";
import './CreditCardAccountModal.css';
import { ChangeCreditCardAccountModal, PostUnLinkedAccount } from "./Stote/addAccountSlice";

function CreditCardAccountModal(props) {

    const {
        reset,
        handleSubmit,
    } = props

    const dispatch = useDispatch()

    const {
        creditCardAccountModal
    } = useSelector(state => state.addAccount)
    const {
        selectEnvelopeMortgageGoalList,
    } = useSelector(state => state.shared)

    const [selectedOption, setSelectedOption] = useState(1)
    const [targetMonth, setTargetMonth] = useState(new Date())
    const [targetAmount, setTargetAmount] = useState(1000)

    const handleDone = handleSubmit(async (accountData) => {

        console.log("parseFloat(accountData?.accountBalance)===>", parseFloat(accountData?.accountBalance))
        console.log("targetAmount===>", targetAmount)

        if (targetAmount > parseFloat(accountData?.accountBalance)) {
            toast("Target amount should be less than or equal to account balance")
            return
        }

        dispatch(ChangeLoading(true))

        let res2 = await dispatch(PostUnLinkedAccount({ ...accountData, suppressToast: true }))

        const { data: accountData1, status: accountStatus } = res2.payload || {}

        let accountId = 0
        if (accountStatus >= 200 && accountStatus < 300) {
            const { data: { id } } = accountData1 || {}
            accountId = id
        }

        let res = await dispatch(CreateEnvelope({
            envelopName: 'Credit Card Envelope',
            suppressToast: true,
        }))

        let selectedEnvelopeId
        const { data, status } = res.payload

        if ((status >= 200 && status < 300) || (status >= 400 && status < 500)) {
            const { id } = data?.envelop || {}
            selectedEnvelopeId = id
        } else {
            selectedEnvelopeId = selectEnvelopeMortgageGoalList.find(obj => obj.label == 'Credit Card Envelope')?.id
        }

        let res1 = await dispatch(CreateExpense({
            expenseNameToAdd: accountData?.accountTitle,
            selectedEnvelopeId,
            accountId,
            suppressToast: true,
        }))

        const { data: expenseData, status: expenseStatus } = res1.payload

        if ((expenseStatus >= 200 && expenseStatus < 300) || (expenseStatus >= 400 && status < 500)) {

            const { id, envelop_id } = expenseData?.data || {}
            let postGoalData

            if (selectedOption == 1) {

                let byDateValue = moment(targetMonth).endOf('month').format('DD/MM/YYYY')
                postGoalData = {
                    totalAmount: accountData?.accountBalance,
                    durationType: 'by_date',
                    byDateValue,
                    expenseId: id,
                    selectedMonth: moment(new Date()).format('YYYY-MM-DD'),
                }

            } else if (selectedOption == 2) {

                let months = Math.round((accountData?.accountBalance / targetAmount)) - 1 //* substracting 1 since currwnt month is also counted

                let byDateValue = moment(new Date()).add(months, 'months').endOf('month').format('DD/MM/YYYY')

                postGoalData = {
                    totalAmount: accountData?.accountBalance,
                    durationType: 'by_date',
                    byDateValue,
                    expenseId: id,
                    selectedMonth: moment(new Date()).format('YYYY-MM-DD'),
                }

            } else if (selectedOption == 3) {

                let byDateValue = moment(new Date()).endOf('month').format('DD/MM/YYYY')

                postGoalData = {
                    totalAmount: accountData?.accountBalance,
                    durationType: 'by_date',
                    byDateValue,
                    expenseId: id,
                    selectedMonth: moment(new Date()).format('YYYY-MM-DD'),
                }
            }

            if (selectedOption != 4)
                await dispatch(PostGoal({ ...postGoalData, suppressToast: true }))

        }

        dispatch(ChangeLoading(false))
        dispatch(ChangeCreditCardAccountModal(false))
        reset({
            accountType: null,
            accountTitle: '',
            accountBalance: '',
        })
    })

    return (
        <Modal
            isOpen={creditCardAccountModal}
            toggle={() => dispatch(ChangeCreditCardAccountModal(false))}
            className="modal-md"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeCreditCardAccountModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="title title-up" style={{ marginTop: "1rem" }}>
                    Handle card's balance
                </h5>
            </div>
            <div className="modal-body">
                <Label md="3" className="custom-top-label">I want to:</Label>
                <div className="form-check-radio custom-radio-div">
                    <Label check>
                        <Input
                            onChange={(event) => setSelectedOption(event.target.value)}
                            checked={selectedOption == "1"}
                            defaultValue={1}
                            type="radio"
                            selectedOption="1"
                        />
                        Pay off balance by a specific date.
                        <span className="form-check-sign" />
                    </Label>
                </div>
                <div className="form-check-radio custom-radio-div">
                    <Label check>
                        <Input
                            onChange={(event) => setSelectedOption(event.target.value)}
                            checked={selectedOption == "2"}
                            defaultValue={2}
                            type="radio"
                            selectedOption="2"
                        />
                        Pay a specific amount every month until balance is paid off.
                        <span className="form-check-sign" />
                    </Label>
                </div>
                <div className="form-check-radio custom-radio-div">
                    <Label check>
                        <Input
                            onChange={(event) => setSelectedOption(event.target.value)}
                            checked={selectedOption == "3"}
                            defaultValue={3}
                            type="radio"
                            selectedOption="3"
                        />
                        Cover my entire balance in current month.
                        <span className="form-check-sign" />
                    </Label>
                </div>
                <div className="form-check-radio custom-radio-div">
                    <Label check>
                        <Input
                            onChange={(event) => setSelectedOption(event.target.value)}
                            checked={selectedOption == "4"}
                            defaultValue={4}
                            type="radio"
                            selectedOption="4"
                        />
                        Skip this for now.
                        <span className="form-check-sign" />
                    </Label>
                </div>
                {selectedOption == "1" &&
                    <>
                        <label htmlFor="">Select target month</label>
                        <FormGroup>
                            <ReactDatetime
                                onChange={(value) => setTargetMonth(value)}
                                value={targetMonth}
                                placeholder="Select target month"
                                inputProps={{
                                    className: "form-control",
                                    placeholder: `Select target month`,
                                    readOnly: true,
                                }}
                                timeFormat={false}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                closeOnScroll={true}
                            />
                        </FormGroup>
                    </>
                }
                {selectedOption == "2" &&
                    <>
                        <label htmlFor="">Select target amount</label>
                        <FormGroup>
                            <Input
                                value={targetAmount}
                                onChange={(e) => setTargetAmount(e.target.value)}
                                type="number"
                                placeholder="Select target amount"
                            />
                        </FormGroup>
                    </>
                }
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link btn-link-cancel"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeCreditCardAccountModal(false))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        onClick={handleDone}
                        className="btn-link "
                        color="info"
                        type="button"
                    >
                        Done
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CreditCardAccountModal
