import { createSlice, createAsyncThunk, createEntityAdapter, current } from '@reduxjs/toolkit';
import { apiInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const GetAllGoals = createAsyncThunk('myGoals/GetAllGoals', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.get(`/api/goals/${selectedBudgetId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PostGoal = createAsyncThunk('myGoals/PostGoal', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { title, goalType, amount, requiredBy, } = params
        let date = requiredBy.split('/')

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.post('/api/goals/store', {
            'title': title,
            'budget_id': selectedBudgetId,
            'goal_type': goalType.value,
            'amount': amount,
            'required_by': `${date[2]}-${date[1]}-${date[0]}`,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PutGoal = createAsyncThunk('myGoals/PutGoal', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { title, goalType, amount, requiredBy, id } = params

        let date
        if (requiredBy.includes('/')) {
            date = requiredBy.split("/")
            date = `${date[2]}-${date[1]}-${date[0]}`
        }
        else {
            date = requiredBy.split(" ")[0].split('-')
            date = `${date[0]}-${date[1]}-${date[2]}`
        }

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.post(`/api/update/goal/${id}`, {
            'title': title,
            'budget_id': selectedBudgetId,
            'goal_type': goalType.value,
            'amount': amount,
            'required_by': date,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status, id }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const myGoalsSlice = createSlice({
    name: 'myGoals',
    initialState: {
        formData: {
            id: 0,
            title: null,
            goalType: null,
            amount: 0,
            requiredBy: null,
        },

        addGoalModal: false,

        myGoals: [],
    },
    reducers: {
        ChangeFormData: (state, action) => {
            const { id, title, goal_type, amount, required_by, } = action.payload
            state.formData = {
                id,
                title,
                goalType: goal_type,
                amount,
                requiredBy: required_by,
            }
        },
        ChangeAddGoalModal: (state, action) => {
            state.addGoalModal = action.payload
        },

    },
    extraReducers: {
        ['shared/AllocateFundsToGoal/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    envelop_id,
                    envelop_sub_category_name,
                    id,
                    spent,
                    total_budget,
                    user_id,
                    updated_at,
                } = data?.data || {}
                let myGoals = []
                current(state.myGoals).map((item) => {
                    if (item.id == id) {
                        myGoals.push({
                            ...item,
                            total_budget,
                            updated_at,
                        })
                    } else {
                        myGoals.push(item)
                    }
                })
                state.myGoals = myGoals
                // toast(data?.message)
            }
        },
        ['myGoals/PutGoal/fulfilled']: (state, action) => {
            const { data, status, id } = action.payload || {}
            if (status >= 200 && status < 300) {
                let myGoals = []
                current(state.myGoals).map((item) => {
                    if (item.id == id)
                        myGoals.push(data?.data)
                    else
                        myGoals.push(item)
                })
                state.myGoals = myGoals
                state.addGoalModal = false
                toast(data?.message)
            }
        },
        ['myGoals/PostGoal/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.myGoals.push(data?.data)
                state.addGoalModal = false
                toast(data?.message)
            }
        },
        ['myGoals/GetAllGoals/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.myGoals = data?.data
            }
        },
    },
});

export const {
    ChangeAddGoalModal,
    ChangeFormData,
} = myGoalsSlice.actions;

export default myGoalsSlice.reducer;