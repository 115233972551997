
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Row
} from "reactstrap";
import './Login.css';
import './OTP.css';
import { ResendVerificationCode, VerifyCode } from "./Store/authSlice";

function OTP(props) {

    const defaultValues = {
        email: "",
        password: "",
    }

    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const [code, setCode] = React.useState('');

    React.useEffect(() => {
    })

    return (
        <div className="login-page">
            <Container>
                <Row style={{ justifyContent: "center" }}>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <Form action="" className="form" method="">
                            <Card className="card-login">
                                <CardHeader>
                                    <CardHeader className="text-center">
                                        <h3 className="header text-center">Verify OTP</h3>
                                        <p>Please check your email to get OTP!</p>
                                    </CardHeader>
                                </CardHeader>
                                <CardBody className="otp-card-body">
                                    <ReactCodeInput
                                        fields={5}
                                        onChange={(code) => setCode(code)}
                                    />
                                </CardBody>
                                <div className="text-center">
                                    <Button
                                        onClick={() => dispatch(VerifyCode(code))}
                                        color="primary"
                                    >
                                        {"Verify"}
                                    </Button>
                                    <p>
                                        {"No recieved the verification code? "}
                                        <span
                                            onClick={() => dispatch(ResendVerificationCode())}
                                            className="link-text"
                                        >
                                            {"Resend"}
                                        </span>
                                    </p>
                                </div>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className="full-page-background"
                style={{
                    backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
                }}
            />
        </div>
    );
}

export default OTP;
