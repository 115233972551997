
import { emailRegex } from "../../Config/Constants";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from "reactstrap";
import './Login.css';
import { SignIn } from "./Store/authSlice";


function Login(props) {

  const defaultValues = {
    email: "",
    password: "",
  }

  const dispatch = useDispatch()
  const history = useHistory()

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const handleLogin = handleSubmit(async (data) => {
    let action = await dispatch(SignIn(data))

    const { data: data1, status } = action.payload || {}

    if (status >= 200 && status < 300)
      if (!data1?.data?.user?.is_email_verified)
        history.push("/auth/otp")
  })

  React.useEffect(() => {
  })

  return (
    <div className="login-page">
      <Container>
        <Row style={{ justifyContent: "center" }}>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader className="text-center">
                    <h3 className="header text-center">Login</h3>
                    <p>Don't have an account ?  </p>
                    <Link to="/auth/sign-up">Sign Up today !</Link>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup className="custom-input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: emailRegex,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          placeholder="Email / Username"
                          type="email"
                          autoComplete="off"
                        />
                      )}
                      name="email"
                    />
                  </InputGroup>
                  {errors.email &&
                    <div className="text text-danger error-text">Enter valid email address</div>
                  }
                  <div style={{ height: 10 }} />
                  <InputGroup className="custom-input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        minLength: 8
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                        />
                      )}
                      name="password"
                    />
                  </InputGroup>
                  {errors.password &&
                    <div className="text text-danger error-text">Enter password with min 8 chars</div>
                  }
                  <div style={{ height: 10 }} />
                  <div className="d-flex mt-4 justify-content-between">
                    <FormGroup className="text-center">
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Keep me Logged In
                        </Label>
                      </FormGroup>
                    </FormGroup>
                    <FormGroup className="text-center">
                      <Link>   Forgot Password ?</Link>
                    </FormGroup>
                  </div>

                </CardBody>
                <div className="text-center">
                  <Button
                    onClick={handleLogin}
                    color="primary"
                  >
                    {"Login"}
                  </Button>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
        }}
      />
    </div>
  );
}

export default Login;
