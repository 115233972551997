
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
    Button,
    Card, CardBody,
    CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row
} from "reactstrap";
import { GetAllDataOfBudget } from "../Shared/Store/sharedSlice";
import { ChangeFormData, GetBudgetbyId, PostBudget, PutBudget } from "./Store/newBudgetSlice";

function NewBudget(props) {


    const defaultValues = {
        numberFormat: null,
        bugetName: '',
        currency: null,
        currencyPlacement: null,
        dateFormat: null,
    }

    const { budgetId } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const { currencies, numberFormats, dateFormats, formData } = useSelector(state => state.newBudget)
    const { selectedBudgetId } = useSelector(state => state.shared)

    useEffect(() => {
        if (budgetId != 0) {
            console.log("FETCH HERE")
            dispatch(GetBudgetbyId({ budgetId }))
        } else {
            dispatch(ChangeFormData({
                numberFormat: null,
                bugetName: '',
                currency: null,
                currencyPlacement: null,
                dateFormat: null,
            }))
        }
    }, [budgetId])
    useEffect(() => {
        console.log(formData)
        const { budget_name,
            currency_id,
            currency_symbol_placement,
            date_format_id,
            id,
            number_format_id,
            user_id,
        } = formData || {}
        reset({
            numberFormat: numberFormats.find(obj => obj.value == number_format_id),
            bugetName: budget_name,
            currency: currencies.find(obj => obj.value == currency_id),
            currencyPlacement: currency_symbol_placement,
            dateFormat: dateFormats.find(obj => obj.value == date_format_id),
        })
    }, [formData])

    return (
        <>
            <div className="content">
                <Row style={{ justifyContent: "center" }}>
                    <Col md="6" className="mr-auto ml-auto" >
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{budgetId == 0 ? "New Budget" : "Edit Budget"}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <Row>
                                        <Label md="3">Budget Name</Label>
                                        <Col md="9">
                                            <FormGroup>
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Input
                                                            value={value}
                                                            defaultValue={defaultValues.value}
                                                            onChange={(event) => onChange(event.target.value)}
                                                            placeholder=""
                                                            type="text"
                                                        />
                                                    )}
                                                    name="bugetName"
                                                />
                                                {errors.bugetName &&
                                                    <div className="text text-danger error-text">Enter budget name</div>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label md="3">Currency</Label>
                                        <Col md="9">
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={value}
                                                        onChange={(value) => onChange(value)}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Select Your Currency",
                                                                isDisabled: true,
                                                            },
                                                            ...currencies
                                                        ]}
                                                        placeholder="Select..."
                                                    />
                                                )}
                                                name="currency"
                                            />
                                            {errors.currency &&
                                                <div className="text text-danger error-text">Select a currency</div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label md="3">Number Format</Label>
                                        <Col md="9">
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={value}
                                                        onChange={(value) => onChange(value)}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Preferred Number Format",
                                                                isDisabled: true,
                                                            },
                                                            ...numberFormats
                                                        ]}
                                                        placeholder="Select..."
                                                    />
                                                )}
                                                name="numberFormat"
                                            />
                                            {errors.numberFormat &&
                                                <div className="text text-danger error-text">Select a number format</div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label md="3">Currency Placement</Label>
                                        <Col md="9">
                                            <div className="form-check-radio">
                                                <Label check>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                onChange={(event) => onChange(event.target.value)}
                                                                checked={value == "1"}
                                                                defaultValue={1}
                                                                id="exampleRadios11"
                                                                name="currencyPlacement"
                                                                type="radio"
                                                                value="1"
                                                            />
                                                        )}
                                                        name="currencyPlacement"
                                                    />
                                                    Before Amount($123,456)
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                            <div className="form-check-radio">
                                                <Label check>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                onChange={(event) => onChange(event.target.value)}
                                                                checked={value == "2"}
                                                                defaultValue={2}
                                                                id="exampleRadios12"
                                                                name="currencyPlacement"
                                                                type="radio"
                                                                value="2"
                                                            />
                                                        )}
                                                        name="currencyPlacement"
                                                    />
                                                    After Amount(123,456$)
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                            <div className="form-check-radio" style={{ marginBottom: 0 }}>
                                                <Label check>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                onChange={(event) => onChange(event.target.value)}
                                                                checked={value == "3"}
                                                                defaultValue={3}
                                                                id="exampleRadios12"
                                                                name="currencyPlacement"
                                                                type="radio"
                                                                value="3"
                                                            />
                                                        )}
                                                        name="currencyPlacement"
                                                    />
                                                    No Symbol(123,456)
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                            {errors.currencyPlacement &&
                                                <div className="text text-danger error-text">Select a currenct placement</div>
                                            }
                                            <div style={{ height: 10 }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label md="3">Date Format</Label>
                                        <Col md="9">
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="singleSelect"
                                                        value={value}
                                                        onChange={(value) => onChange(value)}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Preferred Date Format",
                                                                isDisabled: true,
                                                            },
                                                            ...dateFormats
                                                        ]}
                                                        placeholder="Select..."
                                                    />
                                                )}
                                                name="dateFormat"
                                            />
                                            {errors.dateFormat &&
                                                <div className="text text-danger error-text">Select a date format</div>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col md="4" />
                                    <Col md="8">
                                        {budgetId == 0 ?
                                            <Button
                                                onClick={handleSubmit(async (data) => {
                                                    reset({
                                                        numberFormat: null,
                                                        bugetName: '',
                                                        currency: null,
                                                        // currencyPlacement: null,
                                                        dateFormat: null,
                                                    })
                                                    let res = await dispatch(PostBudget(data))
                                                    if (res.payload.status >= 200 && res.payload.status < 300) {
                                                        await dispatch(GetAllDataOfBudget({ selectedBudgetId }))
                                                        history.push('/admin/all-budgets')
                                                    }
                                                })}
                                                className=""
                                                color="info"
                                                type="submit"
                                            >
                                                Create Budget
                                            </Button>
                                            :
                                            <Button
                                                onClick={handleSubmit(async (data) => {
                                                    console.log("DATA")
                                                    console.log(data)
                                                    await dispatch(PutBudget(data))
                                                })}
                                                className=""
                                                color="info"
                                                type="submit"
                                            >
                                                Edit Budget
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default NewBudget;
