
import { GetAmount } from "App/Config/Helper";
import moment from "moment";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Popover,
    PopoverBody,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import { ChangeAddTransactionModal, ChangeEditTransactionModal, ChangeFormData } from "../Accounts/Store/accountsSlice";
import AddTransactionModal from "../Shared/Components/AddTransactionModal";
import RowForm from "../Shared/Components/RowForm";
import { GetTransactionsByAccountId, GetTransactionsByBudgetId } from "./Store/accountsSlice";

function AllAccounts(props) {

    const history = useHistory()
    const dispatch = useDispatch()
    const { accountId } = useParams()

    const {
        transactions,
        formData,
        submitButtonLoading,
        unClearedBalance,
        clearedBalance,
    } = useSelector(state => state.accounts)
    const {
        accountPayeeList,
        savedPayeeList,
        accountsList,
        selectedTransactionId,
        selectEnvelopeMortgageGoalList,
    } = useSelector(state => state.shared)

    const [filterPopover, setFilterPopover] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    function RenderTransactions() {

        let transactionsToRender = transactions.filter(obj => {

            let transactionDate
            if (obj?.transaction_date) {
                transactionDate = obj?.transaction_date.split("-")
                transactionDate = new Date(transactionDate[0], transactionDate[1] - 1, transactionDate[2])
            } else {
                transactionDate = null
            }

            let fromDateConverted
            if (fromDate) {
                fromDateConverted = fromDate.split('/')
                fromDateConverted = new Date(fromDateConverted[1], fromDateConverted[0] - 1, 1)
            } else {
                fromDateConverted = null
            }

            let toDateConverted
            if (toDate) {
                toDateConverted = toDate.split('/')
                toDateConverted = new Date(toDateConverted[1], toDateConverted[0] - 1, 30)
            } else {
                toDateConverted = null
            }

            const {
                accountName,
                payeeAccountName,
                payeeName,
                expenseName,
                goalName,
                mortgageName,
                memo,
            } = obj
            if (
                (
                    accountName?.includes(searchText) ||
                    payeeAccountName?.includes(searchText) ||
                    payeeName?.includes(searchText) ||
                    expenseName?.includes(searchText) ||
                    goalName?.includes(searchText) ||
                    mortgageName?.includes(searchText) ||
                    memo?.includes(searchText)
                ) &&
                (
                    fromDateConverted <= transactionDate &&
                    transactionDate <= toDateConverted ||
                    fromDateConverted == null ||
                    toDateConverted == null
                )
            )
                return true
            return false
        })

        return transactionsToRender?.map((item) => {
            return (
                <RowForm
                    rowData={item}
                />
            )
        })
    }

    const handleEditTransaction = () => {
        if (formData?.envelopeMortgageGoal?.parentId == -1)
            toast("You cannot edit mortgage transactions!")
        else {

            const selectedTransaction = transactions.find(obj => obj.id == selectedTransactionId)

            console.log("selectedTransaction===>", selectedTransaction)

            const {
                id,
                account_id,
                transaction_date,
                memo,
                out_flow_amount,
                in_flow_amount,
                is_envelop_or_mortgage,
                envelop_sub_cat_id,
                mortgage_id,
                goal_id,
                payee_account_id,
                payee_id,
                envelop_id,
            } = selectedTransaction

            let payeeList = []
            payeeList.push({
                id: -2,
                label: "Other Accounts",
                options: accountPayeeList,
            })
            payeeList.push({
                id: -3,
                label: "Saved Payees",
                options: savedPayeeList,
            })

            let payee = null
            if (is_envelop_or_mortgage == 4) {
                payee = payeeList.find(obj => obj.id == -2)?.options
                if (payee)
                    payee = payee.find(obj => obj.value == payee_account_id)
            } else {
                payee = payeeList.find(obj => obj.id == -3)?.options
                if (payee)
                    payee = payee.find(obj => obj.value == payee_id)
            }

            let envelop = null
            if (is_envelop_or_mortgage == 1) {
                envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == envelop_id)?.options
                if (envelop)
                    envelop = envelop.find(obj => obj.value == envelop_sub_cat_id)
            } else if (is_envelop_or_mortgage == 3) {
                envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == 0)?.options
                if (envelop)
                    envelop = envelop.find(obj => obj.value == goal_id)
            } else if (is_envelop_or_mortgage == 2) {
                envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == -1)?.options
                if (envelop)
                    envelop = envelop.find(obj => obj.value == mortgage_id)
            }

            dispatch(ChangeFormData({
                id,
                account: accountsList.find(obj => obj.value == account_id),
                transactionDate: moment(transaction_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                payee,
                envelopeMortgageGoal: envelop,
                memo,
                outflow: out_flow_amount,
                inflow: in_flow_amount,
            }))
            dispatch(ChangeEditTransactionModal(true))
        }
    }

    React.useEffect(() => {
        if (accountId != 0)
            dispatch(GetTransactionsByAccountId(accountId))
        else
            dispatch(GetTransactionsByBudgetId())
    }, [accountId])

    return (
        <>
            <div className="content">
                <div className="d-flex bg-light justify-content-around pt-4 align-items-center">
                    {accountId != 0 ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <h6>{accountsList.find(obj => obj.value == accountId)?.label}</h6>
                            <i
                                onClick={() => history.push(`/admin/unlinked-account/${accountId}`)}
                                style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                }}
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                            />
                        </div>
                        :
                        <h6>All Accounts</h6>
                    }
                    <p className="text-success font-weight-bold">
                        {GetAmount(clearedBalance)}
                        <br />
                        <span className="text-muted font-weight-normal">Cleared Balance</span>
                    </p>
                    <p className="text-muted">
                        <i className="fas fa-plus font-weight-bold" />
                    </p>
                    <p className="text-success  font-weight-bold">
                        {GetAmount(unClearedBalance)}
                        <br />
                        <span className="text-muted font-weight-normal">Uncleared Balance</span>
                    </p>
                    <p>
                        <i class="fas fa-equals font-weight-bold" />
                    </p>
                    <p className="text-success  font-weight-bold">
                        {GetAmount(clearedBalance + unClearedBalance)}
                        <br />
                        <span className="text-muted font-weight-normal">Working Balance</span>
                    </p>
                </div>

                <div className="bg-white p-3 d-flex justify-content-between align-items-center" style={{ marginTop: "1.2rem" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <Button color="primary" onClick={() => dispatch(ChangeAddTransactionModal(true))}>
                            <i className="fas fa-plus"></i>{"Add Transaction"}</Button>
                        {transactions?.length != 0 &&
                            <Button
                                disabled={!selectedTransactionId}
                                onClick={handleEditTransaction}
                                color="primary"
                            >
                                <i className="fas fa-edit" />
                                {"Edit"}
                            </Button>
                        }
                        <Button color="primary"  >
                            <i class="fas fa-file-upload" />
                            {"Import"}
                        </Button>
                        {transactions?.length != 0 &&
                            <>
                                <Button color="primary" id="tooltip848814788">
                                    <i class="fas fa-undo" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="tooltip848814788"
                                >
                                    Undo
                                </UncontrolledTooltip>
                                <Button color="primary" id="tooltip848814777" >
                                    <i class="fas fa-redo" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="tooltip848814777"
                                >
                                    Redo
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            onClick={() => setFilterPopover(true)}
                            href="javascript:void(0)"
                            className="mx-3"
                            id="filterPopover"
                        >
                            {"Filter"}
                        </a>
                        <div class="input-group mb-3 border rounded border-seondary" style={{ marginTop: "1.2rem" }}>
                            <span class="input-group-text bg-transparent border-0" id="basic-addon1"><i className="fas fa-search"></i></span>
                            <input
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                class="form-control border-0"
                                placeholder="Search All Accounts "
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />
                        </div>
                    </div>
                </div>
                <Row className="mt-3">

                    <Col md="12">
                        <Card className="h-100 w-100">


                            <CardBody>
                                {transactions ?
                                    <>
                                        {transactions?.length != 0 ?
                                            <Table className="table-bordered account-table" style={{ fontSize: '12px' }}>
                                                <thead className="text-dark bg-light" >
                                                    <tr >
                                                        <th className="border">
                                                        </th>
                                                        <th className="border">
                                                            <i class="fa fa-bookmark" style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }} ></i>
                                                        </th>
                                                        <th className="border">Account</th>
                                                        <th className="border px-5 ">Date</th>
                                                        <th className="border" >Payee</th>
                                                        <th className="border" >Envelope</th>
                                                        <th className="border" >Memo</th>
                                                        <th className="border" >Outflow</th>
                                                        <th className="border" >Inflow</th>
                                                        <th className="border"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {RenderTransactions()}
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='no-data-wrapper'>
                                                <span className='no-data'>Transactions you do will show up here!</span>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className='no-data-wrapper'>
                                        <span className='no-data'>You are not authorized to get transactions against this account!</span>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>

                </Row>


                <AddTransactionModal />


                <Popover
                    placement="bottom"
                    isOpen={filterPopover}
                    target="filterPopover"
                    toggle={() => setFilterPopover(false)}
                    trigger="legacy"
                    popperClassName="big-width"
                >
                    <PopoverBody className="w-100">
                        {/* <div className="d-flex justify-content-between w-100 px-3 py-2 border border-left-0 border-right-0">
                            <a href="javascript:void(0)">This Month</a>
                            <a href="javascript:void(0)">Latest 3 Months</a>
                            <a href="javascript:void(0)">This Year</a>
                            <a href="javascript:void(0)">Last Year</a>
                            <a href="javascript:void(0)">All Dates</a>
                        </div> */}
                        <div className="d-flex justify-content-between w-100 px-3 py-2">
                            <FormGroup className="mr-3">
                                <label htmlFor="">From</label>
                                <ReactDatetime
                                    onChange={(value) => setFromDate(moment(value.toDate()).format("MM/yyyy"))}
                                    value={fromDate}
                                    inputProps={{
                                        className: "form-control w-150",
                                        placeholder: "Select to filter...",
                                        readOnly: true,
                                    }}
                                    timeFormat={false}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    yearItemNumber={9}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="">To</label>
                                <ReactDatetime
                                    onChange={(value) => setToDate(moment(value.toDate()).format("MM/yyyy"))}
                                    value={toDate}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Select to filter...",
                                        readOnly: true,
                                    }}
                                    timeFormat={false}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    yearItemNumber={9}
                                />
                            </FormGroup>
                        </div>
                        <div className="text-right">
                            <button
                                disabled={submitButtonLoading}
                                className="btn-btn-sm btn-danger btn-round custom-btn"
                                onClick={() => setFilterPopover(false)}
                            >
                                Close
                            </button>
                            <button
                                disabled={submitButtonLoading}
                                className="btn-btn-sm btn-info btn-round custom-btn"
                                onClick={() => {
                                    setFromDate(null)
                                    setToDate(null)
                                }}
                            >
                                {submitButtonLoading ?
                                    <ScaleLoader
                                        color={"#fff"}
                                        loading={true}
                                        size={50}
                                        height={10}
                                        width={2}
                                    />
                                    :
                                    "Clear"
                                }
                            </button>
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        </>
    )
}

export default AllAccounts
