
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { ChangeMode } from "./Store/displaySettingsSlice";

function DisplaySettings() {

    const dispatch = useDispatch()

    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Row>
                            <Col lg="4">
                                <Card
                                    onClick={() => dispatch(ChangeMode({ mode: 2 }))}
                                    className="card-pricing  h-100 bg-dark"
                                    style={{ cursor: 'pointer' }}
                                >

                                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                        <div className="card-icon icon-primary ">
                                            <i className="fas fa-donate" />
                                        </div>
                                        <CardTitle tag="h6" className="text-success">Dark</CardTitle>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card
                                    onClick={() => dispatch(ChangeMode({ mode: 1 }))}
                                    className="card-pricing  h-100 bg-secondary"
                                    style={{ cursor: 'pointer' }}
                                >


                                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                        <div className="card-icon icon-info ">
                                            <i className="fas fa-donate" />
                                        </div>
                                        <CardTitle tag="h6" className="text-dark">Gray</CardTitle>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="4">
                                <Card
                                    onClick={() => dispatch(ChangeMode({ mode: 0 }))}
                                    className="card-pricing border border-dark h-100"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                        <div className="card-icon icon-primary ">
                                            <i className="fas fa-donate" />
                                        </div>
                                        <CardTitle tag="h6" className="text-success">Light</CardTitle>

                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default DisplaySettings;
