import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { plaidSandbox } from 'App/Config/apiKey';
import { plaidClientId } from 'App/Config/apiKey';
import { toast } from 'react-toastify';
import { apiInstance, apiInstancePlaid, apiNoLoaderInstance } from '../../../Config/AxiosInstances';

export const PostUnLinkedAccount = createAsyncThunk('addAccount/PostUnLinkedAccount', async (params, { dispatch, getState }) => {
    try {

        const { accountType, accountTitle, accountBalance, plaidAccountId, isPlaidAccount, suppressToast } = params || {}
        const { selectedBudgetId } = getState().userData

        let instance
        if (suppressToast)
            instance = apiNoLoaderInstance
        else
            instance = apiInstance

        let currentAmount
        if (accountType.label === "Line Of Credit" || accountType.label === "Credit Card")
            currentAmount = -accountBalance
        else
            currentAmount = accountBalance

        let result = await instance.post('/api/accounts', {
            'account_title': accountTitle,
            'current_amount': currentAmount,
            'account_type_id': accountType.value,
            'budget_id': selectedBudgetId,
            'is_plaid_account': isPlaidAccount ? true : false,
            'plaid_account_id': plaidAccountId ? plaidAccountId : null,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const PutUnLinkedAccount = createAsyncThunk('addAccount/PutUnLinkedAccount', async (params, { dispatch, getState }) => {
    try {

        const { accountType, accountTitle, accountBalance, accountId } = params
        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.put(`/api/accounts/${accountId}`, {
            'account_title': accountTitle,
            'current_amount': accountBalance,
            'account_type_id': accountType.value,
            'budget_id': selectedBudgetId,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const DeleteUnLinkedAccount = createAsyncThunk('addAccount/DeleteUnLinkedAccount', async (params, { dispatch, getState }) => {
    try {
        const accountId = params

        let result = await apiInstance.delete(`/api/accounts/${accountId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status, accountId }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetAccountbyId = createAsyncThunk('addAccount/GetAccountbyId', async (params, { dispatch, getState }) => {
    try {
        const { accountId } = params

        let result = await apiInstance.get(`/api/accounts/${accountId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const CreateLinkToken = createAsyncThunk('addAccount/CreateLinkToken', async (params, { dispatch, getState }) => {

    const { id, first_name, last_name } = getState().userData?.userData?.user

    let result = await apiInstance.post('/api/link/token/create', {
        client_name: `${first_name} ${last_name}`,
        client_user_id: `${id}`,
    }).then(function (response) {
        return response
    })

    const { data, status } = result
    return { data, status }
})
export const GetLinkedAccounts = createAsyncThunk('addAccount/GetLinkedAccounts', async (params, { dispatch, getState }) => {

    const { publicToken } = params

    let result = await apiNoLoaderInstance.post('/api/get/account', {
        "public_token": publicToken,
    }).then(function (response) {
        return response
    })

    const { data, status } = result || {}
    const { accounts } = data || {}

    let accountTypes = getState().addAccount?.accountTypes

    accounts.map((item) => {
        const { balances: { current }, name, subtype, account_id } = item

        let accountType = accountTypes.find((item) => item.label.toLowerCase() === subtype.toLowerCase())

        if (accountType)
            dispatch(PostUnLinkedAccount({
                accountType,
                accountTitle: name,
                accountBalance: current,
                plaidAccountId: account_id,
                isPlaidAccount: true,
            }))
    })

    return { data, status }

})

const addAccountSlice = createSlice({
    name: 'addAccount',
    initialState: {
        formData: null,
        accountTypes: [],
        linkedAccounts: [],
        linkToken: null,
        creditCardAccountModal: false,
    },
    reducers: {
        ChangeFormData: (state, action) => {
            state.formData = action.payload
        },
        ChangeCreditCardAccountModal: (state, action) => {
            state.creditCardAccountModal = action.payload
        }
    },
    extraReducers: {
        ['addAccount/CreateLinkToken/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { expiration, link_token, request_id } = data || {}

                state.linkToken = link_token
            }
        },
        ['addAccount/GetLinkedAccounts/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { accounts } = data
                state.linkedAccounts = accounts
                toast("Accounts are linked successfully")
            }
        },
        ['shared/GetAllDataOfBudget/fulfilled']: (state, action) => {
            const { accountTypes } = action.payload || {}
            const { data = null, status } = accountTypes || {}
            if (status >= 200 && status < 300) {
                let accountTypes = []
                data.data.map((item) => {
                    const { accoutn_type_name, id, user_id } = item
                    accountTypes.push({ value: id, label: accoutn_type_name })
                })
                state.accountTypes = accountTypes
            }
        },
        ['addAccount/DeleteUnLinkedAccount/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                toast(data?.message)
            }
        },
        ['addAccount/GetAccountbyId/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                state.formData = data?.account
            }
        },
        ['addAccount/PutUnLinkedAccount/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                toast(data?.message)
            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
        },
        ['auth/Logout/fulfilled']: (state, action) => {
            state.formData = null
        },
    },
});

export const {
    ChangeFormData,
    ChangeCreditCardAccountModal,
} = addAccountSlice.actions;

export default addAccountSlice.reducer;