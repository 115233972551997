
import { GetDate } from "App/Config/Helper";
import moment from "moment";
import React, { useState } from "react";
import {
    FcPlus
} from "react-icons/fc";
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card, CardBody, CardHeader, Col, FormGroup, Row
} from "reactstrap";
import { styles } from '../../../custom';
import { GetAmount, getOS } from "../../Config/Helper";
import FundsToAllotBtn from "../Shared/Components/FundsToAllotBtn";
import { ChangeCustomHeaerTitle } from "../Shared/Store/sharedSlice";
import AddGoalModal from './AddGoalModal';
import './MyGoals.css';
import { ChangeAddGoalModal, ChangeFormData, GetAllGoals } from "./Store/myGoalsSlice";

const MyGoals = (props) => {

    console.log("getOS()===>", getOS())

    const dispatch = useDispatch()

    const { myGoals } = useSelector(state => state.myGoals)

    const [goalType, setGoalType] = useState("-1")

    React.useEffect(() => {
        dispatch(GetAllGoals())
    }, [])

    React.useEffect(() => {
        dispatch(ChangeCustomHeaerTitle("My Goals & Objectives"))
        return () => {
            dispatch(ChangeCustomHeaerTitle(null))
        }
    }, [])

    function handleDropDownChange(e) {
        if (e.target.value == "-1")
            dispatch(ChangeCustomHeaerTitle("My Goals & Objectives"))
        else if (e.target.value == "0")
            dispatch(ChangeCustomHeaerTitle("My Goals"))
        else if (e.target.value == "1")
            dispatch(ChangeCustomHeaerTitle("My Objectives"))
        setGoalType(e.target.value)
    }

    const RenderMyGoals = () => {
        let goalsToRender
        if (goalType == "-1")
            goalsToRender = myGoals
        else
            goalsToRender = myGoals.filter(obj => obj.goal_type == goalType)
        return goalsToRender.map((item, key) => {
            const {
                id,
                user_id,
                budget_id,
                title,
                goal_type,
                amount,
                total_budget,
                spent,
                required_by,
                created_at,
                updated_at,
            } = item

            let progress = (total_budget / parseFloat(amount)) * 100
            progress = isNaN(progress) ? (total_budget > 0 ? 100 : 0) : progress

            let spent_ = spent ? spent : 0
            let totalBudget = total_budget ? total_budget : 0

            return (
                <Col key={id} lg="4" style={{ marginBottom: 10 }}>
                    <Card className="card-pricing all-budget-cards ">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: 1 }}>
                                <CardHeader >
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {goal_type == "1" ?
                                            <i style={{ color: "#EF8357", fontSize: 16 }} class="fas fa-balance-scale"></i>
                                            :
                                            <i style={{ color: "#239b37", fontSize: 16 }} class="fas fa-smile-beam"></i>
                                        }
                                        <p style={{ marginBottom: 0, marginLeft: 10, fontSize: 16 }} className="font-weight-bold">{title}</p>
                                    </div>
                                    <hr style={styles.smartHr} />
                                </CardHeader>
                                <CardBody style={{ marginBottom: 0 }}>
                                    <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex" }}>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex", }}>Amount</div>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex" }}>{GetAmount(amount)}</div>
                                    </div>
                                    <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex" }}>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex", }}>Budgeted</div>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex" }}>{GetAmount(totalBudget)}</div>
                                    </div>
                                    <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex" }}>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex", }}>Spent</div>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex" }}>{GetAmount(spent_)}</div>
                                    </div>
                                    <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex" }}>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex", }}>Required By</div>
                                        <div style={{ flex: 1, alignItems: "center", display: "flex" }}>{GetDate(required_by)}</div>
                                    </div>
                                    <div style={{ height: 30, width: '100%', flexDirection: "row", display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "flex", width: '100%', flexDirection: "row", backgroundColor: goal_type == "1" ? "#EF835755" : "#239b3755", }} >
                                            <div style={{ display: "flex", width: `${progress}%`, backgroundColor: goal_type == "1" ? "#EF8357" : "#239b37", height: 3 }} ></div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <i class="fas fa-redo"></i>
                                        <span style={{ marginLeft: 5 }}>
                                            {`Updated ${moment(updated_at).startOf('seconds').fromNow()}`}
                                        </span>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                dispatch(ChangeAddGoalModal(true))
                                dispatch(ChangeFormData(item))
                            }}
                            style={{ position: 'absolute', top: 10, right: 10 }}>
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </Card>
                </Col>
            )
        })
    }
    return (
        <>
            <div
                className="row bg-light text-dark align-middle align-items-center my-4 sticky-head"
                style={{ marginLeft: 2, marginRight: 2 }}
            >
                <div className="col-lg-3 col-md-3 align-middle">
                    <div className="">
                        <label htmlFor="" style={{ marginLeft: 5 }}>Select</label>
                        <FormGroup>
                            {getOS() == "Windows" ?
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    onChange={handleDropDownChange}
                                    style={{ fontFamily: "FontAwesome" }}
                                >
                                    <option style={{ fontFamily: 'FontAwesome' }} value="-1"> &#xf03a;&#160;All</option>
                                    <option style={{ fontFamily: 'FontAwesome' }} value="0"> &#xf118;&#160;Goals</option>
                                    <option style={{ fontSize: 13, fontFamily: 'FontAwesome' }} value="1">&#xf24e;&#160; Objectives</option>
                                </select>
                                :
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    onChange={handleDropDownChange}
                                    style={{ fontFamily: "FontAwesome" }}
                                >
                                    <option value="-1">All</option>
                                    <option value="0">Goals</option>
                                    <option value="1">Objectives</option>
                                </select>
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 text-center">
                    <FundsToAllotBtn />
                </div>
            </div>
            <div className="content custom-content">
                <Row>
                    <Col md="12">
                        <Row>
                            {RenderMyGoals()}
                            <Col lg="4" style={{ marginBottom: 10 }}>
                                <Card
                                    onClick={() => {
                                        console.log("CHANGING")
                                        dispatch(ChangeAddGoalModal(true))
                                        dispatch(ChangeFormData({
                                            id: 0,
                                            title: null,
                                            goal_type: null,
                                            amount: 0,
                                            required_by: null,
                                        }))
                                    }}
                                    className="card-pricing all-budget-cards ">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 1 }}>
                                            <CardHeader >
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <p style={{ marginBottom: 0, marginLeft: 10, fontSize: 16 }} className="font-weight-bold">Add New</p>
                                                </div>
                                                <hr style={{ marginBottom: 0, ...styles.smartHr }} />
                                            </CardHeader>
                                            <CardBody style={{ marginTop: 0 }} >
                                                <div style={{ height: 150, width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="card-icon icon-primary icon-flex">
                                                        <div className="budget-icon-wrapper">
                                                            <FcPlus className="budget-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr style={{ margin: 10, width: '100%', ...styles.smartHr }} />
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 15 }}>
                                                </div>
                                            </CardBody>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <AddGoalModal />
            </div >
        </>
    );
}

export default MyGoals