import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiNoLoaderInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';

export const ChangeMode = createAsyncThunk('displaySettings/ChangeMode', async (params, { dispatch, getState }) => {
    try {

        const { mode } = params

        let result = await apiNoLoaderInstance.post(`/api/change-mode`, {
            mode: mode.toString(),
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const displaySettingsSlice = createSlice({
    name: 'displaySettings',
    initialState: {
    },
    reducers: {
    },
    extraReducers: {
    },
});

export const {
    ChangeSidebarColor,
} = displaySettingsSlice.actions;

export default displaySettingsSlice.reducer;