import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { apiNoLoaderInstance } from 'App/Config/AxiosInstances';
import { apiInstance } from 'App/Config/AxiosInstances';

export const EditUser = createAsyncThunk('userProfile/EditUser', async (params, { dispatch, getState }) => {
    try {

        const { email, firstname, lastname, username } = params

        let result = await apiInstance.post('/api/edit-user', {
            'first_name': firstname,
            'last_name': lastname,
            'email': email,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const EditImage = createAsyncThunk('userProfile/EditImage', async (params, { dispatch, getState }) => {
    try {

        const file = params

        let formData = new FormData();
        formData.append('file', file);

        let result = await apiNoLoaderInstance.post('/api/edit-image', formData)
            .then(function (response) {
                return response
            }).catch(function (error) {
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
    },
    reducers: {
    },
    extraReducers: {
    },
});

export const {
} = userProfileSlice.actions;

export default userProfileSlice.reducer;