
import React from "react";
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from "react-redux";
import {
    Button
} from "reactstrap";
import { GetLinkedAccounts } from "./Stote/addAccountSlice";
import { toast } from "react-toastify";

function PlaidLinkButton(props) {

    const dispatch = useDispatch()

    const { linkToken } = useSelector(state => state.addAccount)
    const { selectedBudgetId } = useSelector(state => state.userData)

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            dispatch(GetLinkedAccounts({ publicToken: public_token }))
            console.info("public_token===>", public_token)
            console.info("metadata===>", metadata)
        },
        onExit: (err, metadata) => {
            console.info("onExit===>", err)
            console.info("metadata===>", metadata)
        },
        onEvent: (eventName, metadata) => {
            console.info("onEvent===>", eventName)
            console.info("metadata===>", metadata)
        }
    });

    React.useEffect(() => {
    }, [])

    return (
        <Button
            color="primary"
            onClick={() => {
                if (selectedBudgetId == 0)
                    toast("Please add/select a budget first")
                else
                    open()
            }}
            disabled={!ready}
        >
            Add Connection
        </Button>
    );
}

export default PlaidLinkButton;
