import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
import moment from 'moment';
import { apiInstance } from '../../../Config/AxiosInstances';
import { getFixedColorCode, getRandomColorCode } from '../../../Config/Helper';

export const GetReport = createAsyncThunk('reports/GetReport', async (params, { dispatch, getState }) => {
    try {

        const { envelopes, accounts, to, from } = params || {}
        const selectedBudgetId = getState().userData?.selectedBudgetId

        let formData = new FormData();

        formData.append('budget_id', selectedBudgetId);
        if (to)
            formData.append('to', moment(to, 'YYYY-MM-DD').format('YYYY-MM[-30]'))
        if (from)
            formData.append('from', moment(from, 'YYYY-MM-DD').format('YYYY-MM[-01]'))

        envelopes.map(item => formData.append('envelope_ids[]', item.id.toString()))
        accounts.map(item => formData.append('account_ids[]', item.id.toString()))

        let result = await apiInstance.post('/api/report/spent-chart', formData)
            .then(function (response) {
                return response
            }).catch(function (error) {
                return error.response
            })

        const { data, status } = result
        return { data, status, }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        envelopesData: {
            labels: [],
            datasets: [{
                label: 'All Envelopes',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1,
            }]
        },
        envelopesWithExpenseData: [],
        startDate: null,
        endDate: null,
        totalSpending: 0,
        averageSpending: 0,
    },
    reducers: {
    },
    extraReducers: {
        ['accounts/AddTransaction/fulfilled']: (state, action) => {

            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { transaction: { transaction_date } } = data || {}

                let transactionDate = moment(transaction_date, "YYYY-MM-DD")

                if (transactionDate.isBefore(state.startDate))
                    state.startDate = transactionDate
                if (transactionDate.isAfter(state.endDate))
                    state.endDate = transactionDate
            }
        },
        ['reports/GetReport/fulfilled']: (state, action) => {

            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let envlopesData = {
                    labels: [],
                    datasets: [{
                        label: 'All Envelopes',
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 1,
                    }]
                }
                let envelopesWithExpenseData = []
                let totalSpending = 0
                let averageSpending = 0

                data.data.map((item, key) => {
                    const { envelop_name, total, average } = item || {}
                    const randomColor = getFixedColorCode(key)
                    const absoluteTotal = Math.abs(total)
                    const absoluteAverage = Math.abs(average)

                    totalSpending += absoluteTotal
                    averageSpending += absoluteAverage

                    envlopesData.labels.push(envelop_name)
                    envlopesData.datasets[0].data.push(absoluteTotal)
                    envlopesData.datasets[0].backgroundColor.push(randomColor + '33')
                    envlopesData.datasets[0].borderColor.push(randomColor + 'FF')

                    let expenseData = {
                        labels: [],
                        datasets: [{
                            label: envelop_name,
                            data: [],
                            backgroundColor: [],
                            borderColor: [],
                            borderWidth: 1,
                        }]
                    }

                    item.sub_envelopes.map((subItem, subKey) => {
                        const { sub_envelope, spent } = subItem || {}
                        const randomColor = getFixedColorCode(subKey)
                        const absoluteSpent = Math.abs(spent)

                        expenseData.labels.push(sub_envelope)
                        expenseData.datasets[0].data.push(absoluteSpent)
                        expenseData.datasets[0].backgroundColor.push(randomColor + '33')
                        expenseData.datasets[0].borderColor.push(randomColor + 'FF')
                    })

                    envelopesWithExpenseData.push(expenseData)

                })

                state.envelopesData = envlopesData
                state.envelopesWithExpenseData = envelopesWithExpenseData
                state.totalSpending = totalSpending
                state.averageSpending = averageSpending
            }
        },
        ['shared/GetAllDataOfBudget/fulfilled']: (state, action) => {

            const { reportsDateFilter } = action.payload || {}
            if (reportsDateFilter?.status >= 200 && reportsDateFilter?.status < 300) {
                const { least_date, greatest_date } = reportsDateFilter?.data?.data || {}
                state.startDate = least_date
                state.endDate = greatest_date
            }
        },
    },
});

export const {
} = reportsSlice.actions;

export default reportsSlice.reducer;