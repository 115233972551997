import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiNoLoaderInstance } from '../../../Config/AxiosInstances';

export const GetDashboad = createAsyncThunk('dashboard/GetDashboad', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let [tiles, incomeChartData, expenseChartData] = await Promise.all([
            apiNoLoaderInstance.get(`/api/get-dashboard/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/get-income-chart/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/get-expense-chart/${selectedBudgetId}`).then(response => response).catch(error => error.response),
        ])

        return { tiles, incomeChartData, expenseChartData }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        totalBudget: 0,
        usedBudget: 0,
        myGoals: 0,
        myObligations: 0,
        upcommingExpenses: 0,
        myReports: 0, //todo: make this page dynamic
        totalLetters: 0, //todo: discuss
        trainingDisputes: 0, //todo: discuss
        myReminders: 0,

        weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        byWeekIncome: null,
        byWeekExpense: null,
    },
    reducers: {
    },
    extraReducers: {
        ['auth/Logout/fulfilled']: (state, action) => {
            state.byWeekExpense = null
            state.byWeekIncome = null
        },
        ['dashboard/GetDashboad/fulfilled']: (state, action) => {
            const { tiles, incomeChartData, expenseChartData } = action.payload || {}
            if (
                (tiles?.status >= 200 && tiles?.status < 300) && //todo: use separate checks later
                (incomeChartData?.status >= 200 && incomeChartData?.status < 300) &&
                (expenseChartData?.status >= 200 && expenseChartData?.status < 300)
            ) {
                //*tiles
                const { sumOfAccount, totalObligation, totalGoal, usedBudget, upcomingExpense, } = tiles?.data?.data || {}

                state.totalBudget = sumOfAccount
                state.usedBudget = usedBudget
                state.totalGoal = totalGoal
                state.myObligations = totalObligation
                state.upcommingExpenses = upcomingExpense
                // state.myReminders = {  //! not getting in API
                //     envGoals:,
                //     goals:,
                //     mortgages:,
                // }

                //*incomeChartData
                let byWeekIncome = [0, 0, 0, 0, 0, 0, 0]

                if (incomeChartData.data.data?.length > 0) {
                    incomeChartData.data.data.map((item) => {
                        const { sum, dayName } = item || {}
                        let index = state.weekDays.findIndex(obj => dayName?.includes(obj))
                        byWeekIncome[index] = sum
                    })
                    state.byWeekIncome = byWeekIncome
                }

                //*expenseChartData
                let byWeekExpense = [0, 0, 0, 0, 0, 0, 0]

                if (expenseChartData.data.data?.length > 0) {
                    expenseChartData.data.data.map((item) => {
                        const { sum, dayName } = item || {}
                        let index = state.weekDays.findIndex(obj => dayName?.includes(obj))
                        byWeekExpense[index] = sum
                    })
                    state.byWeekExpense = byWeekExpense
                }
            }
        },
    },
});

export const {
} = dashboardSlice.actions;

export default dashboardSlice.reducer;