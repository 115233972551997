//! COMPLEX CODE
import { GetDateFormat } from "App/Config/Helper";
import moment from "moment";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { Controller, useForm } from "react-hook-form";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import {
    FormGroup,
    Input
} from "reactstrap";
import { BookmarkTransaction, ChangeFormData, ClearTransaction, EditTransaction } from "../../Accounts/Store/accountsSlice";
import { ChangeSelectedTransactionId } from "../Store/sharedSlice";
import './RowForm.css';

function RowForm(props) {
    const {
        account_id,
        envelop_id,
        id,
        in_flow_amount,
        is_envelop_or_mortgage,
        envelop_sub_cat_id,
        memo,
        mortgage_id,
        out_flow_amount,
        transaction_date,
        transaction_status,
        user_id,
        bookmark,
        payee_id,
        payee_account_id,
        goal_id,
        isChecked,
        isCleared,
    } = props.rowData

    let transactionDate
    if (transaction_date) {
        transactionDate = transaction_date.split("-")
        transactionDate = `${transactionDate[2]}/${transactionDate[1]}/${transactionDate[0]}`
    } else {
        transactionDate = null
    }

    let editingDisabled = false
    if ((!payee_account_id && !envelop_sub_cat_id && !goal_id && !mortgage_id) || mortgage_id)
        editingDisabled = true

    const dispatch = useDispatch()

    const {
        selectEnvelopeMortgageGoalList,
        savedPayeeList,
        accountsList,
        selectedTransactionId,
        editRowFormLoading,
    } = useSelector(state => state.shared)

    const [payeeList, setPayeeList] = useState([])
    const [accountPayeeList, setAccountPayeeList] = useState(accountsList.filter(obj => obj.value != account_id))//* -- accounts Other than selected --
    const [localFormData, setLocalFormData] = useState(null)

    const defaultValues = {
        id,
        account: accountsList.find(obj => obj.value == account_id), //* -- selected account --
        transactionDate: null,
        payee: null,
        envelopeMortgageGoal: null,
        memo: null,
        outflow: null,
        inflow: null,
    }
    const { control, handleSubmit, reset, formState: { errors }, getValues, watch, setValue } = useForm({
        mode: 'onChange',
        defaultValues,
    });
    let watchEnvelopeMortgageGoal = watch('envelopeMortgageGoal')
    let watchPayee = watch('payee')
    let watchInflow = watch('inflow')
    let watchOutflow = watch('outflow')
    // console.log("errors===>", errors)

    // const onSubmit = (data) => { //! makes app too slow
    //     console.log("data===>", data)
    // }

    // React.useEffect(() => {
    //     const subscription = watch(handleSubmit(onSubmit));
    //     return () => subscription.unsubscribe();
    // }, [handleSubmit, watch]);

    React.useEffect(() => {
        let payeeListTemp = []
        payeeListTemp.push({
            id: -2,
            label: "Other Accounts",
            options: accountPayeeList,
        })
        payeeListTemp.push({
            id: -3,
            label: "Saved Payees",
            options: savedPayeeList,
        })
        setPayeeList(payeeListTemp)
        // console.log("===>[savedPayeeList, accountPayeeList]")
    }, [savedPayeeList, accountPayeeList])

    React.useEffect(() => {
        let payee = null
        if (is_envelop_or_mortgage == 4) {
            payee = payeeList.find(obj => obj.id == -2)?.options
            if (payee) {
                payee = payee.find(obj => obj.value == payee_account_id)
            }
        } else {
            payee = payeeList.find(obj => obj.id == -3)?.options
            if (payee) {
                payee = payee.find(obj => obj.value == payee_id)
            }
        }

        let envelop = null
        if (is_envelop_or_mortgage == 1) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == envelop_id)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == envelop_sub_cat_id)
        } else if (is_envelop_or_mortgage == 3) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == 0)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == goal_id)
        } else if (is_envelop_or_mortgage == 2) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == -1)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == mortgage_id)
        }

        reset({
            id,
            account: getValues().account,
            transactionDate: transactionDate,
            payee: payee,
            envelopeMortgageGoal: envelop,
            memo: memo,
            outflow: out_flow_amount,
            inflow: in_flow_amount,
        })
        // console.log("===>[accountsList, payeeList]")
    }, [accountsList, payeeList])

    React.useEffect(() => {

        let payee = null
        if (is_envelop_or_mortgage == 4) {
            payee = payeeList.find(obj => obj.id == -2)?.options
            if (payee) {
                payee = payee.find(obj => obj.value == payee_account_id)
            }
        } else {
            payee = payeeList.find(obj => obj.id == -3)?.options
            if (payee) {
                payee = payee.find(obj => obj.value == payee_id)
            }
        }

        let envelop = null
        if (is_envelop_or_mortgage == 1) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == envelop_id)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == envelop_sub_cat_id)
        } else if (is_envelop_or_mortgage == 3) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == 0)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == goal_id)
        } else if (is_envelop_or_mortgage == 2) {
            envelop = selectEnvelopeMortgageGoalList.find(obj => obj.id == -1)?.options
            if (envelop)
                envelop = envelop.find(obj => obj.value == mortgage_id)
        }

        let filteredAccountsList = accountsList.filter(obj => obj.value != account_id)
        setAccountPayeeList(filteredAccountsList)

        reset({
            id,
            account: accountsList.find(obj => obj.value == account_id),
            transactionDate: transactionDate,
            payee: payee,
            envelopeMortgageGoal: envelop,
            memo: memo,
            outflow: out_flow_amount,
            inflow: in_flow_amount,
        })
        // console.log("===>[props.rowData]")
    }, [props.rowData])

    React.useEffect(() => {
        return () => dispatch(ChangeFormData(getValues()))
    }, [])

    if (localFormData && selectedTransactionId != id) {
        reset(localFormData)
        setLocalFormData(null)
    }

    return (
        <tr
            key={id}
            onClick={(e) => {
                e.preventDefault()
                if (!editingDisabled && selectedTransactionId != id) {
                    dispatch(ChangeSelectedTransactionId(id))
                    setLocalFormData(getValues())
                }
            }}
        >
            <th className="checkbox-th">
                {!editingDisabled &&
                    <FormGroup check className="mt-2">
                        {selectedTransactionId == id ?
                            <BsFillCheckSquareFill
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    dispatch(ChangeSelectedTransactionId(0))
                                }}
                                className='check-icon'
                            />
                            :
                            <div
                                onClick={(e) => {
                                    if (selectedTransactionId != id) {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        dispatch(ChangeSelectedTransactionId(id))
                                        setLocalFormData(getValues())
                                    }
                                }}
                                className='checkbox-wrapper'
                            />
                        }
                    </FormGroup>
                }
            </th>
            <th>
                <i
                    onClick={() => dispatch(BookmarkTransaction({ bookmark: !bookmark, id }))}
                    class={bookmark ? "fa fa-bookmark" : "far fa-bookmark"}
                    style={{
                        transform: 'rotate(-90deg)',
                        cursor: 'pointer',
                    }}
                />
            </th>
            <td className={errors.account ? "error-td" : ""}>
                <Controller
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            isDisabled={editingDisabled}
                            aria-invalid="true"
                            options={accountsList}
                            value={value}
                            onChange={(value) => {
                                onChange(value)
                                let filteredAccountsList = accountsList.filter(obj => obj.value != value.value)
                                setAccountPayeeList(filteredAccountsList)
                                reset({
                                    ...getValues(),
                                    payee: null,
                                })
                            }}
                            style={{ paddingBlock: "0.6rem" }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: errors.account ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                    borderRadius: '5px',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#34B5B8' + '55',
                                    primary: '#34B5B8',
                                    primary50: '#34B5B8' + '33',
                                },
                            })}
                        />
                    )}
                    name="account"
                />
            </td>
            <td className={errors.transactionDate ? "error-td date-td" : "date-td"}>
                <Controller
                    className="row-input"
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                        let date
                        if (value) {
                            date = value.split("/")
                            date = new Date(date[2], date[1] - 1, date[0])
                        } else {
                            date = null
                        }
                        if (editingDisabled)
                            return (
                                <Input
                                    disabled={editingDisabled}
                                    className="form-control text-center custom-date-field"
                                    type="number"
                                    placeholder={value}
                                    style={{
                                        paddingBlock: "0.6rem",
                                        border: errors.outflow ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                        borderRadius: '5px',
                                    }}
                                />
                            )
                        else
                            return (
                                <ReactDatetime
                                    onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                    value={date}
                                    inputProps={{
                                        className: "form-control text-center ",
                                        style: {
                                            paddingBlock: "0.6rem",
                                            border: errors.transactionDate ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                            borderRadius: '5px',
                                        },
                                        placeholder: "Date",
                                        readOnly: true,
                                    }}
                                    timeFormat={false}
                                    dateFormat={GetDateFormat()}
                                    style={{ paddingBlock: "0.6rem" }}
                                    closeOnScroll={true}
                                />
                            )
                    }}
                    name="transactionDate"
                />
            </td>
            <td className={errors.payee ? "error-td " : ""}>
                <Controller
                    control={control}
                    rules={{
                        required: !watchEnvelopeMortgageGoal,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            isDisabled={editingDisabled}
                            options={payeeList}
                            value={value}
                            onChange={(value) => {
                                onChange(value)

                                if (value.parentId == -2)
                                    reset({
                                        ...getValues(),
                                        envelopeMortgageGoal: null,
                                    })
                            }}
                            placeholder="No Payee"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: errors.payee ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                    borderRadius: '5px',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#34B5B8' + '55',
                                    primary: '#34B5B8',
                                    primary50: '#34B5B8' + '33',
                                },
                            })}
                        />
                    )}
                    name="payee"
                />
            </td>
            <td className={errors.envelopeMortgageGoal ? "error-td " : ""}>
                <Controller
                    control={control}
                    rules={{
                        required: watchPayee?.parentId == -3,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            isDisabled={editingDisabled}
                            options={selectEnvelopeMortgageGoalList}
                            value={value}
                            onChange={(value) => {
                                onChange(value)
                                if (watchPayee?.parentId == -2)
                                    reset({
                                        ...getValues(),
                                        payee: null,
                                    })
                            }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: errors.envelopeMortgageGoal ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                    borderRadius: '5px',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#34B5B8' + '55',
                                    primary: '#34B5B8',
                                    primary50: '#34B5B8' + '33',
                                },
                            })}
                        />
                    )}
                    name="envelopeMortgageGoal"
                />
            </td>
            <td >
                <Controller
                    control={control}
                    rules={{
                        // required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            disabled={editingDisabled}
                            className="form-control-sm row-input"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            placeholder="memo"
                            style={{
                                paddingBlock: "0.6rem",
                                border: errors.memo ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                borderRadius: '5px',
                            }}
                        />
                    )}
                    name="memo"
                />
            </td>
            <td >
                <Controller
                    control={control}
                    rules={{
                        required: (watchInflow == "0"),
                        pattern: (watchInflow == "0") ? /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/ : null,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            disabled={editingDisabled}
                            className="row-input"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value)
                                reset({
                                    ...getValues(),
                                    inflow: "0",
                                })
                            }}
                            type="number"
                            placeholder="outflow"
                            style={{
                                paddingBlock: "0.6rem",
                                border: errors.outflow ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                borderRadius: '5px',
                            }}
                        />
                    )}
                    name="outflow"
                />
            </td>
            <td >
                <Controller
                    control={control}
                    rules={{
                        required: (watchOutflow == "0"),
                        pattern: (watchOutflow == "0") ? /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/ : null,
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            disabled={editingDisabled}
                            className="row-input"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value)
                                reset({
                                    ...getValues(),
                                    outflow: "0",
                                })
                            }}
                            type="number"
                            placeholder="inflow"
                            style={{
                                paddingBlock: "0.6rem",
                                border: errors.inflow ? '3px solid #EF8157' : '1px solid #CCCCCC',
                                borderRadius: '5px',
                            }}
                        />
                    )}
                    name="inflow"
                />
            </td>
            <td>
                {(selectedTransactionId == id && !editingDisabled) ?
                    <div className="flex-wrapper">
                        {!editRowFormLoading ?
                            <i
                                onClick={handleSubmit(async (data) => {
                                    if (data?.envelopeMortgageGoal?.parentId == -1) {
                                        toast("You cannot edit mortgage transactions!")
                                    } else {
                                        let action = await dispatch(EditTransaction({ ...data, supressLoader: true }))

                                        const { status } = action.payload || {}
                                        if (status >= 200 && status < 300)
                                            setLocalFormData(null)

                                    }
                                })}
                                class={`save-icon fa fa-floppy-o custom-icon`}
                            />
                            :
                            <ScaleLoader
                                color={"#000"}
                                loading={true}
                                size={50}
                                height={10}
                                width={2}
                            />
                        }
                    </div>
                    :
                    <div className="flex-wrapper">
                        <i
                            onClick={(e) => {
                                e.stopPropagation()
                                dispatch(ClearTransaction({ id, isCleared: !isCleared }))
                            }}
                            class={`clear-icon fab fa-cuttlefish ${isCleared ? "cleared-trans" : ""}`}
                        />
                    </div>
                }
            </td>
        </tr>
    )
}

export default RowForm
