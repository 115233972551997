import moment from 'moment';
import React, { useEffect } from 'react';
import {
    Calendar,
    momentLocalizer
} from "react-big-calendar";
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { styles } from '../../../custom.js';
import { GetCalendar } from './Store/customCalendarSlice';


const localizer = momentLocalizer(moment)

let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
    DAY: 'DAY',
    AGENDA: 'AGENDA',
}

class Toolbar extends React.Component {
    render() {
        let { localizer: { messages }, label } = this.props
        return (
            <div style={styles.toolbarDiv}>
                <div style={styles.toolbarLeftDiv}>
                    <span className="rbc-btn-group">
                        <i onClick={this.navigate.bind(null, navigate.PREVIOUS)} style={styles.calendarArrows} class="fas fa-arrow-left"></i>
                    </span>
                    <span className="rbc-btn-group" style={{ width: 150, display: "flex", justifyContent: "center" }}>
                        {label}
                    </span>
                    <span className="rbc-btn-group">
                        <i onClick={this.navigate.bind(null, navigate.NEXT)} style={styles.calendarArrows} class="fas fa-arrow-right"></i>
                    </span>
                </div>
                <div style={styles.toolbarRightDiv}>
                    <div style={{ backgroundColor: "#239b3733", ...styles.goalTypeDiv, }}>
                        <i style={{ color: "#239b37", fontSize: 13 }} class="fas fa-smile-beam"></i>
                        <span style={{ marginLeft: 5 }}>Goal</span>
                    </div>
                    <div style={{ backgroundColor: "#C78E2433", ...styles.goalTypeDiv, }}>
                        <i style={{ color: "#C78E24", fontSize: 13 }} class="fas fa-bullseye"></i>
                        <span style={{ marginLeft: 5 }}>Obligation</span>
                    </div>
                    <div style={{ backgroundColor: "#EF835733", ...styles.goalTypeDiv, }}>
                        <i style={{ color: "#EF8357", fontSize: 12, marginLeft: -2 }} class="fas fa-balance-scale"></i>
                        <span style={{ marginLeft: 5 }}>Objective</span>
                    </div>
                </div>
            </div>
        )
    }
    navigate = action => {
        this.props.onNavigate(action)
    }
}
function CellWrapper(event) {
    // console.log("CELLWRAPPER EVENT")
    // console.log(event)
    return (
        <div className="rbc-day-bg" style={{}} >
        </div>
    )
}
function Event({ event }) {
    return (
        <div style={{ paddingTop: 3, paddingBottom: 3, cursor: "pointer" }}>
            {event.type == "goal" &&
                <i style={{ color: "#239b37", fontSize: 13 }} class="fas fa-smile-beam"></i>
            }
            {event.type == "objective" &&
                <i style={{ color: "#C78E24", fontSize: 13 }} class="fas fa-bullseye"></i>
            }
            {event.type == "obligation" &&
                <i style={{ color: "#EF8357", fontSize: 12, marginLeft: -2 }} class="fas fa-balance-scale"></i>
            }
            <strong style={{ marginLeft: 5 }}>{event.title}</strong>
            {event.type == "goal" &&
                <div style={{ height: 2, width: '100%', marginTop: 3, display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: event.totalBudget, display: "flex", backgroundColor: "#239b37" }}></div>
                    <div style={{ flex: (event.amount - event.totalBudget), display: "flex", backgroundColor: "#239b37" + "88" }}></div>
                </div>
            }
            {event.type == "objective" &&
                <div style={{ height: 2, width: '100%', marginTop: 3, display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: event.totalBudget, display: "flex", backgroundColor: "#C78E24" }}></div>
                    <div style={{ flex: (event.amount - event.totalBudget), display: "flex", backgroundColor: "#C78E24" + "88" }}></div>
                </div>
            }
            {event.type == "obligation" &&
                <div style={{ height: 2, width: '100%', marginTop: 3, display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: event.totalBudget, display: "flex", backgroundColor: "#EF8357" }}></div>
                    <div style={{ flex: (event.amount - event.totalBudget), display: "flex", backgroundColor: "#EF8357" + "88" }}></div>
                </div>
            }
        </div>
    )
}
function DateHeader({ label }) {
    return (
        <div>
            {label}
        </div>
    )
}
const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor;
    if (event.type == "goal")
        backgroundColor = "#239b37"
    else if (event.type == "obligation")
        backgroundColor = "#EF8357"
    else if (event.type == "objective")
        backgroundColor = "#C78E24"
    var style = {
        backgroundColor: backgroundColor + "33",
        borderRadius: '0px',
        opacity: 1,
        color: 'black',
        borderRadius: '5px',
        display: 'block'
    };
    return {
        style: style
    };
}

const CustomCalendar = (props) => {

    const dispatch = useDispatch()

    const { calendarEvents } = useSelector(state => state.customCalendar)

    useEffect(() => {
        dispatch(GetCalendar())
    }, [])

    return (
        <>
            <div className="content">
                <Row>
                    <Col className="ml-auto mr-auto" md="11">
                        <Card className="card-calendar" >
                            <CardBody>
                                <Calendar
                                    popup
                                    localizer={localizer}
                                    defaultView="month"
                                    scrollToTime={new Date(1970, 1, 1, 6)}
                                    events={calendarEvents}
                                    startAccessor="start"
                                    endAccessor="end"
                                    eventPropGetter={(eventStyleGetter)}
                                    truncateEvents={true}
                                    // onShowMore={(events, date) => console.log(date)}
                                    messages={{
                                        showMore: total => (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onMouseDown={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}

                                            >
                                                {`+${total} more`}
                                            </div>
                                        ),
                                    }}
                                    dayPropGetter={(date) => {
                                        console.log("DAYPROPGETTER")
                                        console.log(date)
                                    }}
                                    components={{
                                        toolbar: Toolbar,
                                        event: Event,
                                        dateCellWrapper: CellWrapper,
                                        // dayWrapper: (e) => {
                                        //     console.log("E")
                                        //     console.log(e)
                                        //     return(
                                        //         <div>asf</div>
                                        //     )
                                        // },
                                        month: {
                                            dateHeader: DateHeader,
                                        },
                                    }}
                                    views={['month',]}
                                // style={{ height: 800 }}
                                />
                            </CardBody>
                        </Card>
                    </Col >
                </Row >
            </div >
        </>
    )
}

export default CustomCalendar