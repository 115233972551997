import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiNoLoaderInstance } from 'App/Config/AxiosInstances';
import { toast } from 'react-toastify';
import { GetMiniName } from '../../../Config/Helper';
import AllAccounts from '../../Accounts/AllAccounts';
import { GetExpenseOverview } from '../../MyBudget/Store/myBudgetSlice';


export const GetAllDataOfBudget = createAsyncThunk('shared/GetAllDataOfBudget', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = params

        let [
            allAccounts,
            envelopeNames,
            fundsToAllot,
            envelopeMortgageGoalNames,
            goalNames,
            budgetNotes,
            payees,
            budgetOptions,
            accountTypes,
            reportsDateFilter,
        ] = await Promise.all([
            apiNoLoaderInstance.get(`/api/accounts/get-budgets-accounts/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/envelops/get-envelop/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/accounts/sum-of-accounts/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/envelops/get-subEnvelop/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/get-goal/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/get-note/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get(`/api/get-payees/${selectedBudgetId}`).then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get('/api/budgetOptions').then(response => response).catch(error => error.response),
            apiNoLoaderInstance.get('/api/account-type').then(response => response).catch(error => error.response),
            apiNoLoaderInstance.post('/api/report/date-filter', { budget_id: selectedBudgetId }).then(response => response).catch(error => error.response),
        ])

        return {
            allAccounts,
            envelopeNames,
            fundsToAllot,
            envelopeMortgageGoalNames,
            goalNames,
            budgetNotes,
            payees,
            budgetOptions,
            accountTypes,
            reportsDateFilter,
        }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const UpdateFundsToAllocate = createAsyncThunk('shared/UpdateFundsToAllocate', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.get(`/api/accounts/sum-of-accounts/${selectedBudgetId}`)
            .then(response => response)

        const { data, status } = result

        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const UpdateEnvelopeMortgageGoalNames = createAsyncThunk('shared/UpdateEnvelopeMortgageGoalNames', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.get(`/api/envelops/get-subEnvelop/${selectedBudgetId}`)
            .then(response => response)

        const { data, status } = result

        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const UpdateAccounts = createAsyncThunk('shared/UpdateAccounts', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { selectedBudgetId } = getState().userData

        let result = await apiNoLoaderInstance.get(`/api/accounts/get-budgets-accounts/${selectedBudgetId}`)
            .then(response => response)

        const { data, status } = result

        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetExpanseNamesByEnvelopeId = createAsyncThunk('shared/GetExpanseNamesByEnvelopeId', async (params, { dispatch, getState }) => {
    try {

        const envelopeId = params

        let result = await apiNoLoaderInstance.get(`/api/envelops/get-sub-envelop-by-envelop/${envelopeId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const AllocateFundsToExpense = createAsyncThunk('shared/AllocateFundsToExpense', async (params, { dispatch, getState }) => {
    try {
        const { selectedMonth } = getState().myBudget

        const { amountToAllocate, transferingTo, envelopeValue, expenseValue, mortgateValue } = params || {}

        let result = await apiNoLoaderInstance.post(`/api/fund-allocate/${expenseValue.value}`, {
            'total_amount': amountToAllocate ? parseInt(amountToAllocate) : 0,
            'date': selectedMonth,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(UpdateFundsToAllocate())
        dispatch(GetExpenseOverview())

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const AllocateFundsToGoal = createAsyncThunk('shared/AllocateFundsToGoal', async (params, { dispatch, getState }) => {
    try {
        console.log("PARAMS")
        console.log(params)

        const { amountToAllocate, transferingTo, envelopeValue, expenseValue, mortgateValue } = params || {} //todo: expenseValue here could be goalValue 

        let result = await apiNoLoaderInstance.post(`/api/goal-fund-allocate/${envelopeValue.value}`, {
            'total_amount': amountToAllocate ? parseInt(amountToAllocate) : 0,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        dispatch(UpdateFundsToAllocate())

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const GetMortgageMonths = createAsyncThunk('shared/GetMortgageMonths', async (params, { dispatch, getState }) => {
    try {

        const mortgageId = params

        let result = await apiNoLoaderInstance.get(`/api/get-mortgage-months/${mortgageId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status, mortgageId }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        accounts: [],
        accountsList: [],
        budgetNotes: null,
        editRowFormLoading: false,
        customHeaerTitle: null,
        envelopeNames: [],
        expenseNames: [],
        expenseNamesLoading: false,
        fundsToAllot: 0, //* Sum of available balance in accounts - spent
        goalNames: [],
        loading: false,
        mortgageMonths: [],
        savedPayeeList: [],
        selectedMonthId: 0,
        selectedTransactionId: 0,
        selectEnvelopeMortgageGoalList: [],
        submitButtonLoading: false,
    },
    reducers: {
        ChangeSelectedTransactionId: (state, action) => {
            state.selectedTransactionId = action.payload
        },
        ChangeCustomHeaerTitle: (state, action) => {
            state.customHeaerTitle = action.payload
        },
        ChangeGebudgetNotes: (state, action) => {
            state.budgetNotes = action.payload
        },
        ChangeLoading: (state, action) => {
            state.loading = action.payload
        },
        ChangeSavedPayeeListCheck: (state, action) => {
            const { value, isChecked } = action.payload
            let savedPayeeList = []
            state.savedPayeeList.map((item) => {
                if (item.value == value) {
                    savedPayeeList.push({
                        ...item,
                        isChecked,
                    })
                } else {
                    savedPayeeList.push(item)
                }
            })
            state.savedPayeeList = savedPayeeList
        },
        ChangeAllSavedPayeeListCheck: (state, action) => {
            const { isChecked } = action.payload
            let savedPayeeList = []
            state.savedPayeeList.map((item) => {
                savedPayeeList.push({
                    ...item,
                    isChecked,
                })
            })
            state.savedPayeeList = savedPayeeList
        },
    },
    extraReducers: {
        ['accounts/EditTransaction/pending']: (state, action) => {
            state.editRowFormLoading = true
        },
        ['accounts/EditTransaction/fulfilled']: (state, action) => {
            state.editRowFormLoading = false
        },
        ['accounts/EditTransaction/rejected']: (state, action) => {
            state.editRowFormLoading = false
        },
        ['addAccount/GetLinkedAccounts/pending']: (state, action) => {
            state.loading = true
        },
        ['addAccount/GetLinkedAccounts/fulfilled']: (state, action) => {
            state.loading = false
        },
        ['addAccount/GetLinkedAccounts/rejected']: (state, action) => {
            state.loading = false
        },
        ['auth/SignIn/pending']: (state, action) => {
            state.loading = true
        },
        ['auth/SignIn/fulfilled']: (state, action) => {
            state.loading = false
        },
        ['auth/SignIn/rejected']: (state, action) => {
            state.loading = false
        },
        ['myBudget/EditExpense/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, user_id, envelop_id, envelop_sub_category_name, total_budget, spent } = data?.envelop_sub_cagetgory || {}
                let selectEnvelopeMortgageGoalList = []
                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == envelop_id) {
                        let options = []
                        item.options.map(subItem => {
                            if (subItem.value == id) {
                                options.push({
                                    ...subItem,
                                    label: envelop_sub_category_name
                                })
                            } else {
                                options.push(subItem)
                            }
                        })
                        selectEnvelopeMortgageGoalList.push({
                            ...item,
                            options,
                        })
                    } else {
                        selectEnvelopeMortgageGoalList.push(item)
                    }
                })
                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList
            }
        },
        ['myBudget/EditEnvelope/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, name } = data.envelop || {}

                let selectEnvelopeMortgageGoalList = []

                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == id)
                        selectEnvelopeMortgageGoalList.push({
                            ...item,
                            label: name,
                        })
                    else
                        selectEnvelopeMortgageGoalList.push(item)
                })

                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList
            }
        },
        ['managePayees/CombinePayee/fulfilled']: (state, action) => {
            const { data, status, payeeIds } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    id,
                    auto_loan,
                    user_id,
                    budget_id,
                    mortgage_id,
                    goal_id,
                    envelop_sub_id,
                    sub_envelop,
                    payee_name,
                    include_in_payee,
                    automatically_categorize_payee,
                    softdelete,
                } = data?.data[0] || {}

                let savedPayeeList = []

                state.savedPayeeList.map(obj => {
                    if (obj.value == id) {
                        savedPayeeList.push({
                            value: id,
                            label: payee_name,
                            expenseId: envelop_sub_id,
                            envelopeId: sub_envelop?.envelop_id,
                            goalId: goal_id,
                            mortgageId: mortgage_id,
                            isChecked: false,
                            parentId: -3,
                            isIncluded: include_in_payee,
                            isAutomatic: automatically_categorize_payee,
                        })
                    } else if (!payeeIds.includes(obj.value)) {
                        savedPayeeList.push(obj)
                    }
                })

                state.savedPayeeList = savedPayeeList

                toast(data?.message)
            }
        },
        ['managePayees/DeletePayee/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id } = data?.data
                state.savedPayeeList = state.savedPayeeList.filter(obj => obj.value != id)
            }
        },
        ['managePayees/PostPayee/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    user_id,
                    budget_id,
                    envelop_sub_id,
                    sub_envelop,
                    payee_name,
                    id,
                    goal_id,
                    mortgage_id,
                } = data?.data
                state.savedPayeeList.push({
                    value: id,
                    label: payee_name,
                    expenseId: envelop_sub_id,
                    envelopeId: sub_envelop?.envelop_id,
                    mortgageId: mortgage_id,
                    goalId: goal_id,
                    isChecked: false,
                    parentId: -3,
                })
                toast(data?.message)
            }
        },
        ['managePayees/PutPayee/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    user_id,
                    budget_id,
                    envelop_sub_id,
                    sub_envelop,
                    payee_name,
                    id,
                    mortgage_id,
                    goal_id,
                } = data?.data || {}
                let savedPayeeList = []
                current(state.savedPayeeList).map((item) => {
                    if (item.value == id) {
                        savedPayeeList.push({
                            ...item,
                            label: payee_name,
                            expenseId: envelop_sub_id,
                            envelopeId: sub_envelop?.envelop_id,
                            mortgageId: mortgage_id,
                            goalId: goal_id,
                        })
                    } else {
                        savedPayeeList.push(item)
                    }
                })
                state.savedPayeeList = savedPayeeList
                toast(data?.message)
            }
        },
        ['myBudget/PostNotes/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { note } = data?.data
                state.budgetNotes = note
            } else if (status >= 400 && status < 500) {
                toast(data?.note[0])
            }
        },
        ['mortgage/PostMortgage/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, mortgage_name } = data?.data?.mortgage

                //* selectEnvelopeMortgageGoalList
                const selectEnvelopeMortgageGoalList = []
                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == -1) {
                        let options = []
                        item.options.map((subItem) => {
                            options.push(subItem)
                        })
                        options.push({ value: id, label: mortgage_name, parentId: -1 })
                        selectEnvelopeMortgageGoalList.push({
                            ...item,
                            options: options
                        })
                    } else {
                        selectEnvelopeMortgageGoalList.push(item)
                    }
                })
                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

            }
        },
        ['myGoals/PutGoal/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, title } = data?.data

                //* selectEnvelopeMortgageGoalList
                const selectEnvelopeMortgageGoalList = []
                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == 0) {
                        let options = []
                        item.options.map((subItem) => {
                            if (subItem.value == id) {
                                options.push({ value: id, label: title })
                            } else {
                                options.push(subItem)
                            }
                        })
                        selectEnvelopeMortgageGoalList.push({
                            ...item,
                            options: options,
                        })
                    } else {
                        selectEnvelopeMortgageGoalList.push(item)
                    }
                })
                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

                //* goalNames
                let goalNames = []
                current(state.goalNames).map((item) => {
                    if (item.value == id) {
                        goalNames.push({ value: id, label: title })
                    } else {
                        goalNames.push(item)
                    }
                })
                state.goalNames = goalNames
            }
        },
        ['myGoals/PostGoal/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { id, title } = data?.data

                //* selectEnvelopeMortgageGoalList
                const selectEnvelopeMortgageGoalList = []
                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == 0) {
                        let options = []
                        item.options.map((subItem) => {
                            options.push(subItem)
                        })
                        options.push({ value: id, label: title })
                        selectEnvelopeMortgageGoalList.push({
                            ...item,
                            options: options
                        })
                    } else {
                        selectEnvelopeMortgageGoalList.push(item)
                    }
                })
                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

                //* goalNames
                state.goalNames.push({ value: id, label: title })
            }
        },
        ['shared/GetMortgageMonths/pending']: (state, action) => {
            state.mortgageMonths = []
            state.selectedMonthId = 0
        },
        ['shared/GetMortgageMonths/fulfilled']: (state, action) => {
            const { data, status, mortgageId } = action.payload || {}
            if (status >= 200 && status < 300) {
                let mortgageMonths = []
                let selectedMonthId = 0
                data.data.map((item) => {
                    const {
                        id,
                        budget_id,
                        mortgage_id,
                        months,
                        amount,
                        mortgage_status_id,
                        created_at,
                        updated_at,
                        loan_amount,
                    } = item

                    if ((mortgage_status_id == 2 || mortgage_status_id == 1) && selectedMonthId == 0)
                        selectedMonthId = id

                    mortgageMonths.push({
                        value: id,
                        label: months,
                        amount,
                        loanAmount: loan_amount,
                    })
                })
                state.mortgageMonths = mortgageMonths
                state.selectedMonthId = selectedMonthId
            }
        },
        ['shared/UpdateFundsToAllocate/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                console.log("DATA?.TOTAL_AMOUNT_AVAILABLE_IN_ACCOUNTS_FOR_BUDGET")
                console.log(data?.total_amount_available_in_accounts_for_budget)
                state.fundsToAllot = data?.total_amount_available_in_accounts_for_budget
            }
        },
        ['accounts/AddTransaction/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    account_id,
                    envelop_id,
                    envelop_sub_cat_id,
                    id,
                    in_flow_amount,
                    is_envelop_or_mortgage,
                    memo,
                    mortgage_id,
                    out_flow_amount,
                    transaction_date,
                    user_id,
                    payee_account_id,
                } = data.transaction
                let accounts = []

                current(state.accounts).map((item) => {
                    if (item.accountId != account_id && item.accountId != payee_account_id)
                        accounts.push(item)
                    else
                        if (item.accountId == account_id)
                            if (in_flow_amount == "0.000")
                                accounts.push({
                                    ...item,
                                    name: `${item.singleName}`,
                                    amount: item.amount - parseFloat(out_flow_amount),
                                })
                            else
                                accounts.push({
                                    ...item,
                                    name: `${item.singleName}`,
                                    amount: item.amount + parseFloat(in_flow_amount),
                                })
                        else
                            if (in_flow_amount == "0.000")
                                accounts.push({
                                    ...item,
                                    name: `${item.singleName}`,
                                    amount: item.amount + parseFloat(out_flow_amount),
                                })
                            else
                                accounts.push({
                                    ...item,
                                    name: `${item.singleName}`,
                                    amount: item.amount - parseFloat(in_flow_amount),
                                })
                })

                state.accounts = accounts
            }
        },
        ['shared/UpdateAccounts/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let accountsList = []
                let accounts = [{
                    accountId: 0,
                    path: "/accounts",
                    name: "All Accounts",
                    // name: "All Accounts ($20000)",
                    mini: "AC",
                    component: AllAccounts,
                    layout: "/admin",
                }]
                data.accounts.map((item) => {
                    const { account_title, current_amount, id } = item
                    accounts.push({
                        ...item,
                        accountId: id,
                        path: `/accounts/:accountId`,
                        singleName: account_title,
                        name: `${account_title}`,
                        mini: GetMiniName(account_title),
                        component: AllAccounts,
                        layout: `/admin`,
                        amount: current_amount,
                    })
                    accountsList.push({ value: id, label: account_title, parentId: -2, })
                })
                state.accounts = accounts
                // state.accountsList = accountsList
            }
        },
        ['shared/UpdateEnvelopeMortgageGoalNames/fulfilled']: (state, action) => {

            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                //* --envelopeMortgageGoalNames-->start

                //* Expenses Names
                let selectEnvelopeMortgageGoalList = []
                let options = []
                data.envelops.map((item) => {
                    options = []
                    const { id: envelopId, name, sub_envelop } = item
                    sub_envelop.map((subItem) => {
                        const { id, user_id, envelop_id, envelop_sub_category_name, total_budget, spent, envelop_goal } = subItem
                        if (envelop_goal?.length != 0)
                            options.push({ value: id, label: envelop_sub_category_name, parentId: envelopId })
                    })
                    selectEnvelopeMortgageGoalList.push({
                        id: envelopId,
                        label: name,
                        options,
                    })
                })

                //* Goal/Obligations Names
                options = []
                data.goals.map((item) => {
                    const { id, title } = item
                    options.push({ value: id, label: title, parentId: 0 })
                })
                selectEnvelopeMortgageGoalList.push({
                    id: 0,
                    label: "Goals / Obligations",
                    options,
                })

                //* Mortgages Names
                options = []
                data.mortages.map((item) => {
                    const { id, mortgage_name } = item
                    options.push({ value: id, label: mortgage_name, parentId: -1 })
                })
                selectEnvelopeMortgageGoalList.push({
                    id: -1,
                    label: "Mortgages",
                    options,
                })

                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

                //* --envelopeMortgageGoalNames-->end
            }
        },
        ['dashboard/GetDashboad/pending']: (state, action) => {
            state.loading = true
        },
        ['dashboard/GetDashboad/fulfilled']: (state, action) => {
            state.loading = false
        },
        ['dashboard/GetDashboad/rejected']: (state, action) => {
            state.loading = false
        },
        ['shared/GetAllDataOfBudget/pending']: (state, action) => {
            state.loading = true
        },
        ['shared/GetAllDataOfBudget/fulfilled']: (state, action) => {

            const { allAccounts, envelopeNames, fundsToAllot, envelopeMortgageGoalNames, goalNames, budgetNotes, payees } = action.payload || {}
            if (
                (allAccounts?.status >= 200 && allAccounts?.status < 300) && //todo: use separate checks later
                (envelopeNames?.status >= 200 && envelopeNames?.status < 300) &&
                (fundsToAllot?.status >= 200 && fundsToAllot?.status < 300) &&
                (envelopeMortgageGoalNames?.status >= 200 && envelopeMortgageGoalNames?.status < 300) &&
                (goalNames?.status >= 200 && goalNames?.status < 300) &&
                (budgetNotes?.status >= 200 && budgetNotes?.status < 300) &&
                (payees?.status >= 200 && payees?.status < 300)
            ) {
                //* allAccounts
                let accountsList = []
                let accounts = [{
                    accountId: 0,
                    path: "/accounts",
                    name: "All Accounts",
                    // name: "All Accounts ($20000)",
                    mini: "AC",
                    component: AllAccounts,
                    layout: "/admin",
                }]
                allAccounts.data.accounts.map((item) => {
                    const { account_title, current_amount, id, is_plaid_account, plaid_account_id } = item
                    accounts.push({
                        accountId: id,
                        path: `/accounts/:accountId`,
                        singleName: account_title,
                        name: account_title,
                        mini: GetMiniName(account_title),
                        component: AllAccounts,
                        layout: `/admin`,
                        amount: current_amount,
                        isPlaidAccount: is_plaid_account,
                        plaidAccountId: plaid_account_id,
                    })
                    accountsList.push({ value: id, label: account_title, parentId: -2, })
                })
                state.accounts = accounts
                state.accountsList = accountsList

                //* --envelopeMortgageGoalNames-->start

                //* Expenses Names
                let selectEnvelopeMortgageGoalList = []
                let options = []
                envelopeMortgageGoalNames.data.envelops.map((item) => {
                    options = []
                    const { id: envelopId, name, sub_envelop } = item
                    sub_envelop.map((subItem) => {
                        const { id, user_id, envelop_id, envelop_sub_category_name, total_budget, spent, envelop_goal } = subItem
                        if (envelop_goal?.length != 0)
                            options.push({ value: id, label: envelop_sub_category_name, parentId: envelopId })
                    })
                    selectEnvelopeMortgageGoalList.push({
                        id: envelopId,
                        label: name,
                        options,
                    })
                })

                //* Goal/Obligations Names
                options = []
                envelopeMortgageGoalNames.data.goals.map((item) => {
                    const { id, title } = item
                    options.push({ value: id, label: title, parentId: 0 })
                })
                selectEnvelopeMortgageGoalList.push({
                    id: 0,
                    label: "Goals / Obligations",
                    options,
                })

                //* Mortgages Names
                options = []
                envelopeMortgageGoalNames.data.mortages.map((item) => {
                    const { id, mortgage_name } = item
                    options.push({ value: id, label: mortgage_name, parentId: -1 })
                })
                selectEnvelopeMortgageGoalList.push({
                    id: -1,
                    label: "Mortgages",
                    options,
                })

                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

                //* --envelopeMortgageGoalNames-->end


                //* fundsToAllot
                state.fundsToAllot = fundsToAllot?.data?.total_amount_available_in_accounts_for_budget

                //*envelopeNames
                let envelopeNamesTemp = []
                envelopeNames.data.data.map((item) => {
                    const { id, name } = item
                    envelopeNamesTemp.push({ value: id, label: name })
                })
                state.envelopeNames = envelopeNamesTemp

                //*goalNames
                let goalNamesTemp = []
                goalNames.data.data.map((item) => {
                    const { id, title } = item
                    goalNamesTemp.push({ value: id, label: title })
                })
                state.goalNames = goalNamesTemp

                //*budgetNotes
                state.budgetNotes = budgetNotes?.data?.data?.note

                //*payees
                let savedPayeeList = []
                payees.data.data.map((item) => {
                    const { id, payee_name, envelop_sub_id, mortgage_id, goal_id, sub_envelop } = item || {}
                    savedPayeeList.push({
                        value: id,
                        label: payee_name,
                        envelopeId: sub_envelop?.envelop_id,
                        expenseId: envelop_sub_id,
                        isChecked: false,
                        parentId: -3,
                        goalId: goal_id,
                        mortgageId: mortgage_id,
                    })
                })
                state.savedPayeeList = savedPayeeList
            }
            state.loading = false
        },
        ['shared/GetAllDataOfBudget/rejected']: (state, action) => {
            state.loading = false
        },
        ['addAccount/PutUnLinkedAccount/fulfilled']: (state, action) => {
            const { data = null, status } = action.payload || {}
            if (status >= 200 && status < 300) {

                const { account_title, current_amount, id } = data?.account || {}

                console.log("ID")
                console.log(id)

                let accounts = []
                let amountDifference

                current(state.accounts).map((item) => {
                    if (item.accountId != id)
                        accounts.push(item)
                    else {
                        amountDifference = item.amount - current_amount
                        accounts.push({
                            ...item,
                            singleName: account_title,
                            name: `${account_title}`,
                            amount: current_amount,
                        })
                    }
                })

                state.accounts = accounts
                state.fundsToAllot -= amountDifference
            }
        },
        ['addAccount/DeleteUnLinkedAccount/fulfilled']: (state, action) => {
            const { data = null, status, accountId } = action.payload || {}
            if (status >= 200 && status < 300) {

                let current_amount = state.accounts.find(obj => obj.accountId == accountId)?.amount

                state.accounts = state.accounts.filter(obj => obj.accountId != accountId)
                state.accountsList = state.accountsList.filter(obj => obj.value != accountId)
                state.fundsToAllot -= current_amount
            }
        },
        ['addAccount/PostUnLinkedAccount/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const {
                    account_title,
                    account_type_id,
                    budget_id,
                    current_amount,
                    id,
                    is_plaid_account,
                    plaid_account_id,
                } = data?.data || {}
                let accounts = state.accounts
                let accountsList = state.accountsList
                accounts.push({
                    accountId: id,
                    path: `/accounts/${id}`,
                    name: `${account_title}`,
                    singleName: account_title,
                    mini: `AC`,
                    component: AllAccounts,
                    layout: `/admin`,
                    amount: current_amount,
                    isPlaidAccount: is_plaid_account ? is_plaid_account : 0,
                    plaidAccountId: plaid_account_id ? plaid_account_id : null,
                })
                accountsList.push({ value: id, label: account_title, parentId: -2, })
                state.accounts = accounts
                state.accountsList = accountsList
                state.fundsToAllot += current_amount
                toast(data?.message)
            } else if (status >= 400 && status < 500) {
                toast(data?.message)
            }
        },
        ['shared/GetExpanseNamesByEnvelopeId/pending']: (state, action) => {
            state.expenseNames = []
            state.expenseNamesLoading = true
        },
        ['shared/GetExpanseNamesByEnvelopeId/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                let expenseNames = []
                data.data.map((item) => {
                    const { id, envelop_sub_category_name, envelop_goal } = item
                    if (envelop_goal?.length != 0)
                        expenseNames.push({ value: id, label: envelop_sub_category_name })
                })
                state.expenseNames = expenseNames
                state.expenseNamesLoading = false
            }
        },
        ['shared/GetExpanseNamesByEnvelopeId/rejected']: (state, action) => {
            state.expenseNamesLoading = false
        },
        ['myBudget/CreateEnvelope/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { budget_id, id, name } = data.envelop || {}

                //*envelopeNames
                state.envelopeNames.push({ value: id, label: name })

                //*selectEnvelopeMortgageGoalList
                const selectEnvelopeMortgageGoalList = []
                current(state.selectEnvelopeMortgageGoalList).map((item) => {
                    if (item.id == 0) {
                        selectEnvelopeMortgageGoalList.push({
                            id: id,
                            label: name,
                            options: [],
                        })
                    }
                    selectEnvelopeMortgageGoalList.push(item)
                })
                state.selectEnvelopeMortgageGoalList = selectEnvelopeMortgageGoalList

            }
            state.submitButtonLoading = false
        },
        ['shared/AllocateFundsToExpense/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['shared/AllocateFundsToExpense/fulfilled']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['shared/AllocateFundsToExpense/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['shared/AllocateFundsToGoal/pending']: (state, action) => {
            state.submitButtonLoading = true
        },
        ['shared/AllocateFundsToGoal/fulfilled']: (state, action) => {
            state.submitButtonLoading = false
        },
        ['shared/AllocateFundsToGoal/rejected']: (state, action) => {
            state.submitButtonLoading = false
        },
    },
});

export const {
    ChangeLoading,
    ChangeGebudgetNotes,
    ChangeSavedPayeeListCheck,
    ChangeAllSavedPayeeListCheck,
    ChangeCustomHeaerTitle,
    ChangeSelectedTransactionId,
} = sharedSlice.actions;

export default sharedSlice.reducer;