const baseURL = process.env.REACT_APP_BASE_URL
const baseUrlTesting = process.env.REACT_APP_BASE_URL_TESTING
const baseUrlLocal = process.env.REACT_APP_BASE_LOCAL
const plaidSandboxBaseUrl = process.env.REACT_APP_PLAID_SANDBOX_BASE_URL
const plaidDevelopmentBaseUrl = process.env.REACT_APP_PLAID_DEVELOPMENT_BASE_URL

export {
    baseURL,
    baseUrlLocal,
    baseUrlTesting,
    plaidSandboxBaseUrl,
    plaidDevelopmentBaseUrl,
}