import moment from "moment";
import React, { useEffect } from "react";
import ReactDatetime from "react-datetime";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import {
    Button, Col, Form, FormGroup, Input,
    Modal, Row
} from "reactstrap";
import { ChangeAddMortgageModal, PostMortgage } from './Store/mortgageSlice';


function AddMortgageModal(props) {

    const defaultValues = {
        name: null,
        amount: "0",
        startDate: null,
        endDate: null,
    }

    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const { addMortgageModal } = useSelector(state => state.mortgage)

    useEffect(() => {
    }, [])

    return (
        <Modal
            isOpen={addMortgageModal}
            toggle={() => dispatch(ChangeAddMortgageModal(!addMortgageModal))} addMortgageModal
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeAddMortgageModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-info" style={{ marginTop: "1rem" }} >Add Mortgage</h5>
            </div>
            <div className="modal-body">
                <Row>
                    <Col md="6">
                        <label htmlFor="">Name</label>
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        value={value}
                                        onChange={(e) => onChange(e.target.value)}
                                        type="text"
                                        placeholder="Enter mortgage name"
                                    />
                                )}
                                name="name"
                            />
                            {errors.name &&
                                <div className="text text-danger error-text">Enter mortgage name</div>
                            }
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <label htmlFor="">Amount</label>
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        value={value}
                                        onChange={(e) => onChange(e.target.value)}
                                        type="number"
                                        placeholder="Enter amount"
                                    />
                                )}
                                name="amount"
                            />
                            {errors.amount &&
                                <div className="text text-danger error-text">Enter amount</div>
                            }
                        </FormGroup>

                    </Col>
                    <Col md="6">
                        <label htmlFor="">Start Date</label>
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => {
                                    let date
                                    if (value) {
                                        if (value.includes('/')) {
                                            date = value.split("/")
                                            date = new Date(date[2], date[1] - 1, date[0])
                                        }
                                        else {
                                            date = value.split(" ")[0].split('-')
                                            date = new Date(date[0], date[1] - 1, date[2])
                                        }

                                    } else {
                                        date = null
                                    }
                                    // let date = value ? new Date(value) : new Date()
                                    return (
                                        <ReactDatetime
                                            onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                            value={date}
                                            placeholder="Select start date"
                                            inputProps={{
                                                className: "form-control ",
                                                placeholder: `Select start date`,
                                                readOnly: true,
                                            }}
                                            timeFormat={false}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            closeOnScroll={true}
                                        />
                                    )
                                }}
                                name="startDate"
                            />
                            {errors.startDate &&
                                <div className="text text-danger error-text">Select start date</div>
                            }
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <label htmlFor="">End Date</label>
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => {
                                    let date
                                    if (value) {
                                        if (value.includes('/')) {
                                            date = value.split("/")
                                            date = new Date(date[2], date[1] - 1, date[0])
                                        }
                                        else {
                                            date = value.split(" ")[0].split('-')
                                            date = new Date(date[0], date[1] - 1, date[2])
                                        }

                                    } else {
                                        date = null
                                    }
                                    // let date = value ? new Date(value) : new Date()
                                    return (
                                        <ReactDatetime
                                            onChange={(value) => onChange(moment(value.toDate()).format("DD/MM/YYYY"))}
                                            value={date}
                                            placeholder="Select end date"
                                            inputProps={{
                                                className: "form-control ",
                                                placeholder: `Select end date`,
                                                readOnly: true,
                                            }}
                                            timeFormat={false}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            closeOnScroll={true}
                                        />
                                    )
                                }}
                                name="endDate"
                            />
                            {errors.endDate &&
                                <div className="text text-danger error-text">Select end date</div>
                            }
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <label htmlFor="">Interest Rate (%)</label>
                        <FormGroup>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        value={value}
                                        onChange={(e) => onChange(e.target.value)}
                                        type="number"
                                        placeholder="Enter interest rate"
                                    />
                                )}
                                name="interestRate"
                            />
                            {errors.interestRate &&
                                <div className="text text-danger error-text">Enter interest rate</div>
                            }
                        </FormGroup>

                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link btn-link-cancel"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeAddMortgageModal(!addMortgageModal))}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={handleSubmit(async (data) => {

                            const { startDate, endDate } = data
                            let startDateSplited = startDate.split('/')
                            let endDateSplited = endDate.split('/')
                            let startDateConverted = new Date(startDateSplited[2], startDateSplited[1] - 1, startDateSplited[0])
                            let endDateConverted = new Date(endDateSplited[2], endDateSplited[1] - 1, endDateSplited[0])
                            console.log(startDateConverted)
                            console.log(endDateConverted)
                            if (startDateConverted >= endDateConverted) {
                                toast("End date must come after start date")
                            } else {
                                await dispatch(PostMortgage(data))
                                reset(defaultValues)
                            }
                        })}
                    >
                        Add
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddMortgageModal