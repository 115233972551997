import React, { createContext, useReducer } from 'react';
import AddAccount from '../App/Screens/AddAccount/AddAccount.js';
import AllBudgets from '../App/Screens/AllBudgets/AllBudgets.js';
import SignUp from "../App/Screens/Auth/SignUp.js";
import BudgetSettings from '../App/Screens/BudgetSettings/BudgetSettings.js';
import Dashboard from "../App/Screens/Dashboard/Dashboard.js";
import GeneralSettings from '../App/Screens/GeneralSettings/GeneralSettings.js';
import ManagePayees from '../App/Screens/ManagePayees/ManagePayees.js';
import MyBudget from '../App/Screens/MyBudget/MyBudget.js';
import NewBudget from '../App/Screens/NewBudget/NewBudget.js';
import Reports from '../App/Screens/Reports/Reports.js';
import UserProfile from "../App/Screens/UserProfile/UserProfile.js";
import LockScreen from "../views/pages/LockScreen.js";
import StockDetails from '../views/tables/StockDetails.js';
import Stocks1 from '../views/tables/Stocks1';
import AppReducer from './AppReducer';


// Initial State
const initialState = {

    categoryGroups: [
        {
            id: '0',
            name: 'Monthly Expenses',
            expenses: [
                {
                    id: '0',
                    expenseName: 'Housing',
                    goalAmount: '890',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '1',
                    expenseName: 'Car',
                    goalAmount: '890',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },

                {
                    id: '2',
                    expenseName: 'Insurance',
                    goalAmount: '239',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '3',
                    expenseName: 'Cell Phone',
                    goalAmount: '547',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '4',
                    expenseName: 'Gym Membership ',
                    goalAmount: '234',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'

                }
            ]
        },
        {
            id: '1',
            name: 'Frequent Expenses',
            expenses: [
                {
                    id: '0',
                    expenseName: 'Property tax, Car Insurance',
                    goalAmount: '532',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '1',
                    expenseName: 'Home Owners insurance',
                    budgetedAmount: '20',
                    goalAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '2',
                    expenseName: 'Clothing',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '3',
                    expenseName: 'Deodorant',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '4',
                    expenseName: 'Toiletries',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '5',
                    expenseName: 'Medical Bills',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
            ],
        },
        {
            id: '3',
            name: 'Giving',
            expenses: [
                {
                    id: '0',
                    expenseName: 'Christmas',
                    goalAmount: '532',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '1',
                    expenseName: 'Birthdays',
                    budgetedAmount: '20',
                    goalAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '2',
                    expenseName: 'Monther’s Day',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '3',
                    expenseName: 'Father’s Day',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '4',
                    expenseName: 'Valentine’s Day',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
            ],
        },
        {
            id: '4',
            name: 'Short-term savings',
            expenses: [
                {
                    id: '0',
                    expenseName: 'Appliance',
                    goalAmount: '532',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '1',
                    expenseName: 'Furniture',
                    budgetedAmount: '20',
                    goalAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '2',
                    expenseName: 'Large tools',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '3',
                    expenseName: 'Fishing gear',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },
                {
                    id: '4',
                    expenseName: 'Expensive shoes',
                    goalAmount: '900',
                    budgetedAmount: '20',
                    spentAmount: '10',
                    unspendAmount: '0'
                },

            ],
        },

    ],

    transactions: [
        {
            id: '1',
            date: 'Auto Maintenence',
            payee: '/images/gary.png',
            category: '20',
            memo: '10',
            outflow: '40',
            inflow: '40'
        },
        {
            id: '2',
            date: 'Auto Maintenence',
            payee: '/images/gary.png',
            category: '20',
            memo: '10',
            outflow: '40',
            inflow: '40'

        },


    ],

    accounts: [
        {
            id: '1',
            name: 'savings',
            totalBalance: '200',
            clearedBalance: '22',
            unClearedBalance: '33',
            workingBalance: '344',
        }
    ],


    routes: [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: "nc-icon nc-bank",
            component: Dashboard,
            layout: "/admin",
        },

        {
            path: "/ManagePayees",
            name: "Manage Payees",
            icon: "nc-icon nc-bank",
            component: ManagePayees,
            layout: "/admin",
            invisible: true,
        },

        {
            collapse: true,
            name: "Budgeting",
            icon: "nc-icon nc-book-bookmark",
            state: "pagesCollapse",
            views: [

                {
                    path: "/budget",
                    name: "My Budget",
                    mini: "B",
                    component: MyBudget,
                    layout: "/admin",
                },


                {
                    path: "/my-budget",
                    name: "New Budget",
                    mini: "NB",
                    component: NewBudget,
                    layout: "/admin",
                },
                {
                    path: "/all-budgets",
                    name: "All Budgets",
                    mini: "AB",
                    component: AllBudgets,
                    layout: "/admin",
                },

            ],
        },

        {
            path: "/lock-screen",
            name: "LockScreen",
            mini: "LS",
            component: LockScreen,
            layout: "/auth",
            invisible: true,
        },

        {
            path: "/user-profile",
            name: "UserProfile",
            mini: "UP",
            component: UserProfile,
            layout: "/admin",
            invisible: true,
        },

        {
            path: "/reports",
            name: "Reporting",
            icon: "fas fa-chart-pie",
            component: Reports,
            layout: "/admin",
        },

        {
            collapse: true,
            name: "Accounts Settings",
            icon: "fas fa-user-circle",
            state: "tablesCollapse",
            views: [
                {
                    path: "/add-account",
                    name: "Add Account",
                    mini: "AA",
                    component: AddAccount,
                    layout: "/admin",
                },

            ],
        },

        {
            path: "/BudgetSettings",
            name: "Budget Settings",
            mini: "RF",
            component: BudgetSettings,
            layout: "/admin",
            invisible: true,
        },
        {
            path: "/AccountSettings",
            name: "Account Settings",
            mini: "EF",
            component: GeneralSettings,
            layout: "/admin",
            invisible: true,

        },

        {
            path: "/sign-up",
            name: "Log Out",
            mini: "VF",
            component: SignUp,
            layout: "/auth",
            invisible: true,

        },

        {
            path: "/stocks",
            name: "Stocks",
            icon: "fas fa-chart-line",
            component: Stocks1,
            layout: "/admin",
        },

        {
            path: "/stock-details",
            name: "Stock Details",
            icon: "fas fa-chart-line",
            component: StockDetails,
            layout: "/admin",
            invisible: true,
        },
    ]

}










//  Create Context

export const GlobalContext = createContext(initialState)

// Provider Component

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    function addMonthlyExpense(monthlyExpense) {
        dispatch({
            type: 'ADD_MONTHLYEXPENSE',
            payload: monthlyExpense
        });
    }


    return (
        <GlobalContext.Provider value={{
            categoryGroups: state.categoryGroups,

            transactions: state.transactions,
            routes: state.routes,
            addMonthlyExpense


        }}>
            {children}





        </GlobalContext.Provider>






    )
}

