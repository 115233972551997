
import React from "react";
import NotificationAlert from "react-notification-alert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { Line, Bar, Pie } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col, Modal,
    UncontrolledTooltip, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import Form from "reactstrap/lib/Form";

import {
    chartExample1,
    chartExample4,
    chartExample9,
    chartExample10,
    chartExample11,
    chartExample12,
} from "variables/charts.js";

class Stocks1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            modalClassic: false,
        };
    }
    toggleModalClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic,
        });
    };
    render() {
        return (
            <>
                <div className="content">

                    <Row className="mt-3">

                        <Col md="12">
                            <Card>


                                <CardBody>
                                    <div >
                                        <Row>
                                            <Col md="8">
                                                <h5 className="text-left">Stock Market</h5>
                                            </Col>
                                            <Col md="4">
                                                <InputGroup className="no-border float-right">
                                                    <Input defaultValue="" placeholder="Search..." type="text" />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-zoom-split" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                        </Row>

                                    </div>

                                    <Table hover className="table-bordered account-table" style={{ fontSize: '12px' }}>

                                        <thead className="text-dark bg-light" >
                                            <tr >
                                                <th className="border">Symbol</th>
                                                <th className="border" >Name</th>
                                                <th className="border" >Last Price</th>
                                                <th className="border" >Change</th>
                                                <th className="border" >%Change</th>
                                                <th className="border" >Week Range</th>
                                                <th className="border" >Day Chart</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>  <Link to="/admin/stock-details"> BTCUD=X </Link></td>
                                                <td><Link to="/admin/stock-details"> BTCUD=X </Link></td>
                                                <td>11.79</td>
                                                <td className="text-success">+0.65</td>
                                                <td className="text-success">+0.2%</td>
                                                <td>
                                                    <input type="range" class="form-range" id="customRange1" />
                                                    <div className="d-flex justify-content-between p-0 mt-0">
                                                        <span>  0.00</span>
                                                        <span>   0.00</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-icon"
                                                        color="info"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        type="button"
                                                        onClick={this.toggleModalClassic}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <Link to="/admin/stock-details"> ABCHD=X </Link></td>
                                                <td><Link to="/admin/stock-details"> ABCHD=X </Link></td>
                                                <td>18.783</td>
                                                <td className="text-danger">-0.65</td>
                                                <td className="text-danger">-0.2%</td>
                                                <td>
                                                    <input type="range" class="form-range" id="customRange1" />
                                                    <div className="d-flex justify-content-between p-0 mt-0">
                                                        <span>  0.00</span>
                                                        <span>   0.00</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-icon"
                                                        color="info"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        type="button"
                                                        onClick={this.toggleModalClassic}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/admin/stock-details"> OSUD=X </Link></td>
                                                <td><Link to="/admin/stock-details"> OSUD=X </Link></td>
                                                <td>18.783</td>
                                                <td className="text-success">+0.65</td>
                                                <td className="text-success">+0.2%</td>
                                                <td>
                                                    <input type="range" class="form-range" id="customRange1" />
                                                    <div className="d-flex justify-content-between p-0 mt-0">
                                                        <span>  0.00</span>
                                                        <span>   0.00</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-icon"
                                                        color="info"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        type="button"
                                                        onClick={this.toggleModalClassic}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/admin/stock-details"> LCKOS=X </Link></td>
                                                <td><Link to="/admin/stock-details">LCKOS=X </Link></td>
                                                <td>18.783</td>
                                                <td className="text-dangr">-0.096</td>
                                                <td className="text-danger">-0.6%</td>
                                                <td>
                                                    <input type="range" class="form-range" id="customRange1" />
                                                    <div className="d-flex justify-content-between p-0 mt-0">
                                                        <span>  0.00</span>
                                                        <span>   0.00</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-icon"
                                                        color="info"
                                                        id="tooltip264453216"
                                                        size="sm"
                                                        type="button"
                                                        onClick={this.toggleModalClassic}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </Button>{" "}
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip264453216"
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>






                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>


                    <Modal
                        isOpen={this.state.modalClassic}
                        toggle={this.toggleModalClassic}
                        className="modal-lg"
                    >
                        <div className="modal-header  justify-content-start">
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleModalClassic}
                            >
                                <i className="nc-icon nc-simple-remove" />
                            </button>
                            <h6 className="title title-up">Day Chart</h6>
                        </div>
                        <div className="modal-body">

                            <Card className="bg-light">
                                <CardBody>
                                    <Row>
                                        <Col md="8" className="ml-auto mr-auto">
                                            <Line
                                                data={chartExample9.data}
                                                options={chartExample9.options}
                                                width='120px'
                                                height='100%'

                                            />

                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>


                        </div>
                        {/* <div className="modal-footer">
                            <div className="left-side">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={this.toggleModalClassic}
                                >
                                    Cancel
                            </Button>
                            </div>
                            <div className="divider" />
                            <div className="right-side">
                                <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                >
                                    Add
                            </Button>
                            </div>
                        </div> */}
                    </Modal>
                </div>
            </>
        );
    }
}

export default Stocks1;
