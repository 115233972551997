import moment from 'moment'
import store from '../Store'

const GetMiniName = (name) => {
    // if (name?.length > 6) {
    let matches = name.match(/\b(\w)/g);
    let converted = matches.join("");
    name = converted
    return name
    // }
}

const CheckIfLate = (date) => {
    let splitedDate = date.split('-')
    let today = new Date()
    let mortgageDate = new Date(splitedDate[0], splitedDate[1] - 1, splitedDate[2])
    if (today > mortgageDate)
        return true
    return false
}

const WeekOfMonth = (mJsDate) => {

    let date = moment(mJsDate)

    return Math.ceil(date.date() / 7);
}

const GetAmount = (amount) => {

    let calculatedAmount = parseFloat(amount)

    let numberFormat = store.getState().userData?.selectedBudgetNumberFormat

    if (numberFormat == 1 || numberFormat == 3)
        calculatedAmount = (calculatedAmount).toLocaleString(
            'en-US',
            { minimumFractionDigits: 2 }
        );
    else if (numberFormat == 2 || numberFormat == 4)
        calculatedAmount = (calculatedAmount).toLocaleString(
            'hi-IN',
            { minimumFractionDigits: 2 }
        )

    if (numberFormat == 4 || numberFormat == 3)
        calculatedAmount = calculatedAmount.replace(/,/g, ' ')

    let selectedBudgetCurrencyPlacement = store.getState().userData?.selectedBudgetCurrencyPlacement

    let currencies = store.getState().newBudget?.currencies
    let currencyId = store.getState().userData?.selectedBudgetCurrency
    let currencySymbol = currencies.find(obj => obj.value == currencyId)?.symbol
    currencySymbol = currencySymbol ? currencySymbol : ''

    if (selectedBudgetCurrencyPlacement == "1")
        calculatedAmount = "\u202A" + currencySymbol + "\u202C" + calculatedAmount
    else if (selectedBudgetCurrencyPlacement == "2")
        calculatedAmount = `${calculatedAmount + currencySymbol}`
    else if (selectedBudgetCurrencyPlacement == "3")
        calculatedAmount = `${calculatedAmount}`

    return calculatedAmount
}

const GetDate = (date) => {
    let formattedDate
    let dateFormat = store.getState().userData?.selectedBudgetDateFormat
    let dateFormats = store.getState().newBudget?.dateFormats

    dateFormat = dateFormats.find(obj => obj.value == dateFormat)?.label

    formattedDate = moment(date).format(dateFormat)

    return formattedDate
}

const GetDateFormat = () => {
    let dateFormat = store.getState().userData?.selectedBudgetDateFormat
    let dateFormats = store.getState().newBudget?.dateFormats

    dateFormat = dateFormats.find(obj => obj.value == dateFormat)?.label

    return dateFormat
}

const CompareYears = (a, b) => {
    if (a.value < b.value) {
        return -1;
    }
    if (a.value > b.value) {
        return 1;
    }
    return 0;
}

const CreateHeaderTitle = (title) => {
    let headerTitle = title.replaceAll('-', ' ')
    return headerTitle
}

const getOS = () => {
    if (navigator.userAgent.includes("Mac"))
        return "Mac"
    else if (navigator.userAgent.includes("Windows"))
        return "Windows"
    else
        return "Unknown"
}

const getRandomColorCode = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const getFixedColorCode = (index) => {
    let colors = [
        '#FF0000',
        '#FFA500',
        '#FFFF00',
        '#008000',
        '#0000FF',
        '#4B0082',
        '#9400D3',
        '#FF00FF',
        '#FF0000',
        '#FFA500',
        '#FFFF00',
        '#008000',
        '#0000FF',
        '#4B0082',
        '#9400D3',
        '#FF00FF'
    ]
    return colors[index]
}

export {
    GetMiniName,
    CheckIfLate,
    WeekOfMonth,
    GetAmount,
    GetDate,
    GetDateFormat,
    CompareYears,
    CreateHeaderTitle,
    getOS,
    getRandomColorCode,
    getFixedColorCode,
}