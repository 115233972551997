
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Button, FormGroup, Input, PopoverBody, UncontrolledPopover } from "reactstrap";
import { GetAmount } from '../../../Config/Helper';
import { AllocateFundsToExpense, AllocateFundsToGoal, GetExpanseNamesByEnvelopeId } from '../Store/sharedSlice';

const FundsToAllotBtn = (props) => {

    const defaultValues = {
        amountToAllocate: "0",
        transferingTo: null,
        envelopeValue: null,
        expenseValue: null,
    }
    const transferingToList = [
        { value: 1, label: "Goals" },
        { value: 2, label: "Envelope" },
    ]

    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors }, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    let watchTransferingTo = watch('transferingTo')

    const {
        envelopeNames,
        expenseNames,
        fundsToAllot,
        submitButtonLoading,
        goalNames,
        expenseNamesLoading,
    } = useSelector(state => state.shared)

    const [allotFundsPopover, setAllotFundsPopover] = useState(false);
    const [transferingTo, setTransferingTo] = useState(1);

    const toggleAllotFundsPopover = () => setAllotFundsPopover(!allotFundsPopover);

    useEffect(() => {
    }, [])

    return (
        <>
            <Button
                color={fundsToAllot < 0 ? "danger" : "success"}
                id="allot-funds-popover"
                style={{ height: "3rem", width: 200 }}
            >
                <span style={{ fontSize: "1rem" }}>
                    {`${GetAmount(fundsToAllot)}`}
                </span>
                <br />
                <span className="font-weight-normal">
                    {fundsToAllot < 0 ?
                        "Fix This"
                        :
                        "Funds To Allot"
                    }
                </span>
                {" "}
                <span className="btn-label btn-label-right">
                    <i className="nc-icon nc-minimal-right" />
                </span>
            </Button>
            <UncontrolledPopover placement="bottom" isOpen={allotFundsPopover} target="allot-funds-popover" trigger="legacy" toggle={toggleAllotFundsPopover}>
                <PopoverBody className="custom-popover-body">
                    <FormGroup>
                        <label htmlFor="">Move</label>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                                pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    placeholder={GetAmount(0)}
                                    onChange={(event) => onChange(event.target.value)}
                                    value={value}
                                    type="number"
                                />
                            )}
                            name="amountToAllocate"
                        />
                        {errors.amountToAllocate &&
                            <div className="text text-danger error-text">Enter amount</div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="">To</label>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={transferingTo}
                                    onChange={(value) => {
                                        onChange(value)
                                        setTransferingTo(value)
                                        reset({
                                            ...getValues(),
                                            envelopeValue: null,
                                            expenseValue: null,
                                        })
                                    }}
                                    options={transferingToList}
                                    placeholder="Select.."
                                />
                            )}
                            name="transferingTo"
                        />
                        {errors.transferingTo &&
                            <div className="text text-danger error-text">Select Goals/Envelopes</div>
                        }
                        <br />
                        {watchTransferingTo &&
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={value}
                                        onChange={(value) => {
                                            onChange(value)
                                            reset({
                                                ...getValues(),
                                                expenseValue: null,
                                            })
                                            if (transferingTo.value == 2)
                                                dispatch(GetExpanseNamesByEnvelopeId(value.value))
                                        }}
                                        options={transferingTo.value == 1 ? goalNames : envelopeNames}
                                        placeholder={`Select ${transferingTo.value == 1 ? "Goal" : "Envelope"}`}
                                    />
                                )}
                                name="envelopeValue"
                            />
                        }
                        {errors.envelopeValue &&
                            <div className="text text-danger error-text">Select an envelope</div>
                        }
                        <br />
                        {(watchTransferingTo && transferingTo.value) == 2 &&
                            <>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: transferingTo.value != 1,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            noOptionsMessage={() => expenseNamesLoading ? 'Loading...' : 'No Expenses with target'}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            value={value}
                                            onChange={(value) => {
                                                onChange(value)
                                            }}
                                            options={transferingTo.value == 1 ? [] : expenseNames}
                                            placeholder={`Select ${"Expense"}`}
                                        />
                                    )}
                                    name="expenseValue"
                                />
                                {errors.expenseValue &&
                                    <div className="text text-danger error-text">Select an expense</div>
                                }
                            </>
                        }
                    </FormGroup>
                    <div className="text-right">
                        <a
                            onClick={() => setAllotFundsPopover(false)}
                            className="btn-danger custom-btn p-2 "
                        >
                            <span style={{ color: 'white', marginLeft: 5, marginRight: 5 }}>Cancel</span>
                        </a>
                        <button
                            onClick={handleSubmit(async (data) => {
                                console.log(data)
                                if (watchTransferingTo.value == 2)
                                    await dispatch(AllocateFundsToExpense(data))
                                else
                                    await dispatch(AllocateFundsToGoal(data))
                                setAllotFundsPopover(false)
                                reset({
                                    amountToAllocate: "0",
                                    transferingTo: null,
                                    envelopeValue: null,
                                    expenseValue: null,
                                })
                            })}
                            disabled={submitButtonLoading}
                            className="btn-btn-sm btn-success btn-round custom-btn "
                            style={{
                                minHeight: 31,
                            }}
                        >
                            {submitButtonLoading ?
                                <ScaleLoader
                                    color={"#fff"}
                                    loading={true}
                                    size={50}
                                    height={10}
                                    width={2}
                                />
                                :
                                "Ok"
                            }
                        </button>
                    </div >
                </PopoverBody >
            </UncontrolledPopover >
        </>
    );
}

export default FundsToAllotBtn