import { baseURL } from 'App/Config/baseURL';
import { EditImage } from '../../UserProfile/Store/userProfileSlice';
import React, { useState } from 'react';
import { useRef } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import './CustomAvatar.css'

function CustomAvatar(props) {

    const {
        size = "100px",
        upload = false,
    } = props || {}

    const dispatch = useDispatch()
    const inputRef = useRef()

    const { userData } = useSelector(state => state.userData)
    const {
        first_name,
        image,
    } = userData?.user || {}

    const [preview, setPreview] = useState(null)

    React.useEffect(() => {
    }, [])

    return (
        <div className='avatar-container'>
            <input
                ref={inputRef}
                className='avatar-input'
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={async (e) => {
                    if (e.target.files[0]) {
                        setPreview(URL.createObjectURL(e.target.files[0]))
                        dispatch(EditImage(e.target.files[0]))
                    } else
                        console.error("No file found")
                }}
            />
            <div className='avatar-wrapper'>
                <Avatar
                    src={preview ? preview : `${baseURL}/${image}`}
                    className="profle-avatar"
                    name={first_name?.[0]}
                    round
                    size={size}
                />
                {upload &&
                    <span className='add-btn'>
                        <i
                            onClick={() => {
                                console.log("inputRef===>", inputRef)
                                inputRef.current.click()
                            }}
                            className="nc-icon nc-simple-add"
                        />
                    </span>
                }
            </div>
        </div>
    )
}

export default CustomAvatar