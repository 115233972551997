import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Form, Modal, Row
} from "reactstrap";
import { CheckIfLate, GetAmount, GetDate } from "../../../Config/Helper";
import { ChangeMortgageDetailModal } from "../../Mortgage/Store/mortgageSlice";
import moment from 'moment'

function MortgageDetialModal(props) {

    const dispatch = useDispatch()


    const { mortgageDetailModal, currentMortgageDetail } = useSelector(state => state.mortgage)

    const {
        amount,
        budget_id,
        created_at,
        id,
        months,
        mortgage_id,
        mortgage_status,
        mortgage_status_id,
        updated_at,
        extra_amount,
        interest,
        loan_amount,
    } = currentMortgageDetail || {}

    let isLate = false
    if (months) {
        isLate = CheckIfLate(months)
    }

    let date = null
    if (months) {
        date = months.split("-")
        date = new Date(date[0], date[1] - 1, date[2])
    }

    useEffect(() => {
    }, [])

    return (
        <Modal
            isOpen={mortgageDetailModal}
            toggle={() => dispatch(ChangeMortgageDetailModal(!mortgageDetailModal))}
            className="modal-lg"
        >
            <div className="modal-header  justify-content-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => dispatch(ChangeMortgageDetailModal(false))}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="text-info" >Mortgage Detail</h5>
            </div>
            <div className="modal-body">
                <Form>
                    <Row>
                        <Col md="3">
                            <label style={{ fontWeight: 'bold' }} >Payment Date:</label>
                        </Col>
                        <Col md="3">
                            <span> {GetDate(date)}</span>
                        </Col>
                        <Col md="3">
                            <label style={{ fontWeight: 'bold' }} >Status:</label>
                        </Col>
                        <Col md="3">
                            {mortgage_status_id == 3 ?
                                <span> Paid</span>
                                :
                                <>
                                    {(mortgage_status_id == 1 || isLate) ?
                                        <span> Late</span>
                                        :
                                        <span> Unpaid</span>
                                    }
                                </>
                            }
                        </Col>
                        <Col md="3">
                            <label style={{ fontWeight: 'bold' }} >{mortgage_status_id == 3 ? "Amount Payed" : "Amount To Pay"}:</label>
                        </Col>
                        <Col md="3">
                            <span> {GetAmount(amount)}</span>
                        </Col>
                        {mortgage_status_id == 3 &&
                            <>
                                <Col md="3">
                                    <label style={{ fontWeight: 'bold' }}>Extra Amount Payed</label>
                                </Col>
                                <Col md="3">
                                    <span> {GetAmount(extra_amount)}</span>
                                </Col>
                            </>
                        }
                        <Col md="3">
                            <label style={{ fontWeight: 'bold' }}>Interest:</label>
                        </Col>
                        <Col md="3">
                            <span> {GetAmount(interest)}</span>
                        </Col>
                        <Col md="3">
                            <label style={{ fontWeight: 'bold' }}>Remaining Loan</label>
                        </Col>
                        <Col md="3">
                            <span> {GetAmount(loan_amount)}</span>
                        </Col>
                        {false && //!todo: use account (we used for payment) when getting from API
                            <>
                                <Col md="3">
                                    <label style={{ fontWeight: 'bold' }}>Account Name</label>
                                </Col>
                                <Col md="3">
                                    <span> Freelancing</span>
                                </Col>
                            </>
                        }
                    </Row>
                </Form>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => dispatch(ChangeMortgageDetailModal(!mortgageDetailModal))}
                    >
                        Close
                    </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                    <Button
                        className="btn-link"
                        color="primary"
                        type="button"
                        onClick={() => dispatch(ChangeMortgageDetailModal(false))}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default MortgageDetialModal