
import { GetAmount } from "App/Config/Helper";
import moment from "moment";
import React from "react";
import {
    FcCurrencyExchange,
    FcPlus
} from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    Card, CardBody,
    CardFooter,
    CardTitle, Col, Row
} from "reactstrap";
import { GetAllDataOfBudget } from "../Shared/Store/sharedSlice";
import { ChangeSelectedBudgetId } from "../Shared/Store/userDataSlice";
import './AllBudgets.css';
import { GetAllBudgets } from "./Store/allBudgetSlice";

function AllBudgets(props) {

    const history = useHistory()
    const dispatch = useDispatch()

    const { budgets } = useSelector(state => state.allBudget)

    const handleNewBudget = async () => {
        history.push('/admin/my-budget/0')
    }


    React.useEffect(() => {
        dispatch(GetAllBudgets())
    }, [])

    function RenderBudgets() {
        return budgets?.map((item, key) => {
            const {
                budget_name,
                currency_id,
                currency_symbol_placement,
                date_format_id,
                id,
                number_format_id,
                user_id,
                updated_at,
                total_amount,
            } = item
            let selectedBudgetId = id
            return (
                <Col key={id} lg="4" style={{ marginBottom: 20 }}>
                    <Card
                        onClick={async () => {
                            await dispatch(ChangeSelectedBudgetId(item))
                            await dispatch(GetAllDataOfBudget({ selectedBudgetId }))
                            toast('Your budget is changed successfully!')
                            history.push('/admin/budget')
                        }}
                        className="card-pricing all-budget-cards h-100"
                    >
                        <CardBody>
                            <div className="card-icon icon-primary icon-flex">
                                <div className="budget-icon-wrapper">
                                    <FcCurrencyExchange className="budget-icon" />
                                </div>
                            </div>
                            <CardTitle tag="h6">{GetAmount(total_amount)}</CardTitle>
                            {budget_name}
                        </CardBody>
                        <CardFooter>
                            <hr />
                            <p className="font-italic">{`Last Used ${moment(updated_at).startOf('seconds').fromNow()}`}</p>
                        </CardFooter>
                    </Card>
                </Col>
            )
        })
    }

    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Row>
                            {RenderBudgets()}
                            <Col lg="4">
                                <Card
                                    onClick={handleNewBudget}
                                    className="card-pricing h-100 d-flex align-items-center all-budget-cards add-budget-card"
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                        <Link to="#">       <div className="card-icon icon-success">
                                            <div className="card-icon icon-primary icon-flex">
                                                <div className="budget-icon-wrapper">
                                                    <FcPlus className="budget-icon" />
                                                </div>
                                            </div>
                                        </div></Link>
                                        <Link to="#" >Add New Budget</Link>
                                    </CardBody>

                                </Card>
                            </Col>

                        </Row>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default AllBudgets;
