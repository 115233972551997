import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURL } from '../../../Config/baseURL';
import axios from 'axios';
import { apiInstance, apiNoLoaderInstance } from '../../../Config/AxiosInstances';
import { toast } from 'react-toastify';

export const DeleteUser = createAsyncThunk('myBudget/DeleteUser', async (params, { dispatch, getState }) => {
    try {

        let userId = getState().userData?.userData?.user?.id;

        let result = await apiInstance.get(`/api/delete-user/${userId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                return error.response
            })

        const { data, status } = result

        return { data, status }

    } catch (error) {
        console.error("error===>", error)
    }
})
export const ResendVerificationCode = createAsyncThunk('auth/ResendVerificationCode', async (params, { dispatch, getState }) => {
    try {
        const email = getState().auth.emailToVerify

        let result = await apiInstance.post('/api/resend-verification-email', {
            email,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("error===>", error)
    }
})
export const VerifyCode = createAsyncThunk('auth/VerifyCode', async (params, { dispatch, getState }) => {
    try {

        const code = params
        const email = getState().auth.emailToVerify

        let result = await apiInstance.post('/api/verify-email', {
            email,
            code,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        let budgetId = result.data?.data?.user?.budget_id
        let budget
        let customConfig = {
            headers: {
                Authorization: `Bearer ${result.data?.data?.token}`
            }
        }

        if (budgetId)
            budget = await apiInstance.get(`/api/budgets/${budgetId}`, customConfig).then(response => response)

        const { data, status } = result
        return { data, status, budget }

    } catch (error) {
        console.error("error===>", error)
    }
})
export const SignIn = createAsyncThunk('auth/SignIn', async (params, { dispatch, getState }) => {
    try {

        const { email, password } = params

        // let result = api.post('/login', params); //todo: try this method 

        let result = await apiNoLoaderInstance.post('/api/login', {
            'email': email,
            'password': password,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        let budgetId = result.data?.data?.user?.budget_id
        let budget
        let customConfig = {
            headers: {
                Authorization: `Bearer ${result.data?.data?.token}`
            }
        }

        if (budgetId)
            budget = await apiNoLoaderInstance.get(`/api/budgets/${budgetId}`, customConfig).then(response => response)

        const { data, status } = result
        return { data, status, budget }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const Register = createAsyncThunk('auth/Register', async (params, { dispatch, getState }) => {
    try {
        const { email, firstname, lastname, password } = params

        let result = await apiInstance.post('/api/signup', {
            'first_name': firstname,
            'last_name': lastname,
            'email': email,
            'password': password,
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})
export const Logout = createAsyncThunk('auth/Logout', async (params, { dispatch, getState }) => {
    try {
        let result = await axios({
            baseURL: baseURL,
            timeout: 10000,
            method: 'post',
            url: '/api/logout',
            headers: {
                'Accept': 'Application/json',
                'Access-Control-Max-Age': 0,
                'Authorization': `Bearer ${getState().userData?.userData?.token}`
            }
        }).then(function (response) {
            return response
        }).catch(function (error) {
            return error.response
        })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        emailToVerify: null,
    },
    reducers: {
        ChangeEmailToVerify: (state, action) => {
            state.emailToVerify = action.payload
        },
    },
    extraReducers: {
        ['auth/ResendVerificationCode/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}

            if (status >= 200 && status < 300)
                toast(data?.message)
            else if (status >= 400 && status < 500)
                toast("Email is not found in our system")
            else
                console.error("Something went wrong")
        },
        ['auth/SignIn/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}

            if (status >= 200 && status < 300)
                if (!data?.data?.user?.is_email_verified)
                    state.emailToVerify = data?.data?.user?.email
        },
        ['auth/Register/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}

            if (status >= 200 && status < 300)
                if (!data?.data?.user?.is_email_verified)
                    state.emailToVerify = data?.data?.user?.email
        },
    },
});

export const {
    ChangeEmailToVerify,
} = authSlice.actions;

export default authSlice.reducer;