import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import accounts from '../Screens/Accounts/Store/accountsSlice'
import auth from '../Screens/Auth/Store/authSlice'
import creditRepair from '../Screens/CreditRepair/Store/creditRepairSlice'
import myGoals from '../Screens/MyGoals/Store/myGoalsSlice'
import reports from '../Screens/Reports/Store/reportsSlice'
import shared from '../Screens/Shared/Store/sharedSlice'
import userData from '../Screens/Shared/Store/userDataSlice'
import allBudget from '../Screens/AllBudgets/Store/allBudgetSlice'
import newBudget from '../Screens/NewBudget/Store/newBudgetSlice'
import addAccount from '../Screens/AddAccount/Stote/addAccountSlice'
import myBudget from '../Screens/MyBudget/Store/myBudgetSlice'
import userProfile from '../Screens/UserProfile/Store/userProfileSlice'
import mortgage from '../Screens/Mortgage/Store/mortgageSlice'
import managePayees from '../Screens/ManagePayees/Store/managePayeesSlice'
import customCalendar from '../Screens/Calendar/Store/customCalendarSlice'
import dashboard from '../Screens/Dashboard/Store/dashboardSlice'
import displaySettings from '../Screens/DisplaySettings/Store/displaySettingsSlice'

const reducers = combineReducers({
    accounts,
    addAccount,
    allBudget,
    auth,
    creditRepair,
    customCalendar,
    dashboard,
    displaySettings,
    managePayees,
    mortgage,
    myBudget,
    myGoals,
    newBudget,
    reports,
    shared,
    userData,
    userProfile,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userData']
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;