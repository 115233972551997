import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstance } from '../../../Config/AxiosInstances';
import moment from 'moment';

export const GetCalendar = createAsyncThunk('customCalendar/GetCalendar', async (params, { dispatch, getState }) => {
    try {

        const { selectedBudgetId } = getState().userData

        let result = await apiInstance.get(`/api/get-calender/${selectedBudgetId}`)
            .then(function (response) {
                return response
            }).catch(function (error) {
                console.log("error")
                console.log(error)
                return error.response
            })

        const { data, status } = result
        return { data, status }

    } catch (error) {
        console.error("ERROR")
        console.error(error)
    }
})

const customCalendarSlice = createSlice({
    name: 'customCalendar',
    initialState: {
        calendarEvents: [],
    },
    reducers: {
    },
    extraReducers: {
        ['customCalendar/GetCalendar/fulfilled']: (state, action) => {
            const { data, status } = action.payload || {}
            if (status >= 200 && status < 300) {
                const { envelop, goal } = data?.data
                let calendarEvents = []

                //* expenses
                envelop.map(item => {
                    const { sub_envelop, } = item || {}
                    sub_envelop.map(subItem => {
                        const {
                            envelop_sub_category_name,
                            total_budget,
                            envelop_goal,
                        } = subItem || {}
                        const {
                            id: goalId,
                            total_amount,
                            required_by,
                        } = envelop_goal[0] || {}

                        calendarEvents.push({
                            id: goalId,
                            type: "objective",
                            title: envelop_sub_category_name,
                            amount: total_amount,
                            totalBudget: total_budget,
                            start: required_by,
                            end: required_by,
                        })
                    })
                })

                //* goals
                goal.map(item => {
                    const {
                        id,
                        user_id,
                        budget_id,
                        title,
                        goal_type,
                        amount,
                        total_budget,
                        spent,
                        required_by,
                        created_at,
                        updated_at,
                    } = item

                    let requiredBy = new Date(required_by)

                    calendarEvents.push({
                        id: id,
                        type: goal_type == 0 ? "goal" : "obligation",
                        title,
                        amount,
                        totalBudget: total_budget,
                        start: requiredBy,
                        end: requiredBy,
                    })
                })

                state.calendarEvents = calendarEvents
            }
        },
    },
});

export const {
} = customCalendarSlice.actions;

export default customCalendarSlice.reducer;