
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import ReactDatetime from "react-datetime";
import { Controller, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import {
    chartExample4
} from "variables/charts.js";
import ExpenseTransactionsModal from '../MyBudget/ExpenseTransactionsModal';
import { GetTransactionsByExpenseId } from '../MyBudget/Store/myBudgetSlice';
import { GetReport } from './Store/reportsSlice';
import './Reports.css'

function Reports(props) {
    const options = {
        legend: {
            display: true,
            position: "right"
        }
    }

    const dispatch = useDispatch()

    const {
        envelopesData,
        envelopesWithExpenseData,
        startDate,
        endDate,
        totalSpending,
        averageSpending,
    } = useSelector(state => state.reports)
    const {
        accountsList,
        selectEnvelopeMortgageGoalList,
    } = useSelector(state => state.shared)

    const defaultValues = {
        envelopes: [],
        accounts: [],
        to: undefined,
        from: undefined,
    }
    const { control, handleSubmit, reset, formState: { errors }, getValues, watch, setValue } = useForm({
        mode: 'onChange',
        defaultValues,
    });
    const watchEnvelopes = watch('envelopes');
    const watchAccounts = watch('accounts');

    const accounts = accountsList?.map(account => ({ id: account?.value, name: account?.label }))
    const envelopes = []
    if (selectEnvelopeMortgageGoalList)
        selectEnvelopeMortgageGoalList.map(item => {
            if (item?.id > 0)
                envelopes.push({
                    id: item?.id,
                    name: item?.label,
                })
        })

    const [pageTabs, setPageTabs] = useState("homePages")
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [popoverOpen1, setPopoverOpen1] = useState(false)
    const [donutData, setDonutdata] = useState(null)
    const [specificCategory, setSpecificCategory] = useState('')

    function togglePopover() {
        setPopoverOpen(!popoverOpen)
    }
    function togglePopover1() {
        setPopoverOpen1(!popoverOpen1)
    }

    const onSubmit = (data) => {
        dispatch(GetReport(data))
        setSpecificCategory('')
    }
    const handleValidDate = (currentDate, selectedDate) => {

        const current = moment(currentDate)
        const start = moment(startDate)
        const end = moment(endDate)


        if (
            current.isSame(start, 'month') ||
            (current.isAfter(start, 'month') && current.isBefore(end, 'month')) ||
            current.isSame(end, 'month')
        )
            return true
        return false
    }

    function RenderDescription() {
        let envelopeText
        let accountText
        if (!watchEnvelopes.length || watchEnvelopes.length == selectEnvelopeMortgageGoalList.filter(obj => obj.id > 0).length)
            envelopeText = "All Envelopes"
        else if (watchEnvelopes.length == 1)
            envelopeText = "1 Envelope"
        else
            envelopeText = `${watchEnvelopes.length} Envelopes`

        if (!watchAccounts.length || watchAccounts.length == accountsList.length)
            accountText = "All Accounts"
        else if (watchAccounts.length == 1)
            accountText = "1 Account"
        else
            accountText = `${watchAccounts.length} Accounts`

        return `${envelopeText} and ${accountText} included`
    }
    function RenderEnvlopesWithSpending() {
        return [...Array(envelopesData.labels.length)].map((item, key) => {
            return (
                <tr>
                    <td className="border-0">{envelopesData.labels[key]}</td>
                    <td className="border-0">${envelopesData.datasets[0].data[key]}</td>
                </tr>
            )
        })
    }

    React.useEffect(() => {
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    React.useEffect(() => {
        setDonutdata(envelopesData)
    }, [envelopesData])

    React.useEffect(() => {
        dispatch(GetReport(defaultValues))
        setSpecificCategory('')
    }, [])

    return (
        <>
            <div className="content ">
                <Row className="px-2">
                    <Col className="" md="12">
                        <Card className="card-plain card-subcategories">
                            <CardBody>
                                <TabContent
                                    className=" tab-subcategories"
                                    activeTab={pageTabs}
                                >
                                    <TabPane tabId="homePages" className="p-3" style={{ border: '0px solid red' }}>
                                        <Row className="bg-light" style={{ marginLeft: 2, marginRight: 2, paddingInline: "10px" }}>
                                            <Col md="10">
                                                <div className="row align-items-center justify-items-center">
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                        <Button
                                                            style={{ maxWidth: 220 }}
                                                            id="mypopover"
                                                            type="button"
                                                            color="primary"
                                                            className="w-100 border bg-transparent text-muted no-hover-button font-weight-normal"
                                                        >
                                                            {startDate ?
                                                                moment(startDate).format("MMM YYYY")
                                                                :
                                                                "Start Date"
                                                            }
                                                            {" - "}
                                                            {endDate ?
                                                                moment(endDate).format("MMM YYYY")
                                                                :
                                                                "End Date"
                                                            }
                                                            <i className="fas fa-chevron-down float-right"></i>
                                                        </Button>
                                                        <Popover
                                                            placement="bottom"
                                                            isOpen={popoverOpen}
                                                            target="mypopover"
                                                            toggle={togglePopover}
                                                            trigger="legacy"
                                                            popperClassName="big-width"
                                                        >
                                                            <PopoverBody className="w-100">
                                                                <div className="d-flex justify-content-between w-100 px-3 py-2">
                                                                    <FormGroup className="mr-3">
                                                                        <label htmlFor="">From</label>
                                                                        <Controller
                                                                            control={control}
                                                                            rules={{
                                                                                required: true,
                                                                            }}
                                                                            render={({ field: { onChange, value } }) => {
                                                                                let date
                                                                                if (value) {
                                                                                    date = value.split("-")
                                                                                    date = new Date(date[0], date[1] - 1, date[2])
                                                                                } else
                                                                                    date = null
                                                                                return (
                                                                                    <ReactDatetime
                                                                                        onChange={(value) => onChange(moment(value.toDate()).format("YYYY-MM-DD"))}
                                                                                        value={date}
                                                                                        inputProps={{
                                                                                            className: "form-control w-100",
                                                                                            placeholder: "Start Date",
                                                                                            readOnly: true,
                                                                                        }}
                                                                                        isValidDate={handleValidDate}
                                                                                        timeFormat={false}
                                                                                        dateFormat="MM/yyyy"
                                                                                        showMonthYearPicker
                                                                                    />
                                                                                )
                                                                            }}
                                                                            name="from"
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <label htmlFor="">To</label>
                                                                        <Controller
                                                                            control={control}
                                                                            rules={{
                                                                                required: true,
                                                                            }}
                                                                            render={({ field: { onChange, value } }) => {
                                                                                let date
                                                                                if (value) {
                                                                                    date = value.split("-")
                                                                                    date = new Date(date[0], date[1] - 1, date[2])
                                                                                } else {
                                                                                    date = null
                                                                                }
                                                                                return (
                                                                                    <ReactDatetime
                                                                                        onChange={(value) => onChange(moment(value.toDate()).format("YYYY-MM-DD"))}
                                                                                        value={date}
                                                                                        inputProps={{
                                                                                            className: "form-control",
                                                                                            placeholder: "End Date",
                                                                                            readOnly: true,
                                                                                        }}
                                                                                        isValidDate={handleValidDate}
                                                                                        timeFormat={false}
                                                                                        dateFormat="MM/yyyy"
                                                                                        showMonthYearPicker
                                                                                    />
                                                                                )
                                                                            }}
                                                                            name="to"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </PopoverBody>
                                                        </Popover>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md="2" className="text-right d-flex justify-content-end align-items-center " >
                                                <Button size="sm" color="primary" style={{ paddingBlock: "12px" }}><i className="fas fa-file-export"></i> Export</Button>

                                            </Col>
                                        </Row>
                                        <Row className="bg-light" style={{ marginLeft: 2, marginRight: 2, marginTop: 20, padding: "1rem" }}>
                                            <Col lg="6">
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Multiselect
                                                            onSelect={(selectedItems) => onChange(selectedItems)}
                                                            onRemove={(selectedItems) => onChange(selectedItems)}
                                                            options={accounts}
                                                            isObject={true}
                                                            displayValue="name"
                                                            showCheckbox={true}
                                                            closeOnSelect={false}
                                                            avoidHighlightFirstOption={true}
                                                            showArrow={true}
                                                            keepSearchTerm={false}
                                                            placeholder='All Accounts'
                                                            style={{
                                                                optionContainer: { width: "100%" },
                                                                chips: { maxWidth: "100%" },
                                                                searchBox: {
                                                                    border: "none",
                                                                    "border-radius": "0px",
                                                                    "max-width": "100%",
                                                                    "height": "50%",
                                                                    "border-bottom": "1px solid #9f9f9f"
                                                                },
                                                                multiselectContainer: { Width: "100%" },
                                                                option: { width: "100%" }
                                                            }}
                                                        />
                                                    )}
                                                    name="accounts"
                                                />
                                            </Col>
                                            <Col lg="6">
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Multiselect
                                                            onSelect={(selectedItems) => onChange(selectedItems)}
                                                            onRemove={(selectedItems) => onChange(selectedItems)}
                                                            options={envelopes}
                                                            isObject={true}
                                                            displayValue="name"
                                                            showCheckbox={true}
                                                            closeOnSelect={false}
                                                            avoidHighlightFirstOption={true}
                                                            showArrow={true}
                                                            keepSearchTerm={false}
                                                            placeholder='All Envelopes'
                                                            style={{
                                                                optionContainer: { width: "100%" },
                                                                chips: { maxWidth: "100%" },
                                                                searchBox: {
                                                                    border: "none",
                                                                    "border-radius": "0px",
                                                                    "max-width": "100%",
                                                                    "height": "50%",
                                                                    "border-bottom": "1px solid #9f9f9f"
                                                                },
                                                                multiselectContainer: { Width: "100%" },
                                                                option: { width: "100%" }
                                                            }}
                                                        />
                                                    )}
                                                    name="envelopes"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="8" className="w-100">
                                                <Card className="w-100 custom-card">
                                                    {donutData?.datasets?.[0]?.data?.some(obj => obj > 0) ?
                                                        <>
                                                            <CardHeader className="w-100">
                                                                <span
                                                                    className="card-category"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        setDonutdata(envelopesData)
                                                                        setSpecificCategory('')
                                                                    }}
                                                                >
                                                                    {"All Envelopes "}
                                                                </span>
                                                                <span className="card-category"> {specificCategory}</span>
                                                            </CardHeader>
                                                            <CardBody className="w-100 custom-nav-tabs-bottom">
                                                                <div className="tab-content" id="myTabContent">
                                                                    <div
                                                                        className="tab-pane fade show active"
                                                                        id="home"
                                                                        role="tabpanel"
                                                                        aria-labelledby="home-tab"
                                                                    >
                                                                        <div className="mt-5">
                                                                            <Doughnut
                                                                                data={JSON.parse(JSON.stringify(donutData))}
                                                                                options={options}
                                                                                className="mt-3 d-flex flex-direction-column"
                                                                                onElementsClick={(element) => {
                                                                                    // console.log("ONELEMENTSCLICK")   
                                                                                    if (element[0]?._index != undefined) {
                                                                                        if (specificCategory == '') {
                                                                                            console.log(element[0])
                                                                                            setDonutdata(envelopesWithExpenseData[element[0]._index])
                                                                                            setSpecificCategory('> ' + envelopesData.labels[element[0]._index])
                                                                                        } else {

                                                                                            let envelope = donutData.datasets[0].label
                                                                                            let expense = donutData.labels[element[0]._index]
                                                                                            let expenseId = 0

                                                                                            selectEnvelopeMortgageGoalList.map(item => {
                                                                                                if (item.label == envelope)
                                                                                                    item.options.map(subItem => {
                                                                                                        if (subItem.label == expense)
                                                                                                            expenseId = subItem.value
                                                                                                    })
                                                                                            })
                                                                                            dispatch(GetTransactionsByExpenseId(expenseId))
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                                        <div className="mt-5">
                                                                            <Bar
                                                                                data={chartExample4.data}
                                                                                options={chartExample4.options}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </>
                                                        :
                                                        <div className='reports-nodata-wrapper'>
                                                            <span className='nodata'>
                                                                No data found
                                                            </span>
                                                        </div>
                                                    }
                                                </Card>
                                            </Col>
                                            <Col md="4">
                                                <Card>
                                                    <CardBody
                                                        style={{}}
                                                    >
                                                        <Table   >

                                                            <tbody >
                                                                <tr style={{ lineHeight: '0' }}>
                                                                    <td className="border-0 text-center text-muted line-height-0">
                                                                        {moment(startDate).format("MMM YYYY")} - {moment(endDate).format("MMM YYYY")}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="border border-left-0 border-top-0 border-right-0 border-bottom  text-center font-weight-bolder">
                                                                        {RenderDescription()}
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ lineHeight: '0' }} >
                                                                    <td className="border-0 text-center text-muted line-height-0">
                                                                        TOTAL SPENDING
                                                                    </td>
                                                                </tr>
                                                                <tr className="mb-5">
                                                                    <td className="border border-left-0 border-top-0 border-right-0 border-bottom  text-center font-weight-bolder">
                                                                        ${totalSpending}
                                                                        <br />
                                                                        <span className="font-weight-normal">For this time period</span>
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ lineHeight: '0' }}>
                                                                    <td className="border-0 text-center text-muted line-height-0">
                                                                        AVERAGE SPENDING
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border border-left-0 border-top-0 border-right-0 border-bottom  text-center font-weight-bolder">
                                                                        ${averageSpending}
                                                                        <br />
                                                                        <span className="font-weight-normal">
                                                                            Per month
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        <Table >
                                                            <tr className="border">
                                                                <td className="border-0">Envelopes</td>
                                                                <td className="border-0">Spending</td>
                                                            </tr>
                                                            {RenderEnvlopesWithSpending()}
                                                        </Table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="settingsPages" className="p-4">
                                        <Row className="bg-light" style={{ marginLeft: 2, marginRight: 2 }}>
                                            <Col md="10">
                                                {/* Put the multiselec */}
                                                <div className="row align-items-center justify-items-center">

                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                        <Button style={{ maxWidth: 200 }} id="mypopover1" type="button" color="primary" className=" w-100 border bg-transparent text-muted no-hover-button font-weight-normal">
                                                            Apr 2021 - Apr 2021
                                                            <i className="fas fa-chevron-down float-right"></i>

                                                        </Button>
                                                        <Popover
                                                            placement="bottom"
                                                            isOpen={popoverOpen1}
                                                            target="mypopover1"
                                                            toggle={togglePopover1}
                                                            trigger="legacy"
                                                            popperClassName="big-width"
                                                        >
                                                            <PopoverBody className="w-100">
                                                                <div className="d-flex justify-content-between w-100 px-3 py-2">
                                                                    <FormGroup className="mr-3">
                                                                        <label htmlFor="">From</label>
                                                                        <ReactDatetime
                                                                            inputProps={{
                                                                                className: "form-control w-100",
                                                                                placeholder: "Apr 2019",
                                                                                readOnly: true,
                                                                            }}

                                                                            timeFormat={false}
                                                                            dateFormat="MM/yyyy"
                                                                            showMonthYearPicker
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <label htmlFor="">To</label>
                                                                        <ReactDatetime
                                                                            inputProps={{
                                                                                className: "form-control",
                                                                                placeholder: "Apr 2019",
                                                                                readOnly: true,
                                                                            }}
                                                                            timeFormat={false}
                                                                            dateFormat="MM/yyyy"
                                                                            showMonthYearPicker
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="text-right">
                                                                    <a className="btn-danger custom-btn p-2 " onClick={() => {
                                                                        console.log("Pressed")
                                                                        setPopoverOpen1(false)
                                                                    }}>Cancel</a>
                                                                    <a className="btn-success custom-btn p-2 px-3" onClick={() => {
                                                                        console.log("Pressed")
                                                                        setPopoverOpen(false)
                                                                    }}>Ok</a>
                                                                </div>
                                                            </PopoverBody>
                                                        </Popover>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="2" className="text-right d-flex justify-content-end align-items-center " >
                                                <Button size="sm" color="primary" style={{ paddingBlock: "12px" }}><i className="fas fa-file-export"></i> Export</Button>

                                            </Col>
                                        </Row>
                                        <Row className="bg-light" style={{ marginLeft: 2, marginRight: 2, marginTop: 20, padding: "1rem" }}>
                                            <Col lg="6">
                                                <Multiselect
                                                    options={accounts}
                                                    isObject={true}
                                                    displayValue="name"
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    avoidHighlightFirstOption={true}
                                                    showArrow={true}
                                                    keepSearchTerm={false}
                                                    placeholder='All Accounts'
                                                    style={{ optionContainer: { width: "100%" }, chips: { maxWidth: "100%" }, searchBox: { border: "none", "border-radius": "0px", "max-width": "100%", "height": "50%", "border-bottom": "1px solid #9f9f9f" }, multiselectContainer: { Width: "100%" }, option: { width: "100%" } }}
                                                />

                                            </Col>
                                            <Col lg="6">
                                                <Multiselect
                                                    options={envelopes}
                                                    isObject={true}
                                                    displayValue="name"
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    avoidHighlightFirstOption={true}
                                                    showArrow={true}
                                                    keepSearchTerm={false}
                                                    placeholder='All Envelopes'
                                                    style={{ optionContainer: { width: "100%" }, chips: { maxWidth: "100%", backgroundColor: "none" }, searchBox: { border: "none", "border-radius": "0px", "max-width": "100%", "height": "50%", "border-bottom": "1px solid #9f9f9f" }, multiselectContainer: { Width: "100%" }, option: { width: "100%" } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Card className="mt-3">
                                            <CardHeader> <p className="text-muted font-weight-bold"> Income Vs Expense</p></CardHeader>
                                            <CardBody>
                                                <Table responsive>
                                                    <thead>
                                                        <tr className="border-bottom ">
                                                            <th></th>
                                                            <th></th>
                                                            <th>Feb 2021</th>
                                                            <th>Mar 2021</th>
                                                            <th>Aprl 2021</th>
                                                            <th>Average</th>
                                                            <th>Total</th>

                                                        </tr>
                                                    </thead>
                                                    <p className="text-success font-weight-bold mt-3 p-2">Income</p>
                                                    <tbody>
                                                        <tr>

                                                            <td>All Income Sources</td>
                                                            <td></td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td >$0.00</td>
                                                            <td >$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total All Income Sources</td>
                                                            <td></td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td >$0.00</td>
                                                            <td >$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Income</td>
                                                            <td></td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td >$0.00</td>
                                                            <td >$0.00</td>
                                                        </tr>
                                                        <p className="text-danger font-weight-bold mt-3 p-2">Expense</p>
                                                        <tr>
                                                            <td>Rent</td>
                                                            <td></td>
                                                            <td>200.00</td>
                                                            <td>200.00</td>
                                                            <td>200.00</td>
                                                            <td >200.00</td>
                                                            <td >200.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fuel</td>
                                                            <td></td>
                                                            <td>10.00</td>
                                                            <td>30.00</td>
                                                            <td>70.00</td>
                                                            <td >15.00</td>
                                                            <td >0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>House Maintainence</td>
                                                            <td></td>
                                                            <td>0.00</td>
                                                            <td>0.00</td>
                                                            <td>100.00</td>
                                                            <td >$0.00</td>
                                                            <td >$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Food</td>
                                                            <td></td>
                                                            <td>$10.00</td>
                                                            <td>$10.00</td>
                                                            <td>$10.00</td>
                                                            <td >$10.00</td>
                                                            <td >$10.00</td>
                                                        </tr>
                                                        <h5 className="font-weight-bold mt-3 p-2">Net Income</h5>
                                                        <tr className="font-weight-bold">
                                                            <td></td>
                                                            <td></td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td>$0.00</td>
                                                            <td >$0.00</td>
                                                            <td >$0.00</td>
                                                        </tr>
                                                    </tbody>

                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ExpenseTransactionsModal />
        </>
    );
}

const mapStateToProps = ({ colors, transaction }) => ({
    colors, transaction
})

export default connect(mapStateToProps, {
})(Reports)
